/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
document.addEventListener('turbolinks:load', () => {
  // change works image
  if ($('body').hasClass('Single') || $('body').hasClass('auctions')) {
    let src;
    let cnum = 1;
    const inum = document.getElementsByClassName('images post-media').length;
    $('.images > a:first-child').addClass('viewing');
    $('.images > a')
      .mouseover(function() {
        $(this).toggleClass('on');
      })
      .mouseout(function() {
        $(this).toggleClass('on');
      })
      .click(function() {
        $('.images > a').removeClass('viewing');
        $(this).addClass('viewing');
        $('.main-image')
          .attr('src', null)
          .attr('src', $(this).attr('href'));
        $('.main-image').data('youtube_embed', $(this).data('youtube_embed'));
        new Image().src = $(this).attr('href');
        // キャッシュ用
        const index = $('.images > a').index(this);
        cnum = index + 1;
        return false;
      });
    if ($('.main-image').length) {
      const tmpImg = new Image();
      tmpImg.src = $('.main-image').attr('src');

      tmpImg.onload = function() {
        $('.main-image-wrapper p').css('background-image', 'none');
      };
    }

    const toggle_main_image = function(cnum, fadeIn) {
      $('.main-image')
        .attr('src', null)
        .attr('src', $(`.post-media:nth-child(${cnum})`).attr('href'));
      $('.images > a').removeClass('viewing');
      if (fadeIn) {
        $(`.image:nth-child(${cnum})`).fadeIn();
      }
      $(`.post-media:nth-child(${cnum})`).addClass('viewing');
      if ($(`.post-media:nth-child(${cnum})`).hasClass('video')) {
        return $('.main-image-play').show();
        // $('.main-image-play').css 'display', 'block'
      }
      return $('.main-image-play').hide();
    };

    // change work imgae with arrow
    $('#s_next').click(() => {
      cnum++;
      if (cnum > inum) {
        cnum = 1;
      }
      toggle_main_image(cnum);
    });
    $('#s_prev').click(() => {
      cnum--;
      if (cnum < 1) {
        cnum = inum;
      }
      return toggle_main_image(cnum);
    });
    $('#next').click(() => {
      let fadeIn;
      cnum++;
      if (cnum > inum) {
        cnum = 1;
      }
      $('.image').hide();
      toggle_main_image(cnum, (fadeIn = true));
    });
    $('#prev').click(() => {
      let fadeIn;
      cnum--;
      if (cnum < 1) {
        cnum = inum;
      }
      $('.image').hide();
      toggle_main_image(cnum, (fadeIn = true));
    });
    // touch, scroll event
    const $document = $(document);
    const $hitarea = $('.sm-images');
    // touchable or not
    const supportTouch = 'ontouchend' in document;
    const EVENTNAME_TOUCHSTART = supportTouch ? 'touchstart' : 'mousedown';
    const EVENTNAME_TOUCHMOVE = supportTouch ? 'touchmove' : 'mousemove';
    const EVENTNAME_TOUCHEND = supportTouch ? 'touchend' : 'mouseup';
    let cx;
    let ux;

    const currentX = function(event) {
      const original = event.originalEvent;
      if (original.changedTouches) {
        cx = original.changedTouches[0].pageX;
      } else {
        cx = event.pageX;
      }
    };

    const updateX = function(event) {
      let margin;
      const original = event.originalEvent;
      if (original.changedTouches) {
        ux = original.changedTouches[0].pageX;
      } else {
        ux = event.pageX;
      }
      if (cx - ux > 50) {
        if (scount === snum) {
          return false;
        }
        margin = scount;
        $('li.sm').css('margin-left', `-${margin}00%`);
        scount++;
      } else if (ux - cx > 50) {
        if (scount === 1) {
          return false;
        }
        scount--;
        margin = scount - 1;
        $('li.sm').css('margin-left', `-${margin}00%`);
      }
      $('.post-media').removeClass('viewing');
      $(`.post-media:nth-child(${scount})`).addClass('viewing');
    };

    const handleStart = function(event) {
      currentX(event);
      bindMoveAndEnd();
    };

    const handleMove = function(event) {
      event.preventDefault();
      // prevent document scroll
    };

    const handleEnd = function(event) {
      updateX(event);
      unbindMoveAndEnd();
    };

    var bindMoveAndEnd = function() {
      $document.on(EVENTNAME_TOUCHMOVE, handleMove);
      $document.on(EVENTNAME_TOUCHEND, handleEnd);
    };

    var unbindMoveAndEnd = function() {
      $document.off(EVENTNAME_TOUCHMOVE, handleMove);
      $document.off(EVENTNAME_TOUCHEND, handleEnd);
    };

    // sm-image only showed in smartphone
    var snum = $('.images > a').length;
    var scount = 1;
    const loading = $('#main_image').attr('src');
    if (snum) {
      let i = 1;
      while (i <= snum) {
        src = $(`.post-media:nth-child(${i})`).attr('href');
        $('.sm-images').append(
          `<li class="sm"><p><img class="main-image lazyload" src=${loading} data-src=${src}></p></li>`
        );
        i++;
      }
      $hitarea.on(EVENTNAME_TOUCHSTART, handleStart);
    } else {
      src = $('#main_image').data('src');
      $('.sm-images').append(
        `<li class="sm"><p><img class="main-image lazyload" src=${loading} data-src=${src}></p></li>`
      );
    }
    // auction image
    $('.auction-pics > ul > a')
      .css({ opacity: 0.5 })
      .mouseover(function() {
        $(this).css({ opacity: 1 });
      })
      .mouseout(function() {
        $(this).css({ opacity: 0.5 });
      })
      .click(function() {
        $('#auction_image').attr('src', $(this).attr('href'));
        new Image().src = $(this).attr('href');
        // キャッシュ用
        return false;
      });
    // load full size image
    $('.main-image.work, .main-image-play').click(() => {
      $('#full').fadeIn();
      $('#full .inner .image-wrapper').css('height', $(window).height() - 140);
      $(`.image:nth-child(${cnum})`).show();
    });
    $('.hover-wrapper').hover(
      function() {
        $(this)
          .find('p')
          .fadeIn();
      },
      function() {
        $(this)
          .find('p')
          .fadeOut();
      }
    );
    $('.switch-bg').click(() => {
      $('#full').toggleClass('white');
    });
    $('.hover-wrapper').click(e => {
      e.stopPropagation();
    });
    $('#full, #close').click(() => {
      $('#full').fadeOut();
      $('.image').hide();
    });
  }
});
