import { Controller } from 'stimulus';

/**
 * キャンペーンページのYoutubeプレイヤー
 * 動画の比率やトリミングのサイズ、再生を開始するスクロール位置などをベタ書きしているので
 * キャンペーンページを動的にするタイミングで、もっと汎用的に使えるようにしたい
 */
export default class extends Controller {
  connect() {
    // for turbolinks
    if (typeof YT !== 'undefined') {
      this.setupVideo();
    } else {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      global.onYouTubeIframeAPIReady = () => {
        // this.setupVideo(682, 584.57, 'pou85Auq_wA', 'youtube-player1', '.p-specialEn__movie1');
        this.setupVideo(682, 385, 'KHna8ngTaOg', 'youtube-player1', '.p-specialEn__movie1');
        this.setupVideo(442, 248.62, '81IzPPUiOjE', 'youtube-player2', '.p-specialEn__movie2');
      };
    }
  }

  /**
   * 指定したY座標がウィンドウで表示された時に一度だけ実行されるイベントを設定する。
   *
   * ファーストビューで表示されていたら即座に実行。
   * そうでない場合はスクロールイベントリスナーに登録し、初めて表示された時に一度だけ実行する。
   *
   * @param y Y座標
   * @param callback 実行するイベント
   */
  onFrameIn(y, callback) {
    const handler = event => {
      const scrollY = window.scrollY || window.pageYOffset; // for IE
      if (scrollY + window.innerHeight >= y) {
        callback();
        window.removeEventListener('scroll', handler);
      }
    };

    if (window.innerHeight >= y) {
      callback();
    } else {
      window.addEventListener('scroll', handler);
    }
  }

  setupVideo(width, height, videoId, playerId, selector) {
    this.player = new YT.Player(playerId, {
      height: height,
      width: width,
      videoId: videoId,
      playerVars: {
        autoplay: 0,
        controls: 0,
        disablekb: 1,
        loop: 1,
        playsinline: 1,
        playlist: videoId,
      },
      events: {
        onReady: event => {
          event.target.mute();

          const playStartScrollY =
            window.pageYOffset + document.querySelector(selector).getBoundingClientRect().top + 20;
          this.onFrameIn(playStartScrollY, () => {
            event.target.playVideo();
          });
        },
      },
    });
  }

  isMobile() {
    return window.innerWidth < 768;
  }
}
