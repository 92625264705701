import { Controller } from 'stimulus';

export default class extends Controller {
  toggle() {
    if (!this.element.classList.contains('is-open')) {
      this.element.classList.add('is-open');
      document.addEventListener('click', this.onOutsideClick);
    } else {
      this.element.classList.remove('is-open');
      document.removeEventListener('click', this.close);
    }
  }

  onOutsideClick = () => {
    if (!this.element.contains(event.target)) {
      this.element.classList.remove('is-open');
      document.removeEventListener('click', this.close);
    }
  };
}
