<template>
  <div>
    <div class="b-workDetailIndex__splash" :data-is-loaded="isImagesLoaded"><div class="loader">Loading...</div></div>
    <div v-show="isNoWork">
      <div class="b-workDetailIndex__noWork">
        <div class="b-workDetailIndex__title">B-OWND TOPページ</div>
        <br />
        TOPページには、購入可能な作品のサムネイル画像がタイル表示されます。<br />
        現在は登録されている作品がないため、ページ内容を表示できません。<br />
        このページを表示するために管理者アカウントでログインし、管理画面から作品を登録してください。<br />
        <br />
        ※金額が未設定（0円）の作品は購入不可となりTOPページに表示されません。ご注意ください。
      </div>
    </div>
    <div>
      <transition name="fade">
        <div :ref="'squareTiled'" class="b-workDetailIndex b-workDetailIndex--flexWrap">
          <a
            v-for="(work, index) in items"
            :key="`work-${work.id}-${index}`"
            class="b-workDetailIndex__squareGrid b-workDetailIndex__squareItem"
            @click="openWork(work.id)"
          >
            <img v-lazy="work.thumbnail_url" :alt="work.title" />
          </a>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {},
  data() {
    return {
      lazyLoadInstance: null,
      galleryType: 'squareTiled',
      selectWork: {},
      selectIndex: 0,
      switchButtonClass: 'l-worksGallery__switchGalleryButton',
      scrollY: 0,
      works: [],
      items: [],
      winW: window.innerWidth,
      winH: window.innerHeight,
      column: 6,
      itemGrid: 0,
      row: 0,
      totalGrid: 0,
      imagesCount: 0,
      isImagesLoaded: false,
      isTransitioningFilledImage: false,
      isNoWork: false,
    };
  },
  mounted() {
    this.isImagesLoaded = false;
    this.imagesCount = 0;
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    this.$Lazyload.$on('loaded', this.handleImageLoadedAnimation);
    this.winW = window.innerWidth;
    this.winH = window.innerHeight;
    this.itemGrid = window.innerWidth / this.column;
    this.row = Math.ceil(this.winH / this.itemGrid);
    this.totalGrid = this.column * this.row;
    document.body.classList.add('l-worksGallery__hiddenScrollBar');
    axios
      .get('/explore/data')
      .then(response => {
        this.works = this.items = response.data;
        if (this.items.length === 0) {
          this.isImagesLoaded = true;
          this.isNoWork = true;
          this.hideMask();
          return;
        }
        while (this.items.length % this.column !== 0) {
          const index = Math.floor(Math.random() * this.works.length);
          this.items.push(this.works[index]);
        }
        this.checkAndLoop();
        this.cloneArray();
      })
      .catch(error => console.error(error));
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
    this.$Lazyload.$off('loaded', this.handleImageLoadedAnimation);
    this.isImagesLoaded = false;
    this.imagesCount = 0;
  },
  methods: {
    showNextWork() {
      if (this.selectIndex < this.works.length - 1) {
        this.selectIndex += 1;
      } else {
        this.selectIndex = 0;
      }
      this.selectWork = this.works[this.selectIndex];
    },
    handleScroll(e) {
      this.scrollY = window.scrollY;
      // ページの一番下まで（マイナス - いくつか）で現在の配列が複製される。
      const $squareTiledk = this.$refs.squareTiled;
      const scrollEnd = $squareTiledk.getBoundingClientRect().height - this.itemGrid * this.row + this.winH;
      if (scrollEnd < this.winH + window.scrollY) {
        window.scrollTo(0, 0);
      }
    },
    handleResize() {
      this.winW = window.innerWidth;
      this.winH = window.innerHeight;
      this.itemGrid = window.innerWidth / this.column;
      this.row = Math.ceil(this.winH / this.itemGrid);
      this.totalGrid = this.column * this.row;
      while (this.items.length % this.column !== 0) {
        const index = Math.floor(Math.random() * this.works.length);
        this.items.push(this.works[index]);
      }
      this.checkAndLoop();
    },
    handleImageLoadedAnimation({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error }) {
      this.imagesCount++;
      if (this.winW > 768) {
        if (this.imagesCount > Math.min(this.works.length - 1, this.column) && !this.isImagesLoaded) {
          setTimeout(() => {
            this.isImagesLoaded = true;
            [...document.querySelectorAll('.b-workDetailIndex__squareGrid')].map((item, index) => {
              setTimeout(() => {
                item.dataset.isAnimate = true;
              }, 75 * index);
            });
            this.hideMask();
            document.body.classList.remove('l-worksGallery__hiddenScrollBar');
          }, 1000);
        }
      } else {
        setTimeout(() => {
          this.isImagesLoaded = true;
          [...document.querySelectorAll('.b-workDetailIndex__squareGrid')].map((item, index) => {
            setTimeout(() => {
              item.dataset.isAnimate = true;
            }, 75 * index);
          });
          this.hideMask();
          document.body.classList.remove('l-worksGallery__hiddenScrollBar');
        }, 1000);
      }
    },
    checkAndLoop() {
      if (this.items.length > 0 && this.items.length < this.totalGrid) {
        const arr = Object.assign([], this.items);
        this.items = this.items.concat(arr);
        this.checkAndLoop();
      }
    },
    cloneArray() {
      for (let i = 0; i < this.totalGrid; i++) {
        this.items.push(this.items[i]);
      }
    },
    // マウスホイールイベント。次の作品を表示させる。
    // 作品の表示が終わるまではイベントを無効にしておく。
    handleWheelOnFilledImage(e) {
      if (e.deltaY > 0 && !this.isTransitioningFilledImage) {
        this.isTransitioningFilledImage = true;
        this.showNextWork();
      }
    },
    // 作品画面のロードとアニメーションが終了したときのイベント
    // マウスホイールによる移動を有効にする
    handleTransitionEndOfFilledImage() {
      setTimeout(() => (this.isTransitioningFilledImage = false), 500);
    },
    openWork(id) {
      this.$modal.show('work-modal', { id: id, from: '/explore' });
    },
    hideMask() {
      document.querySelectorAll('.b-workDetailIndex__mask').forEach(elem => {
        elem.style.display = 'none';
      });
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.loop-container.full {
  height: 100vh;
}
</style>
