import { Controller } from 'stimulus';
import smoothscroll from 'smoothscroll-polyfill';

export default class extends Controller {
  connect() {
    smoothscroll.polyfill();
  }

  scrollTo(event) {
    const destSelector = event.currentTarget.dataset.scrollTo;
    document.querySelector(destSelector).scrollIntoView({ behavior: 'smooth' });
  }
}
