/**
 * Returns a debounced function
 * E.g.
 * // apiCall will only be called after 1000ms
 * // of not calling apiCall
 * const apiCall = debounce(1000, () => {
 *  // some api call
 * })
 */
export default function debounce(delay, func) {
  let timerId;
  return function(...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    return (timerId = setTimeout(() => {
      func(...args);
      timerId = null;
    }, delay));
  };
}
