import { Controller } from 'stimulus';
import isMobile from '../helpers/is_mobile';

/**
 * 「B-OWNDとは」ページのコントローラ
 */
export default class extends Controller {
  static targets = ['keyvisual', 'keyvisualText', 'means', 'mean'];

  initialize() {
    this.last_known_scroll_position = 0;
    this.ticking = false;
    this.serviceNameShown = false;
  }

  /**
   * ウィンドウのスクロールイベント
   */
  scrollHandler() {
    this.last_known_scroll_position = (window.scrollY || window.pageYOffset) + window.innerHeight;

    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        if (this._isFeedInHeight(this.last_known_scroll_position) && !this.serviceNameShown) {
          this.feedInServiceName();
        }
        this.ticking = false;
      });

      this.ticking = true;
    }
  }

  /**
   * サービス名に込めた思いをフェードイン表示する
   */
  feedInServiceName() {
    this.meansTarget.classList.remove('display-none');
    this.meanTargets.forEach((mean, i) => {
      setTimeout(() => mean.classList.remove('-hide'), 100 * i);
    });
    this.serviceNameShown = true;
  }

  /**
   * 共通ヘッダーの背景を透明にする
   */
  makeHeaderTransparent() {
    document.querySelector('.b-header-global').style.backgroundColor = 'transparent';
  }

  /**
   * 運営メンバーのモーダルを開く
   */
  openMemberModal(event) {
    const target = event.currentTarget;
    const memberEl = target.closest('.p-about__member');
    Vue.prototype.$modal.show('about-member', { memberEl }, { scrollable: true });
  }

  /**
   * TOPの文章を折りたたむ／広げる
   */
  foldKeyvisualText(event) {
    this.keyvisualTarget.classList.toggle('-fold');
    this.keyvisualTextTarget.classList.toggle('-fold');
    event.currentTarget.classList.toggle('-fold');
    event.currentTarget.textContent = event.currentTarget.classList.contains('-fold') ? '続きを読む' : '少なく表示する';
  }

  _isFeedInHeight = scrollY => (isMobile() ? this._isFeedInHeightMobile() : this._isFeedInHeightPc(scrollY));
  _isFeedInHeightMobile = () => {
    const rect = document.querySelector('.p-about__service-name').getBoundingClientRect();
    return rect.top + rect.height / 2 < window.innerHeight;
  };
  _isFeedInHeightPc = scrollY => scrollY >= 1500;
}
