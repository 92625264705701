export default {
  install(_vue) {
    const Vue = _vue;
    Vue.prototype.$confirmationDialog = {
      show(confirmationText, handler) {
        Vue.prototype.$modal.show('dialog', {
          text: confirmationText,
          buttons: [
            {
              title: 'Cancel',
            },
            {
              title: 'OK',
              handler() {
                handler.call();
                Vue.prototype.$modal.hide('dialog');
              },
            },
          ],
        });
      },
    };
  },
};
