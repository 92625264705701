import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['btn', 'target'];

  toggle() {
    this.btnTarget.classList.toggle('is-open');
    this.targetTarget.classList.toggle('is-open');
  }
}
