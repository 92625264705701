import { Controller } from 'stimulus';
import Glide from '@glidejs/glide';
import isMobile from '../../helpers/is_mobile';

export default class extends Controller {
  static targets = ['artist', 'material', 'price'];

  slides = { find: {} };

  initialFind() {
    // 「作品を探す」セクションの初期化
    // アーティストの先頭を選択してカルーセルを描画
    if (this.artistTargets.length > 0) {
      this._findByArtist(this.artistTargets[0]);
    }

    // 素材・技法の先頭を選択してカルーセルを描画
    if (this.materialTargets.length > 0) {
      this._findByMaterial(this.materialTargets[0]);
    }

    // 価格の先頭を選択してカルーセルを描画
    if (this.priceTargets.length > 0) {
      this._findByPrice(this.priceTargets[0]);
    }
  }

  // 「作品を探す」セクションを選択したときのアクション
  selectFindCategory(event) {
    this._selectFindCategory(event.currentTarget);
  }

  // 「作品を探す」セクション
  _selectFindCategory(target) {
    // 選択したタブをアクティブにする
    const categories = document.querySelectorAll('.p-home__find-category');
    categories.forEach(category => category.classList.remove('p-home__find-category--active'));
    target.classList.add('p-home__find-category--active');

    // 選択したタブに対応した項目（アーティスト・素材技法・価格）と結果エリアを表示
    const results = document.querySelectorAll('.p-home__find-result');
    const category = target.dataset.category;
    results.forEach(result => {
      if (result.dataset.category === category) {
        result.classList.remove('display-none');
      } else {
        result.classList.add('display-none');
      }
    });

    const buttonData = this._footerButtonOnFind();
    const button = document.querySelector('.p-home__find-footer-button');
    button.textContent = buttonData.caption;
    button.dataset.href = buttonData.href;
  }

  // 「作品を探す」セクションでアーティスト選択
  findByArtist(event) {
    this._findByArtist(event.currentTarget);
  }

  _findByArtist(target) {
    this._findBy('artist', target);
  }

  // 素材を選択
  findByMaterial(event) {
    this._findByMaterial(event.currentTarget);
  }

  _findByMaterial(target) {
    this._findBy('material', target);
  }

  // 価格帯を選択
  findByPrice(event) {
    this._findByPrice(event.currentTarget);
  }

  _findByPrice(target) {
    this._findBy('price', target);
  }

  _findBy(type, target) {
    const types = {
      artist: {
        path: 'find_by_artist',
        action: 'click->home--find#findByArtist',
      },
      material: {
        path: 'find_by_material',
        action: 'click->home--find#findByMaterial',
      },
      price: {
        path: 'find_by_price',
        action: 'click->home--find#findByPrice',
      },
    };

    // 選択した項目をアクティブにする（見た目を強調してクリックイベントを無効にする）
    target.parentNode.querySelectorAll('li').forEach(li => {
      li.classList.remove('-active');
      if (li.dataset.notFilter == null) {
        li.dataset.action = types[type].action;
      }
    });
    target.classList.add('-active');
    delete target.dataset.action;

    // xhrで作品の一覧を取得して表示する
    const glide = target.closest('.p-home__find-result').querySelector('.glide');
    const loading = glide.querySelector('.p-home__find-loading');
    loading.classList.remove('display-none');
    setTimeout(() => loading.classList.remove('-hide'), 1);

    const path = `/home/${types[type].path}/${target.dataset.criteria}`;
    setTimeout(() => {
      axios
        .get(path)
        .then(response => {
          // 表示されている作品一覧のカルーセルを無効にする
          if (this.slides.find[type]) {
            this.slides.find[type].destroy();
            delete this.slides.find[type];
          }

          // 作品の一覧を消して書き換える
          const slides = glide.querySelector('.glide__slides');
          slides.querySelectorAll('li').forEach(li => li.parentNode.removeChild(li));
          response.data.forEach(work => {
            const li = document.createElement('li');
            li.classList.add('glide__slide');
            li.dataset.workId = work.id;
            li.dataset.works = JSON.stringify(response.data.map(work => work.id));
            li.innerHTML = `
              <div class="p-home__find-work-picture-wrapper">
                <img class="p-home__find-work-picture" src="${work.picture}" data-work-id="${work.id}" alt="">
              </div>
              <div class="p-home__find-work-artist">${work.artist}</div>
              <div class="p-home__find-work-title">${work.title}</div>
              <div class="p-home__find-work-price">${work.price.toLocaleString()}</div>
            `;
            slides.appendChild(li);
            li.addEventListener('click', event =>
              Vue.prototype.$modal.show('works', { workId: work.id, works: response.data.map(work => work.id) })
            );
          });

          // 作品が5個以上の場合はカルーセルを有効にして左右の矢印を表示
          if (response.data.length > 4 && !isMobile()) {
            this.slides.find[type] = new Glide(glide, {
              type: 'carousel',
              startAt: 0,
              gap: 0,
              perView: 4.5,
              dragThreshold: false,
            })
              .on('build.after', () => {
                // カルーセルのためにcloneされて生まれたサムネイルをクリックすると作品モーダルが開かない
                // clickイベントがコピーされていないためと思われるので、こっちで登録する
                glide.querySelectorAll('.glide__slide--clone').forEach(clone => {
                  clone.addEventListener('click', event => {
                    Vue.prototype.$modal.hide('works');
                    Vue.prototype.$modal.show('works', {
                      workId: clone.dataset.workId,
                      works: JSON.parse(clone.dataset.works),
                    });
                  });
                });
              })
              .mount();
            glide.querySelector('.glide__arrows').classList.remove('display-none');
          } else {
            glide.querySelector('.glide__arrows').classList.add('display-none');
          }

          // 初期表示用に素材・技法と価格のエリアを非表示にする
          if (
            type !== 'artist' &&
            document.querySelector('.p-home__find-result[data-category="artist"]') &&
            !document.querySelector('.p-home__find-result[data-category="artist"]').classList.contains('display-none')
          ) {
            target.closest('.p-home__find-result').classList.add('display-none');
          }

          const buttonData = this._footerButtonOnFind();
          const button = document.querySelector('.p-home__find-footer-button');
          if (button) {
            button.textContent = buttonData.caption;
            button.dataset.href = buttonData.href;
          }

          setTimeout(() => loading.classList.add('-hide'), 300);
        })
        .catch(error => console.error(error));
    }, 300);
  }

  _footerButtonOnFind() {
    const category = document.querySelector('.p-home__find-result:not(.display-none)');
    if (category == null) {
      return;
    }

    const subCategory = category.querySelector('nav ul li.-active');
    switch (category.dataset.category) {
      case 'artist':
        return {
          caption: `「${subCategory.textContent}」の作品一覧へ`,
          href: subCategory.dataset.worksPath,
        };
      case 'material':
        if (subCategory.dataset.criteria === 'other') {
          return { caption: '全ての作品を見る', href: '/works' };
        } else {
          return {
            caption: `全ての「${subCategory.textContent}」作品を見る`,
            href: `/works#materials/${subCategory.dataset.criteria}`,
          };
        }
      case 'price':
        return {
          caption: '全ての作品を見る',
          href: '/works',
        };
    }
  }
}
