import { Controller } from 'stimulus';

/**
 * 新規ユーザーを登録するときにユーザー名の入力を必須にする。
 *
 * 既に登録済みのユーザーが空白のまま更新するのはOKという仕様。なので、サーバー側でのチェックは難。
 */
export default class extends Controller {
  static targets = ['name', 'submit', 'errorOfName'];

  validate(e) {
    if (this.nameTarget.value.trim() === '') {
      this.errorOfNameTarget.classList.remove('p-registration__errorUserNew--hidden');
      e.preventDefault();
      this.submitTarget.removeAttribute('disabled');
    } else {
      this.submitTarget.value = '送信中…';
      this.submitTarget.disabled = 'disabled';
    }
  }
}
