<template>
  <div>
    <button :class="buttonClass" @click="toggleMarkButton">
      <svg
        v-show="!isMarkingNow"
        xmlns="http://www.w3.org/2000/svg"
        width="17.438"
        height="16"
        viewBox="0 0 17.438 16"
        fill="#fff"
      >
        <path
          d="M14.643 3a5.221 5.221 0 0 0-3.924 1.822A5.221 5.221 0 0 0 6.8 3 4.749 4.749 0 0 0 2 7.8c0 3.3 2.965 5.981 7.455 10.062L10.719 19l1.264-1.151c4.49-4.072 7.455-6.757 7.455-10.053A4.749 4.749 0 0 0 14.643 3zm-3.837 13.559l-.087.087-.087-.087C6.482 12.8 3.744 10.316 3.744 7.8A2.98 2.98 0 0 1 6.8 4.744 3.409 3.409 0 0 1 9.908 6.8h1.631a3.387 3.387 0 0 1 3.1-2.058A2.98 2.98 0 0 1 17.695 7.8c0 2.516-2.738 5-6.889 8.759z"
          class="cls-1"
          transform="translate(-2 -3)"
        />
      </svg>

      <svg
        v-show="isMarkingNow"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 17.4 16"
        fill="#fff"
      >
        <path
          d="M12.6,0c-1.5,0-2.9,0.7-3.9,1.8C7.7,0.7,6.3,0,4.8,0C2.2,0,0,2.1,0,4.7 c0,0,0,0.1,0,0.1c0,3.3,3,6,7.5,10.1L8.7,16l1.3-1.2c4.5-4.1,7.5-6.8,7.5-10.1C17.5,2.2,15.4,0,12.6,0C12.7,0,12.7,0,12.6,0z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import initApolloClient from '../apollo/client.js';
import markMutation from '../graphql/mutations/mark_mutation.js';
import unmarkMutation from '../graphql/mutations/unmark_mutation.js';

export default {
  name: 'MarkButton',
  props: {
    isMarking: {
      type: Boolean,
      default: false,
      required: true,
    },
    markableId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMarkingNow: this.isMarking,
    };
  },
  computed: {
    buttonClass() {
      const markingClass = this.isMarkingNow ? 'b-markButton--marking' : 'b-markButton--unmarking';
      return `b-markButton ${markingClass}`;
    },
  },
  methods: {
    toggleMarkButton() {
      const client = initApolloClient();
      const mutation = this.isMarkingNow ? unmarkMutation : markMutation;

      client
        .mutate({
          mutation,
          variables: {
            markableId: this.markableId,
          },
        })
        .then(data => {
          if (this.isMarkingNow && data.data.unmark.success) {
            this.isMarkingNow = false;
          } else if (!this.isMarkingNow && data.data.mark.success) {
            this.isMarkingNow = true;
          }
        })
        .catch(error => {
          Vue.prototype.$notify({
            group: 'error_notification',
            type: 'error',
            duration: 5000,
            text: error.message,
          });
        });
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
