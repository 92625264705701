import { Controller } from 'stimulus';

export default class extends Controller {
  // @counter element to set remaining counter
  // @input input element
  static targets = ['input', 'counter'];

  countChar(event) {
    const text = this.inputTarget.value;
    const max = parseInt(this.inputTarget.getAttribute('maxlength'), 10);
    const remaining = max - text.length;
    this.counterTarget.innerHTML = remaining;

    if (remaining < 20) {
      this.counterTarget.setAttribute('style', 'color:red;');
    } else {
      this.counterTarget.removeAttribute('style');
    }
  }

  change() {
    const max = parseInt(this.inputTarget.getAttribute('maxlength'), 10);
    this.counterTarget.innerHTML = max;
  }
}
