<template>
  <div><slot></slot></div>
</template>

<script>
export default {
  mounted() {
    for (const [key, value] of Object.entries(this.$attrs)) {
      this.$el.setAttribute(key, value);
    }

    this.$nextTick(this.mountAndReady);

    if (document.readyState === 'complete') {
      this.$nextTick(this.mountAndLoaded);
    } else {
      window.addEventListener('load', this.mountAndLoaded); // for first load
    }
  },
  methods: {
    mountAndReady() {
      this.$el.dispatchEvent(new CustomEvent('vue:ready'));
    },
    mountAndLoaded() {
      this.$el.dispatchEvent(new CustomEvent('vue:load'));
    },
  },
};
</script>
