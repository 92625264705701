<template>
  <div class="p-registration__form p-registration__profileImg">
    <div ref="importButton" @click.prevent="clickHiddenFileInput" class="p-registration__profileImg__preview">
      <img v-if="imageUrl !== ''" ref="defaultImage" :src="imageUrl" />
    </div>
    <p class="p-registration__lead">プロフィール画像</p>
    <input
      ref="hiddenInputButton"
      type="file"
      name="user[avatar]"
      style="display:none"
      accepts="image/*"
      @change="importFile"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: '',
    };
  },
  methods: {
    clickHiddenFileInput() {
      this.$refs.hiddenInputButton.click();
    },
    importFile(e) {
      const file = e.target.files[0];
      this.imageUrl = URL.createObjectURL(file);
    },
  },
};
</script>
