import axios from 'axios';
import { Controller } from 'stimulus';

/**
 * 販売作品一覧で、全作品表示／販売中の作品のみ表示を切り替えるボタン
 */
export default class extends Controller {
  static targets = ['work', 'onSale', 'toggleButton'];

  connect() {
    window.addEventListener('hashchange', this.syncHashAndFilter);
    this.syncHashAndFilter();
  }

  disconnect() {
    window.removeEventListener('hashchange', this.syncHashAndFilter);
  }

  syncHashAndFilter = () => {
    this.isFiltered = this.element.dataset.isFiltered === 'true' || location.hash === '#nowonsale';
    this.render(this.isFiltered);
  };

  /**
   * 「販売作品のみ表示」ボタンを押したときのイベント
   */
  toggleFilter(event) {
    this.isFiltered = !this.isFiltered;

    // フィルタの状態を保存する
    this.storeFilterStatus(this.isFiltered);

    this.render(this.isFiltered);
  }

  render(isFiltered) {
    // ボタンの色を変える
    if (isFiltered) {
      this.toggleButtonTarget.classList.add('p-artistWorks__filterButton--press');
    } else {
      this.toggleButtonTarget.classList.remove('p-artistWorks__filterButton--press');
    }

    // 販売済み作品を隠す／表示する
    this.workTargets.forEach(work => {
      work.style.display = 'none';
      work.dataset.animation = false;
      work.dataset.display = false;
    });

    let count = 0;
    const displayWorks = isFiltered ? this.onSaleTargets : this.workTargets;
    displayWorks.forEach(work => {
      work.style.display = '';
      setTimeout(() => {
        work.dataset.animation = true;
        work.dataset.display = true;
      }, this.delay(count++));
    });

    // URLのハッシュをあわせる
    const newURL = new URL(location.href);
    if (isFiltered) {
      newURL.hash = '#nowonsale';
    } else if (location.hash === '#nowonsale') {
      newURL.hash = '';
    }
    history.replaceState('', document.title, newURL);
  }

  /**
   * アニメーションの開始を遅らせる時間を計算して返す
   *
   * @param index 表示する作品の番号 0から開始
   * @return number アニメーションの開始を遅らせる時間(ミリ秒)
   */
  delay(index) {
    // 1段目は75ミリ秒、2段目は150ミリ秒、3段目以降は225ミリ秒
    // モバイルは1段に2作品、PCは1段に4作品表示される
    const col = window.screen.width > 768 ? 4 : 2;
    return Math.min(225, 75 * (Math.floor(index / col) + 1));
  }

  /**
   * 絞り込みの状態を保存する
   *
   * @param isFiltered 販売中の作品のみ表示した場合true
   */
  storeFilterStatus(isFiltered) {
    const path = `/artists/${this.element.dataset.artistId}/works_filter`;
    const method = isFiltered ? axios.post : axios.delete;
    const handleError = reason => console.error(error);
    method(path).catch(handleError);
  }
}
