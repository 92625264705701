<template>
  <div class="p-transactionCreditCard__form">
    <div class="p-transactionCreditCard__formInput">
      <form v-show="!submitted" ref="form">
        <input name="utf8" type="hidden" value="✓" />
        <div class="p-transactionCreditCard__info">
          <div class="p-transactionCreditCard__paymentMethods">
            <div class="p-transactionCreditCard__paymentMethodsSubject">決済方法</div>
            <ul>
              <li>
                <label for="payment_method_credit_card" class="p-transactionCreditCard__paymentMethod">
                  <input
                    id="payment_method_credit_card"
                    type="radio"
                    name="payment_method"
                    @change="
                      model.paymentMethod = 'credit_card';
                      update();
                    "
                  />
                  <p>クレジットカード</p>

                  <div class="p-transactionCreditCard__infoImg">
                    <img src="../images/creditcard/visa.svg" />
                    <img src="../images/creditcard/master-card.svg" />
                    <img src="../images/creditcard/american_express.svg" />
                    <img src="../images/creditcard/jcb.svg" />
                    <img src="../images/creditcard/discover.svg" />
                    <img src="../images/creditcard/diners.svg" />
                  </div>
                </label>
              </li>
              <li>
                <label for="payment_method_bank_transfer" class="p-transactionCreditCard__paymentMethod">
                  <input
                    id="payment_method_bank_transfer"
                    type="radio"
                    name="payment_method"
                    @change="
                      model.paymentMethod = 'bank_transfer';
                      update();
                    "
                  />
                  <p class="p-transactionCreditCard__bankTransfer">銀行振込<br />ご購入後に入金先をご案内いたします</p>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div v-show="model.paymentMethod === 'credit_card'">
          <p class="p-transactionCreditCard__creditCardSubject">
            クレジットカード情報
          </p>
          <p class="p-transactionCreditCard__infoTextRed">
            ※クレジットカード情報はセキュリティ上、保存されません。
          </p>

          <div class="form-group">
            <label class="required">カード番号</label>
            <card-number
              ref="cardNumber"
              :stripe="stripePublishableKey"
              :options="options"
              class="stripe-element card-number"
              @change="number = $event.complete"
            />
          </div>

          <div class="form-group">
            <label class="required">有効期限（月/年）</label>
            <card-expiry
              ref="cardExpiry"
              :stripe="stripePublishableKey"
              :options="options"
              class="stripe-element card-expiry"
              @change="expiry = $event.complete"
            />
          </div>
          <div class="form-group">
            <label class="required">セキュリティコード</label>
            <card-cvc
              ref="cardCvc"
              :stripe="stripePublishableKey"
              :options="options"
              class="stripe-element card-cvc"
              @change="cvc = $event.complete"
            />
          </div>
        </div>
        <div class="p-transactionCreditCard__formInputItemBtns">
          <button class="p-transactionCreditCard__formInputItemBtn" @click.prevent="back">戻る</button>
          <general-submit-button
            :disabled="!complete || errors.some(error => error.disable_button)"
            class="p-transactionCreditCard__formInputItemBtn"
            @submitting="pay"
          >
            {{ model.paymentMethod === 'credit_card' ? 'お支払い' : 'ご注文内容の確認' }}
          </general-submit-button>
        </div>
      </form>

      <transaction-info-panel v-show="submitted" :model="model" :token="last4number" ref="panel">
        <template slot="notes">
          <div v-if="model.work" class="p-transactionConfirmation__item">
            <p class="p-transactionCreditCard__notice is-red w-96">
              この段階では、まだ購入は確定しておりません。下記の「{{
                model.paymentMethod === 'credit_card' ? '支払いを確定する' : 'ご注文を確定する'
              }}」ボタンを押したときに、作品が他の方に購入されていなければ、購入が確定されます。
            </p>
          </div>
        </template>

        <template slot="buttons">
          <button class="p-transactionCreditCard__formInputItemBtn" @click.prevent="clearTokenBack">戻る</button>
          <general-submit-button
            class="p-transactionCreditCard__formInputItemBtn"
            @submitting="withReduceStock(sendCreditCardToken)"
            :disabled="errors.some(error => error.disable_button)"
          >
            {{ model.paymentMethod === 'credit_card' ? '支払いを確定する' : 'ご注文を確定する' }}
          </general-submit-button>
        </template>
      </transaction-info-panel>
    </div>
    <div class="errors">
      <ul>
        <li v-for="error in errors" :key="error.code" v-html="error.message"></li>
      </ul>
    </div>
    <notifications group="error" position="bottom right" />
  </div>
</template>

<script>
import { CardNumber, CardExpiry, CardCvc, createToken } from 'vue-stripe-elements-plus';
import initApolloClient from '../apollo/client.js';
import TransactionInfoPanel from './transaction_info_panel.vue';
import ServiceAgreementsStripe from './service_agreements/stripe.vue';
import createStripeCharge from '../graphql/mutations/create_stripe_charge.js';
import GeneralSubmitButton from '../buttons/general_submit_button.vue';
import axios from 'axios';

export default {
  components: {
    CardNumber,
    CardExpiry,
    CardCvc,
    TransactionInfoPanel,
    GeneralSubmitButton,
    'service-agreements-stripe': ServiceAgreementsStripe,
  },
  props: {
    transaction: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      complete: false,
      number: false,
      expiry: false,
      cvc: false,
      submitted: false,
      certPrice: 5000,
      stripePublishableKey: process.env.STRIPE_PUBLISHABLE_KEY,
      options: {},
      model: this.transaction || {},
      token: {},
      acceptedCardBrands: ['Visa', 'MasterCard', 'American Express', 'JCB', 'Discover', 'Diners Club'],
      errors: ['申し訳ありません。在庫がなくなったため取引を完了できませんでした。'],
    };
  },
  computed: {
    last4number() {
      return this.token.card ? this.token.card.last4 : '';
    },
  },
  watch: {
    number() {
      this.update();
    },
    expiry() {
      this.update();
    },
    cvc() {
      this.update();
    },
    submitted() {
      this.renderBreadCrumbs();
    },
  },
  mounted() {
    axios.get(`/transactions/${this.model.activeRecordId}/is_sold`).catch(error => {
      this.errors = error.response.data.errors;
    });
  },
  methods: {
    withReduceStock(fn) {
      axios
        .patch(`/transactions/${this.model.activeRecordId}/reduce_item_stock`)
        .then(response => fn())
        .catch(error => {
          this.errors = error.response.data.errors;
        });
    },
    sendCreditCardToken() {
      if (this.model.paymentMethod === 'credit_card') {
        // クレジットカード払いの場合
        initApolloClient()
          .mutate({
            mutation: createStripeCharge,
            variables: {
              transactionId: this.model.id,
              source: this.token.id,
            },
          })
          .then(response => {
            if (response.data.createStripeCharge && response.data.createStripeCharge.success) {
              Turbolinks.visit(`/transactions/${response.data.createStripeCharge.transaction.activeRecordId}/built`);
            } else {
              this.errors = response.errors.map(e => ({ code: e.path.join('/'), message: e.message }));
            }
          })
          .catch(error => {
            console.error({ error });
            if (error.graphQLErrors) {
              this.errors = error.graphQLErrors.map((error, i) => ({
                code: i,
                message: error.message,
                disable_button: true,
              }));
            } else {
              this.handleError(error);
            }
          });
      } else {
        // 銀行振込の場合
        axios
          .patch(`/transactions/${this.model.activeRecordId}`, {
            transaction: { payment_method: this.model.paymentMethod },
          })
          .then(response => Turbolinks.visit(`/transactions/${this.model.activeRecordId}`))
          .catch(error => {
            this.errors = error.response.data.errors;
          });
      }
    },
    update() {
      this.complete = this.model.paymentMethod === 'bank_transfer' || (this.number && this.expiry && this.cvc);

      // field completed, find field to focus next
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.focus();
        } else if (!this.cvc) {
          this.$refs.cardCvc.focus();
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.focus();
        } else if (!this.number) {
          this.$refs.cardNumber.focus();
        }
      }
      // no focus magic for the CVC field as it gets complete with three
      // numbers, but can also have four
    },
    validateForm() {
      return true;
    },
    pay() {
      if (this.model.paymentMethod === 'credit_card') {
        createToken().then(data => {
          if (data.token === undefined || !this.isAcceptedCardBrand(data.token.card)) {
            alert('こちらのカードは対応していません');
            this.$emit('submissionFailed');
            return (this.submitted = false);
          }
          this.$emit('submissionDone');
          this.submitted = true;
          this.token = data.token;
        });
      } else if (this.model.paymentMethod === 'bank_transfer') {
        this.$emit('submissionDone');
        this.submitted = true;
      }
    },
    back() {
      this.errors = [];
      Turbolinks.visit(`/transactions/${this.transaction.activeRecordId}/shipping_address/edit`);
    },
    clearTokenBack() {
      // TODO: トークン情報を破棄する？その後クレジットカード情報入力画面に遷移する。
      this.errors = [];
      this.submitted = false;
    },
    isAcceptedCardBrand(card) {
      return card && this.acceptedCardBrands.includes(card.brand);
    },
    renderBreadCrumbs() {
      const breadcrumb = index => document.querySelector(`.p-transactionAddressee__breadcrubm:nth-child(${index})`);
      if (this.submitted) {
        breadcrumb(2).classList.remove('-active');
        breadcrumb(2).classList.add('-checked');
        breadcrumb(3).classList.add('-active');
      } else {
        breadcrumb(2).classList.add('-active');
        breadcrumb(2).classList.remove('-checked');
        breadcrumb(3).classList.remove('-active');
      }
    },
    handleError(text) {
      this.$emit('submissionFailed');
      this.$refs.panel.$emit('submissionFailed');
      this.$notify({
        group: 'error',
        type: 'error',
        duration: 5000,
        title: 'エラーが発生しました',
        text: text,
      });
    },
  },
};
</script>
