import { Controller } from 'stimulus';
import Glide from '@glidejs/glide';
import smoothscroll from 'smoothscroll-polyfill';

/**
 * キャンペーンページのJavaScript
 */
export default class extends Controller {
  static targets = ['close', 'popup', 'workPurchase'];

  initialize() {
    smoothscroll.polyfill();
  }

  connect() {
    // ぼやけた文字が明確になっていくアニメーション
    document.addEventListener('turbolinks:load', event => {
      this.removeBlur('.p-campaigns__artistAndModel', 200);
      this.removeBlur('.p-campaigns__workTitle', 400);
      this.removeBlur('.p-campaigns__workSubTitles', 600);
      this.removeBlur('.p-campaigns__limitedNum', 800);
    });
  }

  removeBlur(selector, timeout) {
    setTimeout(() => {
      const element = document.querySelector(selector);
      if (element) {
        element.classList.add('-after');
      }
    }, timeout);
  }

  // ポップアップを閉じる
  close() {
    this.popupTarget.classList.add('p-campaigns__popupWrapper--hide');
    setTimeout(() => this.popupTarget.classList.add('p-campaigns__popupWrapper--displayNone'), 500);
    document.body.style.position = '';
  }

  // ポップアップを閉じてアカウント作成モーダルを開く
  openSignUp() {
    this.close();
    Vue.prototype.$modal.show('login-modal', { initialForm: 'signup' });
  }

  // ポップアップを表示する
  showPopup() {
    const modal = this.popupTarget;
    if (modal) {
      modal.classList.remove('p-campaigns__popupWrapper--displayNone');
      modal.classList.remove('p-campaigns__popupWrapper--hide');
      window.scrollTo(0, 0);
      document.body.style.position = 'fixed';
    }
  }

  scrollToPurchase() {
    this.workPurchaseTarget.scrollIntoView({ behavior: 'smooth' });
  }

  initGlide() {
    new Glide('.glide').mount();
  }

  showSlideBlack(event) {
    this.showSlide(event, '.p-campaigns__slideBlack');
  }

  showSlidePlatinum(event) {
    this.showSlide(event, '.p-campaigns__slidePlatinum');
  }

  showSlide(event, slideSelector) {
    document.querySelector(slideSelector).classList.remove('display-none');
    const index = parseInt(event.currentTarget.dataset.index);
    new Glide(slideSelector, { startAt: index, gap: 0 }).mount();
  }

  closeSlide(event) {
    document.querySelectorAll('.glide').forEach(e => e.classList.add('display-none'));
  }
}
