import { Controller } from 'stimulus';

const ARTCONTRACT_VIDEO_TRACKS = [
  { s: 0, e: 9000 },
  { s: 9000, e: 13000 },
  { s: 13000, e: 26000 },
  { s: 26000, e: 39000 },
  { s: 39000, e: 49000 },
];

export default class extends Controller {
  static targets = ['hero', 'section', 'step', 'links', 'video_pc', 'video_sp', 'logo', 'loading', 'splash'];

  connect() {
    this.data.set('pc_src', this.video_pcTarget.getAttribute('src'));
    this.data.set('sp_src', this.video_spTarget.getAttribute('src'));
    setTimeout(() => {
      if (window.innerWidth < 768) {
        this.video_pcTarget.src = '';
      } else {
        this.video_spTarget.src = '';
      }
    }, 600);
  }

  getLoaded(e) {
    this.logoTarget.muted = true;
    this.data.set('loaded', 'true');
    this.loadingTarget.classList.add('is-hide');
    this.logoTarget.play();
    setTimeout(() => {
      this.splashTarget.classList.add('is-back');
    }, 2000);
  }

  hideHero() {
    // TODO: 動画の読み込みでheroから動かせるようにする。フラグも必要
    if (this.toBoolean(this.data.get('loaded'))) {
      Array.prototype.map.call(this.sectionTargets, (ele, i) => {
        ele.classList.add('is-show');
      });
      this.heroTarget.classList.add('is-hide');
      this.data.set('start', 'true');
      if (window.innerWidth < 768) {
        this.nextTrackSp(0);
      } else {
        this.showStep(0);
        this.selectTrack(0);
      }
    }
  }

  detectDirection(e) {
    if (this.toBoolean(this.data.get('start')) && this.video_pcTarget.paused && window.innerWidth > 768) {
      if (e.wheelDeltaY > 0) {
        // return this.toBoolean(this.data.get('trailing')) ? null : this.prevStep();
      } else {
        return this.toBoolean(this.data.get('trailing')) ? null : this.nextStep();
      }
    } else if (this.toBoolean(this.data.get('loaded')) && !this.toBoolean(this.data.get('start'))) {
      // Let user go to slideshow by scrolling from hero page
      if (e.wheelDeltaY < 0) {
        setTimeout(this.hideHero.bind(this), 1000);
      }
    }
  }

  detectDirectionSp(e) {
    if (this.toBoolean(this.data.get('loaded')) && !this.toBoolean(this.data.get('start'))) {
      // Let user go to slideshow by scrolling from hero page
      setTimeout(this.hideHero.bind(this), 1000);
    }
  }

  nextStep() {
    this.data.set('trailing', 'true');
    const step = parseInt(this.data.get('step')) > 3 ? 4 : parseInt(this.data.get('step')) + 1;
    this.hidePcArrowStep();

    if (step !== 4) {
      this.showStep(step);
      this.nextTrack(step);
    } else {
      this.showEndcontents();
    }
  }

  prevStep() {
    this.data.set('trailing', 'true');
    const step = parseInt(this.data.get('step')) < 1 ? 0 : parseInt(this.data.get('step')) - 1;
    this.showStep(step);
    this.selectTrack(step);
  }

  nextStepSp() {
    this.data.set('trailing', 'true');
    const step = parseInt(this.data.get('step')) > 3 ? 4 : parseInt(this.data.get('step')) + 1;
    this.data.set('step', step);
    if (step !== 4) {
      Array.prototype.map.call(this.stepTargets, (step, i) => {
        step.classList.remove('is-show');
      });
      this.nextTrackSp(step);
    } else {
      this.showEndcontents();
    }
  }

  showEndcontents() {
    this.data.set('step', 4);
    Array.prototype.map.call(this.stepTargets, (step, i) => {
      step.classList.remove('is-show');
      // if(i === _step) {
      //   setTimeout(()=>{
      //     step.classList.add('is-show');
      //     setTimeout(()=> {
      //       this.data.set('trailing', "false");
      //     }, 800);
      //   }, 600)
      // }
    });

    // Navigation section
    document.querySelector('.is-endcontents').classList.add('is-show');
    Array.prototype.map.call(this.linksTargets, (link, i) => {
      link.classList.remove('is-current');
      if (i === 5) {
        link.classList.add('is-current');
      }
    });
    if (window.innerWidth < 768) {
      this.nextTrackSp(4);
    } else {
      this.nextTrack(4);
    }
  }

  showStep(_step) {
    // TODO: is-showを全部消す→動画を流す→stepのものだけis-showする。
    // Step section
    this.data.set('step', _step);
    Array.prototype.map.call(this.stepTargets, (step, i) => {
      step.classList.remove('is-show');
      if (i === _step) {
        setTimeout(() => {
          step.classList.add('is-show');
          setTimeout(() => {
            this.data.set('trailing', 'false');
          }, 800);
        }, 600);
      }
    });

    // Navigation section
    Array.prototype.map.call(this.linksTargets, (link, i) => {
      link.classList.remove('is-current');
      if (i === _step) {
        link.classList.add('is-current');
      }
    });
  }

  // PCのスクロール矢印を表示させる
  showPcArrowStep(_step) {
    this.data.set('step', _step);
    Array.prototype.map.call(this.stepTargets, (step, i) => {
      if (i === _step) {
        setTimeout(() => {
          step.classList.add('is-pcArrowShow');
          setTimeout(() => {
            this.data.set('trailing', 'false');
          }, 800);
        }, 600);
      }
    });
  }

  // 動画ロードで矢印非表示
  hidePcArrowStep(_step) {
    this.data.set('step', _step);
    Array.prototype.map.call(this.stepTargets, (step, i) => {
      step.classList.remove('is-pcArrowShow');
    });
  }

  selectTrack(_step) {
    const duration = ARTCONTRACT_VIDEO_TRACKS[_step].e - ARTCONTRACT_VIDEO_TRACKS[_step].s;
    this.video_pcTarget.currentTime = ARTCONTRACT_VIDEO_TRACKS[_step].s / 1000;
    this.video_pcTarget.play();
    setTimeout(() => {
      this.video_pcTarget.pause();
    }, duration);
  }

  toBoolean(str) {
    return str.toLowerCase() === 'true';
  }

  nextTrack(_step) {
    this.video_pcTarget.play();
  }

  nextTrackSp(_step) {
    this.video_spTarget.play();
  }

  getPaused(e) {
    if (window.innerWidth < 769) {
      // TODO: 止まったら文章を表示
      const step = parseInt(this.data.get('step')) > 3 ? 4 : parseInt(this.data.get('step'));
      if (step !== 4) {
        this.showStep(step);
      }
    } else {
      // 止まったら矢印を表示
      const step = parseInt(this.data.get('step')) > 3 ? 4 : parseInt(this.data.get('step'));
      if (step !== 4) {
        this.showPcArrowStep(step);
      }

      if (this.data.get('step') === '3') {
        Array.prototype.map.call(this.linksTargets, (link, i) => {
          link.classList.remove('is-current');
          if (i === 4) {
            link.classList.add('is-current');
          }
        });
      }
    }
  }

  getTimeupdate(e) {
    if (window.innerWidth < 769) {
      // SP
      if (this.video_spTarget.currentTime > ARTCONTRACT_VIDEO_TRACKS[parseInt(this.data.get('step'))].e / 1000) {
        this.video_spTarget.pause();
      }
    } else {
      // PC
      if (this.video_pcTarget.currentTime > ARTCONTRACT_VIDEO_TRACKS[parseInt(this.data.get('step'))].e / 1000) {
        this.video_pcTarget.pause();
      }
    }
  }

  handleResize() {
    if (window.innerWidth < 768) {
      this.video_pcTarget.src = this.data.get('pc_src');
      this.video_pcTarget.load();
    } else {
      this.video_spTarget.src = this.data.get('sp_src');
      this.video_spTarget.load();
    }
  }

  stepContainerHandleClick() {
    if (window.innerWidth < 769 && !this.toBoolean(this.data.get('trailing'))) {
      this.nextStepSp();
    }
  }

  onClickLink(e) {
    // FIXME: バグがあるので一時封印。
    // this.showStep(parseInt(e.target.dataset.to));
    // this.selectTrack(parseInt(e.target.dataset.to));
  }
}
