window.checkMoney = function(value, validator, $field) {
  const val = remove_comma_from_number($field.val());

  const min = $field.attr('pricemin') || '1';
  const max = $field.attr('pricemax') || '1000000';

  return {
    valid: min <= val && val <= max,
    message: I18n.t('other.validations.price_range', {
      min: add_thousands_separator(min),
      max: add_thousands_separator(max),
    }),
  };
};
