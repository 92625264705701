import { Controller } from 'stimulus';
import moment from 'moment';
import axios from 'axios';

export default class extends Controller {
  static targets = ['posts'];

  axiosInstance = axios.create({
    baseURL: 'https://media.b-ownd.com/wp-json/wp/v2',
  });

  /**
   * B-OWND Magazineのサムネイルを取得して表示する
   */
  loadPosts = () => {
    this.axiosInstance.interceptors.request.use(config => {
      const clone = JSON.parse(JSON.stringify(config));
      delete clone.headers.common['X-CSRF-Token'];
      return clone;
    });

    this.axiosInstance.get('/posts?categories=16').then(response => {
      JSON.parse(response.data)
        .slice(0, 3)
        .forEach(item => {
          this._feedItemElement(item);
        });
    });
  };

  /**
   * 記事単体の要素を生成して返す
   */
  async _feedItemElement(post) {
    const div = document.createElement('div');
    div.classList.add('p-about__media-line');
    this.postsTarget.appendChild(div);

    div.innerHTML = `
      <div class="p-about__media-date-category">
        <div class="p-about__media-date">${moment(post.date).format('YYYY.MM.DD')}</div>
        <div class="p-about__media-category">掲載情報</div>
      </div>
      <div class="p-about__media-text">
        <a href="${post.link}">${post.title.rendered.replace(/　をアップロードしました。$/, '')}</a>
      </div>
    `;
  }
}
