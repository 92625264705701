<template>
  <button
    class="b-clipboard-button"
    type="button"
    v-clipboard="source"
    v-clipboard:success="onCopy"
    v-clipboard:error="onError"
  >
    リンクをコピーする
  </button>
</template>

<script>
export default {
  props: ['source'],
  methods: {
    onCopy: function(e) {
      alert('リンクがコピーされました。' + e.text);
    },
    onError: function(e) {
      alert('リンクのコピーに失敗しました。');
    },
  },
};
</script>

<style lang="scss">
.b-clipboard-button {
  background: none;
  border: none;
  color: inherit;
  font-size: 1.2rem;
  line-height: 2;
  font-weight: 600;
  cursor: pointer;
}
</style>
