<template>
  <div class="p-transactionCreditCard__form">
    <div v-show="!submitted" class="p-transactionCreditCard__formInput">
      <stripe-credit-card-input @back="back" @forward="forward"></stripe-credit-card-input>
    </div>
    <transaction-info-panel v-show="submitted" :model="model" :token="last4number">
      <template slot="buttons">
        <button class="p-transactionCreditCard__formInputItemBtn" @click.prevent="clearTokenBack">戻る</button>
        <general-submit-button class="p-transactionCreditCard__formInputItemBtn" @submitting="sendCreditCardToken">
          ご注文を確定する
        </general-submit-button>
      </template>
    </transaction-info-panel>
  </div>
</template>

<script>
import { CardNumber, CardExpiry, CardCvc, createToken } from 'vue-stripe-elements-plus';
import StripeCreditCardInput from './components/stripe_credit_card_input.vue';
import GeneralSubmitButton from '../buttons/general_submit_button.vue';

export default {
  components: {
    StripeCreditCardInput,
    GeneralSubmitButton,
  },
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      complete: false,
      submitted: false,
      certPrice: 5000,
      stripePublishableKey: process.env.STRIPE_PUBLISHABLE_KEY,
      options: {},
      model: Object.assign(this.reservation, {
        paymentMethod: 'credit_card',
        artworkPrice: this.reservation.priceCents,
      }),
      token: {},
      acceptedCardBrands: ['Visa', 'MasterCard', 'American Express', 'JCB', 'Discover', 'Diners Club'],
    };
  },
  watch: {
    submitted() {
      this.renderBreadCrumbs();
    },
  },
  computed: {
    last4number() {
      return this.token.card ? this.token.card.last4 : '';
    },
  },
  methods: {
    validateForm() {
      return true;
    },
    pay() {
      createToken().then(data => {
        if (data.token === undefined || !this.isAcceptedCardBrand(data.token.card)) {
          alert('こちらのカードは対応していません');
          this.$emit('submissionFailed');
          return (this.submitted = false);
        }
        this.$emit('submissionDone');
        this.token = data.token;
        this.submitted = true;
      });
    },
    back() {
      Turbolinks.visit(`/reservations/${this.model.activeRecordId}/address/edit`);
    },
    clearTokenBack() {
      this.submitted = false;
      this.$emit('submissionDone');
    },
    forward(token) {
      this.token = token;
      this.submitted = true;
    },
    onInvalidCard() {
      console.warn('invalid card!');
    },
    sendCreditCardToken() {
      axios
        .post(`/reservations/${this.reservation.activeRecordId}/credit_card`, {
          token: this.token.id,
        })
        .then(() => {
          Turbolinks.visit(`/reservations/${this.reservation.activeRecordId}`);
        })
        .catch(() => {});
    },
    renderBreadCrumbs() {
      const breadcrumb = index => document.querySelector(`.p-transactionAddressee__breadcrubm:nth-child(${index})`);
      if (this.submitted) {
        breadcrumb(2).classList.remove('-active');
        breadcrumb(2).classList.add('-checked');
        breadcrumb(3).classList.add('-active');
      } else {
        breadcrumb(2).classList.add('-active');
        breadcrumb(2).classList.remove('-checked');
        breadcrumb(3).classList.remove('-active');
      }
    },
  },
};
</script>
