<template>
  <div class="p-transactionCreditCard__form">
    <div class="p-transactionCreditCard__formInput">
      <form v-show="!submitted" ref="form">
        <input name="utf8" type="hidden" value="✓" />
        <div class="p-transactionCreditCard__info">
          <div class="p-transactionCreditCard__infoFlex">
            <h3 class="p-transactionCreditCard__infoTtl">クレジットカード情報</h3>

            <div class="p-transactionCreditCard__infoImg">
              <img src="../images/creditcard/visa.svg" />
              <img src="../images/creditcard/master-card.svg" />
              <img src="../images/creditcard/american_express.svg" />
              <img src="../images/creditcard/jcb.svg" />
              <img src="../images/creditcard/discover.svg" />
              <img src="../images/creditcard/diners.svg" />
            </div>
          </div>

          <p class="p-transactionCreditCard__notice is-red">
            ※クレジットカード情報はセキュリティ上、保存されません。
          </p>
        </div>

        <div :class="{ complete }">
          <div class="form-group">
            <label class="required">カード番号</label>
            <card-number
              ref="cardNumber"
              :stripe="stripePublishableKey"
              :options="options"
              class="stripe-element card-number"
              @change="number = $event.complete"
            />
          </div>

          <div class="form-group">
            <label class="required">有効期限（月/年）</label>
            <card-expiry
              ref="cardExpiry"
              :stripe="stripePublishableKey"
              :options="options"
              class="stripe-element card-expiry"
              @change="expiry = $event.complete"
            />
          </div>
          <div class="form-group">
            <label class="required">セキュリティコード</label>
            <card-cvc
              ref="cardCvc"
              :stripe="stripePublishableKey"
              :options="options"
              class="stripe-element card-cvc"
              @change="cvc = $event.complete"
            />
          </div>
          <div class="p-transactionCreditCard__formInputItemBtns">
            <button class="p-transactionCreditCard__formInputItemBtn" @click.prevent="back">戻る</button>
            <general-submit-button
              :disabled="!complete"
              class="p-transactionCreditCard__formInputItemBtn"
              @submitting="pay"
            >
              次へ
            </general-submit-button>
          </div>
        </div>
      </form>

      <donation-info-panel v-show="submitted" :model="model" :token="last4number">
        <template slot="buttons">
          <button class="p-transactionCreditCard__formInputItemBtn" @click.prevent="clearTokenBack">戻る</button>
          <general-submit-button class="p-transactionCreditCard__formInputItemBtn" @submitting="sendCreditCardToken">
            支払いを確定する
          </general-submit-button>
        </template>
      </donation-info-panel>
    </div>
  </div>
</template>

<script>
import { CardNumber, CardExpiry, CardCvc, createToken } from 'vue-stripe-elements-plus';
import initApolloClient from '../apollo/client.js';
import DonationInfoPanel from './donation_info_panel.vue';
import ServiceAgreementsStripe from './service_agreements/stripe.vue';
import createStripeCharge from '../graphql/mutations/create_stripe_charge.js';
import GeneralSubmitButton from '../buttons/general_submit_button.vue';

export default {
  components: {
    CardNumber,
    CardExpiry,
    CardCvc,
    DonationInfoPanel,
    GeneralSubmitButton,
    'service-agreements-stripe': ServiceAgreementsStripe,
  },
  props: {
    donation: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      complete: false,
      number: false,
      expiry: false,
      cvc: false,
      submitted: false,
      certPrice: 5000,
      stripePublishableKey: process.env.STRIPE_PUBLISHABLE_KEY,
      options: {},
      model: this.donation || {},
      token: {},
      acceptedCardBrands: ['Visa', 'MasterCard', 'American Express', 'JCB', 'Discover', 'Diners Club'],
    };
  },
  computed: {
    last4number() {
      return this.token.card ? this.token.card.last4 : '';
    },
  },
  watch: {
    number() {
      this.update();
    },
    expiry() {
      this.update();
    },
    cvc() {
      this.update();
    },
  },
  methods: {
    sendCreditCardToken() {
      const form = document.getElementById('donation-form');
      form.querySelector('input[name="charge_token"]').value = this.token.id;
      form.submit();
    },
    update() {
      this.complete = this.number && this.expiry && this.cvc;

      // field completed, find field to focus next
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.focus();
        } else if (!this.cvc) {
          this.$refs.cardCvc.focus();
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.focus();
        } else if (!this.number) {
          this.$refs.cardNumber.focus();
        }
      }
      // no focus magic for the CVC field as it gets complete with three
      // numbers, but can also have four
    },
    validateForm() {
      return true;
    },
    pay() {
      // TODO: トークン情報をサーバーに送信して確認画面を表示させる。
      createToken().then(data => {
        if (data.token === undefined || !this.isAcceptedCardBrand(data.token.card)) {
          alert('こちらのカードは対応していません');
          this.$emit('submissionFailed');
          return (this.submitted = false);
        }
        this.$emit('submissionDone');
        this.token = data.token;
        this.submitted = true;
      });
    },
    back() {
      window.history.back();
    },
    clearTokenBack() {
      // TODO: トークン情報を破棄する？その後クレジットカード情報入力画面に遷移する。
      this.submitted = false;
    },
    isAcceptedCardBrand(card) {
      return card && this.acceptedCardBrands.includes(card.brand);
    },
  },
};
</script>
