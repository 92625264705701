/*
 * decaffeinate suggestions:
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// assume today is 2015/6/24
// convertToMoment('now') -> 2015-06-24 00:00
// convertToMoment('1 year') -> 2016-06-24 00:00
// convertToMoment('next year') -> 2016-06-24 00:00

window.convertToMoment = function(s, format) {
  if (s == null) {
    return false;
  }
  if (s === 'now') {
    s = '0 days';
  }
  const offset = s.split(' ');

  format = format || 'YYYY-MM-DD HH:mm';
  // next or previous case
  offset[0] = (() => {
    switch (offset[0]) {
      case 'this':
        return 0;
      case 'next':
        return 1;
      case 'prev':
        return -1;
      default:
        return offset[0];
    }
  })();
  if (offset[0] === 'endOf') {
    return moment(new Date())
      .endOf(offset[1])
      .format(format);
  }
  if (offset[0] === 'startOf') {
    return moment(new Date())
      .startOf(offset[1])
      .format(format);
  }
  return moment(new Date())
    .add(offset[0], offset[1])
    .format(format);
};
