document.addEventListener('turbolinks:load', () => {
  $(document).on('click', '.tt-open', function() {
    $('.tt').hide();
    $(this)
      .next('.tt')
      .fadeIn();
  });
  $(document).on('click', '.tt-close', () => {
    $('.tt').hide();
  });
});
