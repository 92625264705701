import gql from 'graphql-tag';

const shippingAddressMutation = gql`
  mutation attachNewAddressToTransaction(
    $country: String!
    $state: String!
    $city: String!
    $line1: String!
    $line2: String
    $postalCode: String!
    $name: String!
    $phone: String!
    $transactionId: ID!
  ) {
    attachNewAddressToTransaction(
      shipping: {
        address: {
          country: $country
          state: $state
          city: $city
          line1: $line1
          line2: $line2
          postalCode: $postalCode
        }
        name: $name
        phone: $phone
      }
      transactionId: $transactionId
    ) {
      success
      transaction {
        activeRecordId
      }
    }
  }
`;

export default shippingAddressMutation;
