/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// CSS file
import 'jquery.initialize';
import 'moment';
import 'jquery-ui';

import 'babel-polyfill';
import 'unfetch/polyfill';

import 'imagesloaded/imagesloaded.pkgd.min';

import 'lazysizes/lazysizes.min';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.min';

import 'datatables.net-dt/css/jquery.dataTables.css';

import 'morris.js/morris.min';
import 'raphael/raphael.min';
import 'jquery-countdown/dist/jquery.countdown';
import 'select2/dist/js/select2.min';
import 'select2/dist/css/select2.css';

import jQueryBridget from 'jquery-bridget';
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

// Vue Component Registration
import TurbolinksAdapter from 'vue-turbolinks';
import StarRating from 'vue-star-rating';
import VueCountdown from '@xkeshi/vue-countdown';
import VueTabs from 'vue-nav-tabs';
import vmodal from 'vue-js-modal';
import VueFormGenerator from 'vue-form-generator/dist/vfg-core.js';
import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import notifications from 'vue-notification';
import velocity from 'velocity-animate';
import VueStripeCheckout from 'vue-stripe-checkout';
import VueReadMore from 'vue-read-more';
import VueCarousel from 'vue-carousel';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import VueApollo from 'vue-apollo';
import VueLazyload from 'vue-lazyload';
import VueYouTubeEmbed from 'vue-youtube-embed';
import ShippingAddressForm from '../forms/shipping_address.vue';
import StripeCreditCardPayment from '../forms/stripe_credit_card_payment.vue';
import StripeCreditCardDonation from '../forms/stripe_credit_card_donation.vue';
import StripeCreditCardRegister from '../forms/stripe_credit_card_register.vue';
import StripeCreditCardInput from '../forms/components/stripe_credit_card_input.vue';
import TransactionInfoPanel from '../forms/transaction_info_panel.vue';
import DonationInfoPanel from '../forms/donation_info_panel.vue';
import CreateStripeCharge from '../graphql/mutations/create_stripe_charge.js';
import VueClipboard from 'vue-clipboard2';

import '../styles/style.scss';

import '../i18n/translations';
import vueI18n from '../helpers/vueI18n';
import confirmationPlugin from '../plugins/confirmation';
import setupCSRFToken from '../setupCSRFToken';
import MarkButton from '../buttons/mark_button.vue';
import FollowOrMarkButton from '../buttons/follow_or_mark_button.vue';
import SearchSuggest from '../search/search_suggest.vue';
import EventHub from '../event_hub';

// Buttons
import ShowLoginModalButton from '../buttons/show_login_modal_button.vue';
import ShowWorksDetailModalButton from '../buttons/show_works_detail_modal_button.vue';
import ShowWorksPurchaseModalButton from '../buttons/show_works_purchase_modal_button.vue';
import GeneralSubmitButton from '../buttons/general_submit_button.vue';
import ShowWorksShareModalButton from '../buttons/show_works_share_modal_button.vue';
import ClipboardButton from '../buttons/clipboard_button';
import ShowWorksModalButton from '../buttons/show_works_modal_button.vue';
import PurchaseButton from '../buttons/purchase_button.vue';

// Modal
import LoginModal from '../modal/login_modal.vue';
import WorksDetailModal from '../modal/works_detail_modal.vue';
import WorksPurchaseModal from '../modal/works_purchase_modal.vue';
import ArtistWorksCarouselModal from '../modal/artist_works_carousel_modal.vue';
import WorksShareModal from '../modal/works_share_modal.vue';
import WorkModal from '../modal/work_modal';
import WorksModal from '../modal/works_modal.vue';
import AboutMemberModal from '../modal/about_member_modal.vue';
import ReservationInfoModal from '../modal/reservation_info_modal.vue';

// Artist
import ArtistWorkImage from '../artist/artist_work_image.vue';

// Gallery
import WorksGallery from '../work/gallery.vue';

// Work
import worksSinglePage from '../work/works_single_page.vue';
import worksFooter from '../work/works_footer.vue';
import worksPurchaseActionButton from '../work/works_purchase_action_button.vue';
import worksImageCarousel from '../work/works_image_carousel.vue';

// Cropper
import avatarPictureCropper from '../picture_cropper/avatar_picture_cropper.vue';

// YouTube Player
import YouTubePlayer from '../youtube_player/youtube_player.vue';
// Accordion
import artistProfileAccordion from '../accordion/artist_profile_accordion.vue';

// Readmore
import artistProfileReadmore from '../readmore/artist_profile_readmore.vue';

// Images
import certFingerprintIcon from '../images/works/cert_fingerprint.vue';

// Reservation
import RemainingTimes from '../reservation/remaining_times';
import RemainingTimesPlane from '../reservation/remaining_times-plane';

// Transaction
import CertNameInput from '../transaction/cert_name_input.vue';

// Other
import StimulusEvent from '../stimulus/stimulus_event.vue';

Vue.use(EventHub);

Vue.component('avatar-picture-cropper', avatarPictureCropper);
Vue.component('shipping-address-form', ShippingAddressForm);
Vue.component('stripe-credit-card-register-form', StripeCreditCardRegister);
Vue.component('stripe-credit-card-payment-form', StripeCreditCardPayment);
Vue.component('stripe-credit-card-donation-form', StripeCreditCardDonation);
Vue.component('stripe-credit-card-input', StripeCreditCardInput);
Vue.component('transaction-info-panel', TransactionInfoPanel);
Vue.component('donation-info-panel', DonationInfoPanel);
Vue.component('create-stripe-charge', CreateStripeCharge);

const imagesLoaded = require('imagesloaded');

imagesLoaded.makeJQueryPlugin($);
jQueryBridget('isotope', Isotope, $);
Rails.start();
Turbolinks.start();
I18n.defaultLocale = 'ja';
I18n.locale = 'ja';
document.addEventListener('turbolinks:load', setupCSRFToken);

Vue.use(VueFormGenerator);
Vue.use(confirmationPlugin);
Vue.use(TurbolinksAdapter);
Vue.use(vmodal, { dialog: true, dynamic: true });
Vue.use(vueI18n);
Vue.use(VueTabs);
Vue.use(notifications, { velocity });
Vue.use(VueReadMore);
Vue.use(VueLazyload);
Vue.use(VueYouTubeEmbed);
Vue.use(VueClipboard);

const stripeCheckoutOptions = {
  key: document.querySelector('meta[property=stripe_publishable_key]').getAttribute('content'),
  billingAddress: true,
  shippingAddress: true,
};

Vue.use(VueStripeCheckout, stripeCheckoutOptions);
Vue.use(VueCarousel);

// Suggest
Vue.component('search-suggest', SearchSuggest);

// Buttons
Vue.component('show-login-modal-button', ShowLoginModalButton);
Vue.component('show-works-detail-modal-button', ShowWorksDetailModalButton);
Vue.component('mark-button', MarkButton);
Vue.component('follow-or-mark-button', FollowOrMarkButton);
Vue.component('show-works-purchase-modal-button', ShowWorksPurchaseModalButton);
Vue.component('gheneral-submit-button', GeneralSubmitButton);
Vue.component('show-works-share-modal-button', ShowWorksShareModalButton);
Vue.component('clipboard-button', ClipboardButton);
Vue.component('show-works-modal-button', ShowWorksModalButton);
Vue.component('purchase-button', PurchaseButton);

// Modal
Vue.component('login-modal', LoginModal);
Vue.component('works-detail-modal', WorksDetailModal);
Vue.component('works-purchase-modal', WorksPurchaseModal);
Vue.component('artist-works-carousel-modal', ArtistWorksCarouselModal);
Vue.component('works-share-modal', WorksShareModal);
Vue.component('work-modal', WorkModal);
Vue.component('works-modal', WorksModal);
Vue.component('about-member-modal', AboutMemberModal);
Vue.component('reservation-info-modal', ReservationInfoModal);

// Artist
Vue.component('artist-work-image', ArtistWorkImage);

// Works Gallery
Vue.component('works-gallery', WorksGallery);

// Work
Vue.component('works-single-page', worksSinglePage);
Vue.component('works-purchase-action-button', worksPurchaseActionButton);
Vue.component('works-footer', worksFooter);
Vue.component('works-image-carousel', worksImageCarousel);

// YouTube Player
Vue.component('youtube-player', YouTubePlayer);

// Accordion
Vue.component('artist-profile-accordion', artistProfileAccordion);

// Readmore
Vue.component('artist-profile-readmore', artistProfileReadmore);

// Images
Vue.component('cert-fingerprint-icon', certFingerprintIcon);

// Reservation
Vue.component('remaining-times', RemainingTimes);
Vue.component('remaining-times-plane', RemainingTimesPlane);

// Transaction
Vue.component('cert-name-input', CertNameInput);

// Other
Vue.component('stimulus-event', StimulusEvent);

function requireAll(r) {
  r.keys().forEach(r);
}
requireAll(require.context('../vendor', true, /\.(js)$/));
require.context('../images', true);
requireAll(require.context('../common/', true, /\.js$/));

VueFormGenerator.validators.resources.fieldIsRequired = Vue.prototype.$t.t('errors.messages.required');
VueFormGenerator.validators.resources.textTooSmall = Vue.prototype.$t.t('errors.messages.too_short');
VueFormGenerator.validators.resources.numberTooSmall = Vue.prototype.$t.t('errors.messages.less_than');

Vue.use(VueApollo);

const application = Application.start();
const context = require.context('controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

import initApolloClient from '../apollo/client';
document.addEventListener('turbolinks:load', () => {
  const apolloProvider = new VueApollo({
    defaultClient: initApolloClient(),
  });

  const app = new Vue({
    el: document.querySelector('#app'),
    apolloProvider,
  });
});
