import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'modal', 'other', 'submenu'];

  connect() {
    // メニューのダイアログ開閉
    this.modalTarget.addEventListener('transitionend', event => {
      const target = event.currentTarget;
      if (window.getComputedStyle(target).getPropertyValue('opacity') === '0') {
        target.classList.add('display-none');
      }
    });

    this.buttonTarget.addEventListener('click', event => {
      this.modalTarget.classList.remove('display-none');
      event.currentTarget.classList.toggle('-open');
      document.body.classList.toggle('overflow-hidden');
      setTimeout(() => {
        this.modalTarget.classList.toggle('-open');
      }, 1);
      this.element.classList.remove('-z-index-1000');
    });
    // サブメニュー開閉
    this.otherTarget.addEventListener('click', event => {
      event.currentTarget.classList.toggle('-open');
      this.submenuTarget.classList.toggle('-open');
    });
  }

  // ポップアップを閉じてアカウント作成モーダルを開く
  openSignUp() {
    Vue.prototype.$modal.show('login-modal', { initialForm: 'signup' });
  }
}
