/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
document.addEventListener('turbolinks:load', () => {
  if ($('body').hasClass('registration') || $('body').hasClass('edit')) {
    const typeDescriptions = {
      Artist: I18n.t('user.registration.artist'),
      Reviewer: I18n.t('user.registration.reviewer'),
      Collector: I18n.t('user.registration.collector'),
    };

    const changeDescription = _value => $('.type-description').text(typeDescriptions[_value]);

    $('#user_user_type').change(function() {
      return changeDescription($(this).val());
    });

    return changeDescription($('#user_user_type option:selected').val());
  }
});
