<template>
  <div>
    <modal
      name="artist-works-carousel-modal"
      :adaptive="true"
      width="100%"
      height="100%"
      @before-open="beforeOpen"
      @closed="removeOpenClass"
    >
      <div class="b-artistWorksCarousel">
        <carousel
          :navigateTo="[selectedIndex, false]"
          :per-page="1"
          :navigation-enabled="true"
          :mouse-drag="false"
          :loop="true"
          :pagination-enabled="false"
        >
          <slide v-for="work in sortedWorks" :key="work.id">
            <div class="b-artistWorksCarousel__image" @click="closeModal">
              <img :src="images[work.id]" :alt="work.title" />
            </div>
          </slide>
        </carousel>
      </div>
    </modal>
    <div :class="{ 'b-artistWorksCarousel__background': fillBackground }"></div>
  </div>
</template>

<script>
export default {
  props: {
    works: {
      type: Array,
      default: [],
      required: false,
    },
    images: {
      type: Object,
      default: [],
      required: false,
    },
  },
  data() {
    return {
      sortedWorks: this.works,
      fillBackground: false,
      selectedIndex: this.selectedIndex,
    };
  },
  beforeDestroy: function() {
    window.removeEventListener('touchmove', this.preventScroll, { passive: false });
  },
  methods: {
    beforeOpen(e) {
      this.selectedIndex = e.params.index;
      this.fillBackground = true;
      window.addEventListener('touchmove', this.preventScroll, { passive: false });
    },
    closeModal() {
      this.$modal.hide('artist-works-carousel-modal');
    },
    removeOpenClass() {
      window.removeEventListener('touchmove', this.preventScroll, { passive: false });
      this.fillBackground = false;
    },
    preventScroll(e) {
      e.preventDefault();
    },
  },
};
</script>
