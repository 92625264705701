<template>
  <div class="b-workSinglePage__footer" :data-work-id="work.id" :data-from="from" ref="footer">
    <a
      v-if="modal"
      class="b-workSinglePage__footerBtnLarge"
      @click="$modal.hide('work-modal')"
      style="cursor: pointer"
      ref="back"
    >
      閉じる
    </a>
    <a
      v-else
      :href="work.artist_works_url"
      data-turbolinks="false"
      class="b-workSinglePage__footerBtnLarge"
      ref="back"
      style="display:none"
    >
      販売作品一覧を見る
    </a>

    <a v-if="modal" class="b-workSinglePage__footerNextBtn" ref="next" style="display:none" @click="openNextWork">
      次の作品を見る
    </a>
    <a v-else class="b-workSinglePage__footerNextBtn" ref="next" style="display:none"> 次の作品を見る </a>
    <div v-if="work.limited_edition" class="b-workSinglePage__footerLimitedNum">
      LIMITED EDITION <span class="b-workSinglePage__footerLimitedNumBody">{{ work.limited_num }}</span>
    </div>
    <div class="b-workSinglePage__footerRight">
      <!-- シェアモーダルボタン -->
      <show-works-share-modal-button></show-works-share-modal-button>

      <!--
        お気に入りボタン
        未ログインユーザはログインモーダルを表示
        アーティストには表示しない
      -->
      <template v-if="!isArtist">
        <mark-button v-if="isUser" :markable-id="markableId" :is-marking="isMarking"></mark-button>
        <div v-else class="b-workSinglePage__footerBtn--like">
          <show-login-modal-button>
            <svg xmlns="http://www.w3.org/2000/svg" width="17.438" height="16" viewBox="0 0 17.438 16" fill="#fff">
              <path
                d="M14.643 3a5.221 5.221 0 0 0-3.924 1.822A5.221 5.221 0 0 0 6.8 3 4.749 4.749 0 0 0 2 7.8c0 3.3 2.965 5.981 7.455 10.062L10.719 19l1.264-1.151c4.49-4.072 7.455-6.757 7.455-10.053A4.749 4.749 0 0 0 14.643 3zm-3.837 13.559l-.087.087-.087-.087C6.482 12.8 3.744 10.316 3.744 7.8A2.98 2.98 0 0 1 6.8 4.744 3.409 3.409 0 0 1 9.908 6.8h1.631a3.387 3.387 0 0 1 3.1-2.058A2.98 2.98 0 0 1 17.695 7.8c0 2.516-2.738 5-6.889 8.759z"
                class="cls-1"
                transform="translate(-2 -3)"
              />
            </svg>
          </show-login-modal-button>
        </div>
      </template>

      <!-- 作品詳細モーダルボタン -->
      <show-works-detail-modal-button v-if="isForSale"></show-works-detail-modal-button>

      <!-- Purchase Button + Detail Button -->
      <template v-if="!isArtist">
        <div v-if="!isForSale" class="b-workSinglePage__footerIsNotSale">非売品</div>
        <!--
          購入モーダルボタン
          未ログインユーザはログインモーダルを表示
        -->
        <works-purchase-action-button
          v-else-if="isUser"
          :work-id="work.id"
          :is-buyer="isBuyer"
          :is-buyable="isBuyable"
          :campaign-id="campaignId"
          :transaction-link="transactionLink"
        >
        </works-purchase-action-button>
        <div v-else-if="campaignId" class="b-workSinglePage__purchaseBtn">
          <a :href="`/campaigns/${campaignId}`">購入手続きに進む</a>
        </div>
        <div v-else-if="isBuyable" class="b-workSinglePage__purchaseBtn">
          <show-login-modal-button :purchase-work-id="work.id">購入手続きに進む</show-login-modal-button>
        </div>
        <div v-else class="b-workSinglePage__footerSold">売済</div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    isUser: {
      type: Boolean,
      required: true,
    },
    isBuyer: {
      type: Boolean,
      required: true,
    },
    isArtist: {
      type: Boolean,
      required: true,
    },
    isBuyable: {
      type: Boolean,
      required: true,
    },
    transactionLink: {
      type: String,
      required: false,
    },
    markableId: {
      type: String,
      required: true,
    },
    isMarking: {
      type: Boolean,
      required: true,
    },
    isForSale: {
      type: Boolean,
      required: true,
    },
    work: {
      type: Object,
      required: true,
    },
    from: {
      type: String,
    },
    modal: {
      type: Boolean,
    },
    campaignId: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      works: [],
    };
  },

  mounted() {
    const footer = this.$refs.footer;
    const back = this.$refs.back;
    const next = this.$refs.next;

    const from = footer.dataset.from;
    const queryParam = from ? `?from=${from}` : '';

    if (this.modal) {
      axios
        .get(`${from}.json`)
        .then(response => {
          const workId = parseInt(footer.dataset.workId);
          this.works = response.data.works;
          const nextWorkId = this.next(this.works, workId);
          back.textContent = response.data.back_label;
          back.style.display = '';
          next.dataset.nextWorkId = nextWorkId;
          next.style.display = '';
        })
        .catch(error => {
          console.error(error);
        });
      return;
    }

    if (from === undefined) {
      back.style.display = '';
      return;
    }

    axios
      .get(`${from}.json`)
      .then(response => {
        const workId = parseInt(footer.dataset.workId);
        this.works = response.data.works;
        const nextWorkId = this.next(this.works, workId);
        back.textContent = response.data.back_label;
        back.setAttribute('href', `${response.data.back_to}#work-${workId}`);
        back.style.display = '';
        next.setAttribute('href', `/works/${nextWorkId}${queryParam}`);
        next.style.display = '';
      })
      .catch(error => {
        console.error(error);
      });
  },

  methods: {
    next(array, current) {
      const currentIndex = array.indexOf(current);
      const nextIndex = (currentIndex + 1) % array.length;
      return array[nextIndex];
    },
    openNextWork() {
      this.$modal.hide('work-modal');

      const next = this.$refs.next;
      const workId = parseInt(next.dataset.nextWorkId);
      const nextWorkId = this.next(this.works, workId);
      next.dataset.nextWorkId = nextWorkId;

      this.$modal.show('work-modal', { id: workId, from: this.from });
    },
  },
};
</script>
