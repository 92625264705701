window.checkDatetime = function(value, validator, $field) {
  const val = $field.val();
  let message = $field.data('fv-callback-message');

  let valid = true;
  const min = convertToMoment($field.data('mindate'));
  const max = convertToMoment($field.data('maxdate'));

  if (min) {
    const min_valid = min < val;
    if (valid) {
      valid = min_valid;
    }
    if (!min_valid) {
      message += I18n.t('other.validations.time_min_html', { min });
    }
  }

  if (max) {
    const max_valid = val < max;
    if (valid) {
      valid = max_valid;
    }
    if (!max_valid) {
      message += I18n.t('other.validations.time_max_html', { max });
    }
  }

  return {
    valid,
    message,
  };
};
