<template>
  <div v-cloak class="b-artistProfileReadmore">
    <p class="b-artistProfileReadmore__conceptText" :class="{ 'is-open': isOpened }"><slot name="body" /></p>

    <div class="b-artistProfileReadmore__conceptTextReadMore" :class="{ 'is-open': isOpened }">
      <button class="b-artistProfileReadmore__btn" type="button" @click="readmoreLink();">続きを読む</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpened: false,
    };
  },
  methods: {
    readmoreLink() {
      this.isOpened = !this.isOpened;
    },
  },
};
</script>
