<template>
  <modal
    name="works-share-modal"
    :adaptive="true"
    width="320px"
    height="320px"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="b-worksShareModal" @click="closeWorksShareModal">
      <a @click="closeWorksShareModal" class="b-worksShareModal__closeBtn"></a>

      <p class="b-worksShareModal__ttl">シェアする</p>

      <div class="b-worksShareModal__btnWrapper">
        <a
          v-bind:href="facebookShareUrl"
          rel="nofollow"
          target="_blank"
          class="b-worksShareModal__btn"
          @click="stopClosingModal"
        >
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 13.3 25.6" fill="#000">
            <path
              d="M3.9,25.6V13.9l0,0v0H0V9.4h3.9V6c0-1.1,0.2-2.1,0.7-3.1c0,0,0-0.1,0.1-0.1c0.1-0.1,0.1-0.3,0.2-0.4 c0-0.1,0.1-0.1,0.1-0.2C5,2.2,5.1,2.1,5.2,2c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0.1-0.1,0.1-0.1 c0.1-0.1,0.2-0.2,0.3-0.3l0,0C7.1,0.4,8.4,0,9.8,0c1.2,0,2.3,0.1,3.5,0.2v4.1h-2.4C9,4.3,8.6,5.2,8.6,6.5v2.9l0,0v0h4.5l-0.6,4.5 H8.6v11.7H3.9L3.9,25.6z"
            />
          </svg>
        </a>

        <a
          v-bind:href="twitterShareUrl"
          rel="nofollow"
          target="_blank"
          class="b-worksShareModal__btn"
          @click="stopClosingModal"
        >
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 25.6" fill="#000">
            <path
              d="M15.8,7.9L15.8,7.9c-0.1-0.5-0.2-1-0.2-1.5c0-3.6,3-6.5,6.6-6.5C24,0,25.7,0.7,27,2 c1.2-0.2,2.4-0.6,3.5-1.2c0.2-0.1,0.5-0.2,0.7-0.4c-0.4,1.2-1.1,2.2-2.1,3c-0.2,0.2-0.5,0.4-0.8,0.5l0,0c0,0,0,0,0,0 c1.1-0.1,2.1-0.4,3.1-0.7c0.2-0.1,0.4-0.2,0.7-0.2c-0.9,1.3-2,2.4-3.3,3.3c0,0.3,0,0.6,0,0.8c0,10.2-8.2,18.4-18.4,18.4 c-0.1,0-0.2,0-0.3,0c-3.6,0-7.1-1-10.1-2.9c0.5,0.1,1,0.1,1.6,0.1c2.2,0,4.3-0.5,6.2-1.5c0,0,0.1,0,0.1-0.1C8,21.1,8.1,21.1,8.2,21 c0.5-0.3,1-0.6,1.5-1h0l0,0c-2.4,0-4.6-1.4-5.7-3.5c-0.2-0.3-0.3-0.6-0.4-0.9c0.4,0.1,0.8,0.1,1.2,0.1c0.4,0,0.8,0,1.3-0.1 c0.2,0,0.3,0,0.5-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-3-0.6-5.3-3.2-5.3-6.3V9C2,9.4,2.8,9.7,3.6,9.8c0.2,0,0.4,0.1,0.7,0.1l0,0 c0,0,0,0,0,0C2.5,8.6,1.4,6.6,1.4,4.4c0-1,0.2-2,0.7-2.8c0.1-0.1,0.1-0.3,0.2-0.4c3.3,4,8.1,6.4,13.3,6.7 C15.6,7.9,15.7,7.9,15.8,7.9L15.8,7.9z"
            />
          </svg>
        </a>

        <a class="b-worksShareModal__btn" v-bind:href="mailToUrl" @click="stopClosingModal">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 17.3 12.5" fill="#000">
            <g transform="translate(-232.85 -381.85)">
              <path
                d="M234.4,394.4c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.5-0.6-0.5-1v-7.1c0.3,0.3,0.6,0.6,1,0.8c2.3,1.5,3.9,2.5,4.8,3.1c0.4,0.2,0.7,0.4,0.9,0.6c0.3,0.2,0.6,0.3,0.9,0.4c0.3,0.1,0.7,0.2,1.1,0.2h0c0.4,0,0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.3,0.9-0.4c0.2-0.1,0.5-0.3,0.9-0.6c1.1-0.7,2.7-1.8,4.8-3.1c0.4-0.2,0.7-0.5,1-0.8v7.1c0,0.4-0.2,0.8-0.5,1c-0.3,0.3-0.7,0.4-1.1,0.4H234.4z M241.5,389.8L241.5,389.8c-0.2,0-0.3,0-0.5-0.1c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.3-0.2-0.5-0.3l-0.4-0.3c-0.6-0.4-1.4-0.9-2.5-1.6c-1.1-0.7-1.8-1.1-2-1.3c-0.4-0.3-0.8-0.6-1.1-1c-0.3-0.3-0.5-0.8-0.5-1.2c0-0.4,0.1-0.8,0.4-1.2c0.3-0.3,0.7-0.5,1.1-0.5h14.2c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.5,0.6,0.5,1c0,0.5-0.2,1-0.5,1.3c-0.3,0.4-0.7,0.8-1.2,1.1c-2.4,1.6-3.9,2.5-4.5,2.9c-0.1,0-0.2,0.1-0.4,0.3c-0.2,0.1-0.4,0.3-0.5,0.3c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.2-0.6,0.2C241.8,389.8,241.7,389.8,241.5,389.8L241.5,389.8z"
              />
            </g>
          </svg>
        </a>
      </div>

      <textarea class="b-worksShareModal__copyLinkDummyArea" v-model="thisWorksUrl" readonly></textarea>
      <button
        class="b-worksShareModal__copyLinkBtn"
        type="button"
        v-clipboard:copy="thisWorksUrl"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        リンクをコピーする
      </button>
    </div>
  </modal>
</template>

<script>
import ToggleHeaderWorks from './toggle_header_works';

export default {
  props: {
    work: {
      type: Object,
      required: true,
    },
  },
  computed: {
    facebookShareUrl() {
      return 'http://www.facebook.com/share.php?u=' + this.thisWorksUrl;
    },
    twitterShareUrl() {
      return (
        'https://twitter.com/share?url=' +
        this.thisWorksUrl +
        '&text=' +
        this.work.artist_name +
        '/' +
        this.work.title +
        '/B-OWND 工芸×テクノロジーのオンラインマーケット'
      );
    },
    mailToUrl() {
      return (
        'mailto:?body=' +
        this.work.artist_name +
        '/' +
        this.work.title +
        '/B-OWND 工芸×テクノロジーのオンラインマーケット' +
        this.thisWorksUrl
      );
    },
    thisWorksUrl() {
      return `${location.origin}/works/${this.work.id}`;
    },
  },
  methods: {
    closeWorksShareModal() {
      this.$modal.hide('works-share-modal');
    },
    stopClosingModal(e) {
      e.stopPropagation();
    },
    onCopy: function(e) {
      alert('リンクがコピーされました。' + e.text);
    },
    onError: function(e) {
      alert('リンクのコピーに失敗しました。');
    },
    beforeOpen() {
      this.disableHeaderWorks();
      // ボタンの色を変える
      document
        .querySelector('.b-workSinglePage__footerBtn--share')
        .classList.add('b-workSinglePage__footerBtn--active');
    },
    closed() {
      this.enableHeaderWorks();
      // ボタンの色を戻す
      document
        .querySelector('.b-workSinglePage__footerBtn--share')
        .classList.remove('b-workSinglePage__footerBtn--active');
    },
  },
  mixins: [ToggleHeaderWorks],
};
</script>
