/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
document.addEventListener('turbolinks:load', () => {
  $('#invitation_invitee_type').change(function() {
    const comment = $('#invitation_comment');
    if (!comment.data('changed')) {
      return comment.val(
        I18n.t(
          `invitations.messages.${$(this)
            .val()
            .toLowerCase()}`
        )
      );
    }
  });

  return $('#invitation_comment').change(function() {
    return $(this).data('changed', true);
  });
});
