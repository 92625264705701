import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = ['unread'];
  initialize() {
    if (this.hasUnreadTarget) {
      axios({
        url: '/notifications/mark_as_read',
        method: 'post',
      }).then(response => {});
    }
  }
}
