import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  destroy(event) {
    axios.patch(event.target.dataset.url).then(res => {
      this.element.closest('li').classList.add('p-myPageNotification__closed');
    });
  }
}
