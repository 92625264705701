// import { ValidationController } from 'stimulus-validation';
import { ValidationController } from './form_validator/validation_controller.js';
import Rails from 'rails-ujs';

export default class extends ValidationController {
  static targets = ['form'];
  connect() {
    this.constructor.rules = this.collectValidatorFromAttributes();
    super.connect();
  }

  afterValidateAll(event) {
    if (this.errors.dataMap.size > 0) {
      Rails.enableElement(this.formTarget);
    } else {
      this.formTarget.dispatchEvent(new CustomEvent('validate-all'));
    }
  }

  afterValidate({ el, attr }) {
    const error = this.errorMessage(attr);
    if (error) {
      el.classList.add('b-fvGroup__input--error');
    } else {
      el.classList.remove('b-fvGroup__input--error');
    }
    this.errorMessageEl(el).textContent = error;
  }

  errorMessageEl(el) {
    return el.closest('.b-fvGroup').querySelector('.b-fvGroup__error');
  }

  errorMessage(attr) {
    return this.errors.has(attr) ? this.errors.get(attr)[0] : '';
  }

  collectValidatorFromAttributes() {
    var rules = {};
    this.element.querySelectorAll('[data-fv-rule]').forEach(el => {
      const rule = JSON.parse(el.getAttribute('data-fv-rule'));
      rules = { ...rules, ...rule };
    });
    return rules;
  }
}
