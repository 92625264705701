import { Controller } from 'stimulus';
import Glide from '@glidejs/glide';
import isMobile from '../../helpers/is_mobile';

export default class extends Controller {
  static targets = ['slide'];

  mountSlide() {
    if (!isMobile()) {
      this.slide = this._mountSlide();
    }
  }

  _mountSlide() {
    return new Glide(this.slideTarget, {
      type: 'carousel',
      startAt: 0,
      gap: 0,
      perView: 4.5,
      dragThreshold: false,
    })
      .on('build.after', () => {
        // カルーセルのためにcloneされて生まれたサムネイルをクリックすると作品モーダルが開かない
        // clickイベントがコピーされていないためと思われるので、こっちで登録する
        this.slideTarget.querySelectorAll('.glide__slide--clone').forEach(clone => {
          clone.addEventListener('click', event => {
            Vue.prototype.$modal.hide('works');
            Vue.prototype.$modal.show('works', {
              workId: clone.dataset.workId,
              works: JSON.parse(clone.dataset.works),
            });
          });
        });
      })
      .mount();
  }

  resized() {
    if (this.slide && isMobile()) {
      this.slide.destroy();
      delete this.slide;
    } else if (!this.slide && !isMobile()) {
      this.slide = this._mountSlide();
    }
  }
}
