/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
document.addEventListener('turbolinks:load', () =>
  $('#work_filter').on('change', function() {
    return Turbolinks.visit(`${window.location.href.split('?')[0]}?category=${$(this).val()}`);
  })
);
