// Create the apollo client
//

import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

const cache = new InMemoryCache();

export default function() {
  return new ApolloClient({
    link: new HttpLink({
      uri: '/graphql',
      headers: {
        credentials: 'same-origin',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
        },
      },
    }),
    cache,
  });
}
