import { Controller } from 'stimulus';

/**
 * イベントの発生を親要素にバブリングしたくない要素にセットする
 */
export default class extends Controller {
  stopPropagation(event) {
    event.stopPropagation();
  }
}
