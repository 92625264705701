import { Controller } from 'stimulus';
import Glide from '@glidejs/glide';
import isMobile from '../../helpers/is_mobile';

export default class extends Controller {
  static targets = ['slide'];

  mountSlide() {
    if (!isMobile()) {
      this.slide = this._mountSlide();
    }
  }

  _mountSlide() {
    return new Glide(this.slideTarget, {
      perView: 1.8947,
      gap: 0,
      focusAt: 'center',
      dragThreshold: false,
    }).mount();
  }

  resized() {
    if (this.slide && isMobile()) {
      this.slide.destroy();
      delete this.slide;
    } else if (!this.slide && !isMobile()) {
      this.slide = this._mountSlide();
    }
  }
}
