/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.checkCookies = function() {
  document.cookie = '_verify=1';
  const supportCookies = document.cookie.length >= 1 && document.cookie.indexOf('_verify=1') !== -1;
  const thePast = new Date(1976, 8, 16);
  document.cookie = `_verify=1;expires=${thePast.toUTCString()}`;
  return supportCookies;
};

document.addEventListener('turbolinks:load', () => {
  if ($('body').hasClass('signin')) {
    if (!checkCookies()) {
      return $('.flash-message').html(
        `${"<a class='close' data-dismiss='alert' href='#'>&times;</a>" + "<div class='alert alert-danger'>"}${I18n.t(
          'errors.messages.enable_cookie_html'
        )}</div>`
      );
    }
  }
});
