<template>
  <modal name="work-modal" :adaptive="true" width="100%" height="100%" @before-open="beforeOpen" @closed="closed">
    <works-single-page
      :is-user="isUser"
      :current-user-name="currentUserName"
      :work="work"
      :is-for-sale="isForSale"
      :is-artist="isArtist"
      :is-buyer="isBuyer"
      :is-buyable="isBuyable"
      :markable-id="markableId"
      :is-marking="isMarking"
      :transaction-link="transactionLink"
      :from="from"
      :modal="modal"
      :campaign-id="campaignId"
    />
  </modal>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      isUser: false,
      currentUserName: '',
      work: { id: 0, secret: '', pictures: [] },
      isForSale: false,
      isArtist: false,
      isBuyer: false,
      isBuyable: false,
      markableId: '',
      isMarking: false,
      transactionLink: '',
      from: null,
      modal: true,
      workId: 0,
      beforePath: '',
      campaignId: '',
    };
  },
  mounted() {
    document.querySelectorAll('header').forEach(header => {
      header.style.zIndex = 1000;
    });
  },
  methods: {
    beforeOpen(event) {
      this.workId = event.params.id;
      this.secret = event.params.secret;
      this.from = event.params.from;
      this.reload(this.workId, this.secret, this.from);
      document.body.style.overflowY = 'hidden';

      if (!location.pathname.match(/^\/works\/\d+$/)) {
        this.beforePath = `${location.pathname}${location.search}${location.hash}`;
      }
      if (this.secret == null) {
        history.replaceState('', '', `/works/${this.workId}`);
      }
    },
    closed() {
      this.workId = 0;
      this.work = { id: 0, pictures: [] };
      this.isMarking = false;
      document.body.style.overflowY = '';
      history.replaceState('', '', this.beforePath);
    },
    reload(workId, secret, from) {
      const url = this.secret ? `/works/secret/${this.secret}/for_modal.json` : `/works/${workId}/for_modal.json`;
      axios.get(url).then(response => {
        this.isUser = response.data.is_user;
        this.currentUserName = response.data.current_user_name;
        this.work = response.data.work;
        this.isForSale = response.data.is_for_sale;
        this.isArtist = response.data.is_artist;
        this.isBuyer = response.data.is_buyer;
        this.isBuyable = response.data.is_buyable;
        this.markableId = response.data.markable_id;
        this.isMarking = response.data.is_marking;
        this.transactionLink = response.data.transaction_link;
        this.from = from;
        this.campaignId = response.data.campaign_id;
      });
    },
  },
};
</script>
