// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['messageList', 'messageInput'];

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.messageListTarget.innerHTML += xhr.response;
    this.messageInputTarget.value = '';
  }

  onPostError(event) {
    let [data, status, xhr] = event.detail;
    Vue.prototype.$notify({
      group: 'error_notification',
      type: 'error',
      duration: 5000,
      position: 'bottom right',
      text: xhr.response,
    });
  }
}
