import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['hidden', 'display', 'unitPrice'];

  displayAmount(event) {
    const amount = parseInt(event.currentTarget.value);
    const unitPrice = parseInt(this.unitPriceTarget.value);
    this.hiddenTarget.value = amount;
    this.displayTarget.textContent = `¥ ${(amount * unitPrice).toLocaleString()}-`;
  }
}

// item_amount_controller
