import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const app = document.getElementById('app');
    app.style.backgroundColor = '#EBEBEB';
    app.style.marginBottom = '60px';
    if (location.hash.match(/^#purchase:(\d+)/)) {
      document.querySelectorAll('[name="item_purchase[amount]"]').forEach(input => {
        input.value = location.hash.replace(/^#purchase:/, '');
      });
      this.submit();
    }
  }

  submit() {
    document.getElementById('item_purchase').submit();
  }

  scrollLeft() {
    const scrollSize = window.innerWidth * 0.8893;
    document.querySelector('.p-ticketsNew__eventImages').scrollBy({ left: -scrollSize, behavior: 'smooth' });
  }

  scrollRight() {
    const scrollSize = window.innerWidth * 0.8893;
    document.querySelector('.p-ticketsNew__eventImages').scrollBy({ left: scrollSize, behavior: 'smooth' });
  }
}
