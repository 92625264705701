<template>
  <a @click="showLoginModal"><slot>ログイン</slot></a>
</template>

<script>
export default {
  props: {
    purchaseWorkId: {
      type: Number,
      required: false,
    },
    reservationWorkId: {
      type: Number,
      required: false,
    },
    itemId: {
      type: Number,
      required: false,
    },
  },
  methods: {
    showLoginModal() {
      this.$modal.show('login-modal', {
        purchaseWorkId: this.purchaseWorkId,
        reservationWorkId: this.reservationWorkId,
        itemId: this.itemId,
      });

      // 他のモーダルを閉じる
      this.$modal.hide('works-detail-modal');
      this.$modal.hide('works-share-modal');
      this.$modal.hide('works-purchase-modal');

      document.querySelectorAll('.b-headerMobile__toggleBtn').length &&
        document.querySelectorAll('.b-headerMobile__toggleBtn')[0].classList.remove('is-open');
    },
  },
};
</script>
