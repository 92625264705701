import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['recipientName'];

  connect() {
    this.recipientNameTarget.addEventListener('invalid', event => {
      if (event.target.validity.valueMissing) {
        event.target.setCustomValidity('宛名を入力してください');
      }
    });
  }
}
