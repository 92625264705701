import { Controller } from 'stimulus';

/**
 * Railsの <%= link_to ... remote: true %> で発生するリクエストのエラーハンドラ
 * vue-notification を使ったメッセージを表示する
 *
 * 使用例：
 * <%= link_to '更新する', update_xxx_path, remote: true, data: { controller: 'payment', action='ajax:complete->payment#handleError' } %>
 * ...
 * <notifications group="error" position="bottom right" />
 */
export default class extends Controller {
  handleError(event) {
    const xhr = event.detail[0];

    // 正常終了していたときは何もしない
    if (xhr.status >= 200 && xhr.status < 300) {
      return;
    }

    // エラーのステータスコードを返したらコンソールに出力してポップアップを表示する
    console.error(event.detail);
    Vue.prototype.$notify({
      group: 'error',
      type: 'error',
      duration: 5000,
      title: 'エラーが発生しました',
      text: xhr.responseText,
    });
  }
}
