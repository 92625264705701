I18n.translations || (I18n.translations = {});
I18n.translations['ja'] = I18n.extend(I18n.translations['ja'] || {}, {
  other: {
    processing: '送信中...',
    validations: {
      price_range: '%{min}円以上%{max}円以下の価格にして下さい。',
      time_max_html: '<br/>%{max}以前の時間を入力して下さい。<br/>',
      time_min_html: '<br/>%{min}以降の時間を入力して下さい。<br/>',
    },
  },
  errors: {
    messages: {
      enable_cookie_html: 'Cookieを有効にしてください\n',
      required: 'こちらの項目が必須です!',
      too_short: 'は%{count}文字以上で入力してください。',
      less_than: 'は%{count}より小さい値にしてください。',
      invalid_email: '正しいメールアドレスを入力してください',
      text_too_short: '{1}文字以上入力してください!',
      number_too_small: '{0}以上の数値を入力してください!',
    },
  },
  flash: {
    danger: {
      upload_avatar: 'プロフィール画像のアップデートが失敗しました。',
    },
    success: {
      upload_avatar: 'プロフィール画像をアップデートしました。',
    },
  },
  invitations: {
    messages: {
      artist:
        '- ビーオウンドは、持続可能なオンライン美術市場を創ることを目的としたサービスです。\n- ユーザーは、作品・レビュー・イベント・スレッドの投稿、投稿物へのコメント、販売機能を通じた売買などが行えます。\n- 特に、2種類の販売システムと還元システムがサービスの大きな特徴です。\n',
    },
  },
  shared: {
    follow: 'フォロー',
    unfollow: 'フォロー中',
  },
  user: {
    registration: {
      artist: 'アーティストはオリジナル作品を登録、管理、販売できるアカウントです。',
      collector: 'コレクターは作品を購入、管理、売却できるアカウントです。',
      reviewer: 'レビュワーは作品に対するレビューを登録、管理できるアカウントです。',
    },
  },
  helpers: {
    submit: {
      create: '投稿する',
      pay: 'お支払いをする',
    },
  },
  activerecord: {
    models: {
      work: '作品',
      artist: 'アーティスト',
      general: '一般ユーザー',
    },
  },
});
I18n.translations['en'] = I18n.extend(I18n.translations['en'] || {}, {
  other: {
    processing: 'Processing...',
    validations: {
      price_range: 'The amount must be between %{min}JPY and %{max}JPY.',
      time_max_html: '<br/>You must input the time before %{max}.<br/>',
      time_min_html: '<br/>You must input the time after %{min}.<br/>',
    },
  },
  errors: {
    messages: {
      enable_cookie_html: 'Please enable your browser cookie\n',
      required: 'This field is required',
      too_short: {
        one: 'is too short (minimum is 1 character)',
        other: 'is too short (minimum is %{count} characters)',
      },
      less_than: 'must be less than %{count}',
      invalid_email: 'Invalid email',
      text_too_short: 'The length of text is too small! Current: {0}, Minimum: {1}',
      number_too_small: 'The number is too small! Minimum: {0}',
    },
  },
  flash: {
    danger: {
      upload_avatar: 'Error occured when upload the avatar picture',
    },
    success: {
      upload_avatar: 'Your profile avatar has been updated successfully',
    },
  },
  invitations: {
    messages: {
      artist:
        '- This is a web service optimized for sustainable online art market which includes both social and online function.\n- All users can; post artworks, reviews, events and threads; leave comments on posted contents; sell and purchase artworks at market.\n- Particularly, two kinds of sales and original rewarding system are distinguishing features.\n',
    },
  },
  shared: {
    follow: 'Follow',
    unfollow: 'Unfollow',
  },
  user: {
    registration: {
      artist: 'Artist is the account to exhibit your artworks.',
      collector: 'Collector is the account to purchase sold artworks.',
      reviewer: 'Reviewer is the account to review artworks.',
    },
  },
  helpers: {
    submit: {
      create: 'Post',
      pay: 'Pay',
    },
  },
  activerecord: {
    models: {
      work: 'Work',
    },
  },
});
