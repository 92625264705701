import { Controller } from 'stimulus';

const staticPaths = ['privacy', 'terms_of_use', 'commercial_law', 'contact_us', 'faq'].map(
  pathname => new RegExp(`^/${pathname}([\/#].*)?$`)
);

const toBoolean = str => str.toLowerCase() === 'true';

export default class extends Controller {
  static targets = ['trigger', 'subcontent', 'arrow'];

  connect() {
    this.data.set('mobile-nav-is-open', false);
    this.data.set('subnav-is-open', false);
    staticPaths.forEach(regex => {
      if (regex.test(window.location.pathname)) this.data.set('subnav-is-open', true);
    });
  }

  toggleMobileDropdown(e) {
    e.preventDefault();
    this.data.set('mobile-nav-is-open', !toBoolean(this.data.get('mobile-nav-is-open')));
  }

  toggleSubDropdown(e) {
    e.preventDefault();
    this.data.set('subnav-is-open', !toBoolean(this.data.get('subnav-is-open')));
  }
}
