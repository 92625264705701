<template>
  <div class="b-searchSuggest" :class="isMobile && 'b-searchSuggestMobile'">
    <input type="hidden" />
    <div v-if="!searchOpened" class="b-searchSuggest__triggerButton"><button @click="openSearch" /></div>
    <div v-if="searchOpened" class="b-searchSuggest__searchBox" :class="isMobile && 'b-searchSuggestMobile__searchBox'">
      <form action="/searches" accept-charset="utf-8">
        <vue-simple-suggest
          v-model="chosen"
          :debounce="200"
          :list="getList"
          value-attribute="id"
          :controls="controls"
          :prevent-submit="false"
          @select="onSuggestSelect"
          name="query"
        >
          <!-- Filter by input text to only show the matching results -->
          <template slot="misc-item-above" slot-scope="{ suggestions, query }">
            <div class="misc-item" @click="submit">
              <span>Search for "{{ query }}"</span>
            </div>
          </template>
          <div slot="suggestion-item" slot-scope="{ suggestion, query }">
            <div class="suggest-item__name">
              <span>{{ suggestion.name }}</span>
            </div>
            <div class="suggest-item__type">
              {{ $t.t(`activerecord.models.${suggestion.__typename.toLowerCase()}`) }}
            </div>
          </div>
        </vue-simple-suggest>

        <button
          type="submit"
          class="b-searchSuggest__submitButton"
          :class="isMobile && 'b-searchSuggestMobile__submitButton'"
          @click="submit"
        >
          <img src="../images/common/icon-search-black.svg" />
        </button>
      </form>
    </div>
    <div v-if="isMobile && searchOpened">
      <button @click="closeSearch" class="b-searchSuggestMobile__cancelButton">キャンセル</button>
    </div>
  </div>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest';
import SearchAllQueries from '../graphql/queries/search_all.js';

export default {
  name: 'SearchSuggest',
  components: {
    VueSimpleSuggest,
  },
  data() {
    return {
      searchOpened: false,
      isMobile: false,
      query: '',
      chosen: '',
      controls: {
        selectionUp: [38],
        selectionDown: [40],
        select: [],
        hideList: [27],
        autocomplete: [32, 13],
      },
      items: [],
    };
  },
  mounted: function() {
    const updateMobileOrNot = () => (this.isMobile = window.innerWidth < 875);

    window.addEventListener('resize', updateMobileOrNot);
    updateMobileOrNot();
  },
  methods: {
    openSearch() {
      this.searchOpened = true;
      if (!this.isMobile) {
        document.querySelector('.b-header__searchSuggestWrapper').classList.add('b-header__searchSuggestWrapperOpened');
      }
    },
    closeSearch() {
      this.searchOpened = false;
      if (!this.isMobile) {
        document
          .querySelector('.b-header__searchSuggestWrapper')
          .classList.remove('b-header__searchSuggestWrapperOpened');
      }
    },
    submit(e) {
      if (this.query === '') {
        e.preventDefault();
        this.closeSearch();
      } else {
        Turbolinks.visit(`/searches?query=${this.query}`);
      }
    },
    getList(query) {
      this.query = query;
      return new Promise((resolve, reject) => {
        this.$apollo
          .query({
            query: SearchAllQueries,
            variables: {
              query,
            },
          })
          .then(data => {
            const artists = data.data.searchArtists.edges.map(edge => edge.node);
            const users = data.data.searchGenerals.edges.map(edge => edge.node);
            const works = data.data.searchWorks.edges.map(edge => edge.node);
            const candidates = artists.concat(users).concat(works);
            resolve(candidates);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    onSuggestSelect(suggest) {
      Turbolinks.visit(suggest.url);
    },
  },
};
</script>
