import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['popup', 'certNameButton', 'textField'];

  annoymouse = true;

  toggleAnonymouse() {
    this.annoymouse = !this.annoymouse;
    this.certNameButtonTarget.textContent = this.annoymouse ? '証明書に名前を記入する' : '匿名に戻す';
    this.textFieldTarget.disabled = this.annoymouse;
    this.textFieldTarget.value = this.annoymouse ? 'annonymous' : '';
    if (!this.annoymouse) {
      this.textFieldTarget.focus();
    } else {
      this.textFieldTarget.value = 'annonymous';
    }
  }

  togglePopup() {
    this.popupTarget.classList.toggle('display-none');
  }
}
