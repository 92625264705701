import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form'];

  toggle(e) {
    const currentValue = e.target.checked;
    if (currentValue) {
      const elements = this.formTarget.elements;
      for (var i = 0; i < elements.length; i++) {
        const el = elements[i];
        if (el.type !== 'hidden') {
          elements[i].value = '';
        }
      }
    } else {
      this.formTarget.reset();
    }
  }
}
