<template>
  <modal
    name="login-modal"
    :adaptive="true"
    width="100%"
    height="100%"
    @before-open="beforeOpen"
    @closed="enableHeaderWorks"
  >
    <div class="b-loginModal">
      <div class="b-loginModal__wrapper">
        <h3 class="b-loginModal__logo"><img src="../images/modal/logo--fullBlack.png" alt="B-OWND" /></h3>

        <div v-show="signupSuccess" class="b-loginModal__successSignupMessage">
          {{ signupEmail }} 宛にメールが送信されました。
        </div>

        <div v-show="!signupSuccess">
          <div v-show="!resetPassword">
            <div class="b-loginModal__linkNav">
              <ul>
                <li><a :class="{ isCurrent: login }" @click="login = true">ログイン</a></li>
                <li><div class="b-loginModal__linkNavHr" /></li>
                <li><a :class="{ isCurrent: !login }" @click="login = false">初めての方へ</a></li>
              </ul>
            </div>
          </div>

          <div v-show="resetPassword">
            <div class="b-loginModal__linkNav">
              <ul>
                <li><a class="isCurrent">パスワードの再設定</a></li>
              </ul>
            </div>
          </div>

          <div>
            <div v-show="!resetPassword">
              <div v-show="login" class="b-loginModal__forms">
                <signin-form
                  ref="signinForm"
                  :purchaseWorkId="purchaseWorkId"
                  :reservationWorkId="reservationWorkId"
                  :itemId="itemId"
                />
              </div>

              <div v-show="!login" class="b-loginModal__forms">
                <signup-form
                  ref="signupForm"
                  @success="signupSuccessCallback"
                  :purchaseWorkId="purchaseWorkId"
                  :afterAction="afterAction"
                />
              </div>
            </div>

            <div v-show="resetPassword" class="b-loginModal__forms">
              <reset-password-form ref="resetPasswordForm" @success="signupSuccessCallback" />
            </div>

            <div class="b-loginModal__resetPasswordLink">
              <a v-show="login && !resetPassword" @click.prevent="resetPassword = true"
                >パスワードを忘れた場合はこちら</a
              >
              <a v-show="resetPassword" @click.prevent="resetPassword = false">← ログイン画面に戻る</a>
            </div>
          </div>
        </div>
        <!-- end v-show="!signupSuccess" -->
        <a @click="closeLoginModal" class="b-loginModal__closeBtn"></a>
      </div>
    </div>
  </modal>
</template>

<script>
import SigninForm from '../forms/signin.vue';
import SignupForm from '../forms/signup.vue';
import ResetPasswordForm from '../forms/reset_password.vue';
import ToggleHeaderWorks from './toggle_header_works';

export default {
  components: {
    SigninForm,
    SignupForm,
    ResetPasswordForm,
  },
  data() {
    return {
      login: true,
      resetPassword: false,
      signupSuccess: false,
      signupEmail: '',
      purchaseWorkId: '',
      itemId: '',
      reservationWorkId: '',
    };
  },
  computed: {
    afterAction() {
      if (this.purchaseWorkId) {
        return 'purchase';
      }

      if (this.reservationWorkId) {
        return 'reserve';
      }

      if (this.itemId) {
        const amount = document.querySelector('[name="item_purchase[amount]"]')?.value || 1;
        return `purchase:${amount}`;
      }

      return null;
    },
  },
  methods: {
    // handle callback from signup child component
    signupSuccessCallback(val) {
      this.signupEmail = val.email;
      this.signupSuccess = true;
    },
    signinSuccessCallback() {
      this.$modal.show('works-purchase-modal');
    },
    closeLoginModal() {
      this.$modal.hide('login-modal');
    },
    beforeOpen(event) {
      this.purchaseWorkId = event.params && event.params.purchaseWorkId;
      this.reservationWorkId = event.params && event.params.reservationWorkId;
      this.itemId = event.params && event.params.itemId;
      this.initModal();
      this.disableHeaderWorks();
      if (event.params && event.params.initialForm === 'signup') {
        this.login = false;
      }
    },
    initModal() {
      // For open modal after sign up or reset password.
      this.login = true;
      if (location.pathname === '/sign_up') {
        this.login = false;
      }
      this.resetPassword = false;
      this.signupSuccess = false;
    },
  },
  mixins: [ToggleHeaderWorks],
};
</script>
