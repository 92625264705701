<template>
  <div>
    <form ref="form" class="b-signupForm">
      <input name="utf8" type="hidden" value="✓" />
      <div class="input-body">
        <vue-form-generator ref="formGenerator" :schema="schema" :model="model" />
        <p class="b-loginModal__formsErrorTxt" v-html="errorHtml" v-show="hasError"></p>

        <p class="b-signupForm__recommendMailProvider">
          Webブラウザで送受信可能なWebメール<br />(例: ●●@gmail.com)の利用を推奨しています。
        </p>

        <div class="b-loginModal__formsOauth -signup">
          <p class="b-loginModal__formsSubTxt">or</p>
          <a class="b-loginModal__formsOauthLink" href="/auth/facebook">Facebook</a>
        </div>

        <vue-recaptcha
          ref="invisibleRecaptcha"
          size="invisible"
          :sitekey="recaptchaSiteKey"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
        />

        <div class="b-signupForm__agreement">
          <input ref="agreementCheckbox" type="checkbox" class="b-signupForm__agreementCheckbox" />
          <a href="/terms_of_use" target="_blank">利用規約</a>・<a href="/privacy" target="_blank"
            >プライバシポリシー</a
          >
          に同意しました
        </div>

        <input
          ref="submitButton"
          class="b-loginModal__formsLoginBtn"
          type="submit"
          :value="submitButtonTitle"
          @click.prevent="submit"
        />

        <p class="b-signupForm__recaptchaStatement">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import initApolloClient from '../apollo/client.js';
import SignupMutation from '../graphql/mutations/signup.js';

export default {
  props: {
    afterAction: {
      type: String,
      required: false,
    },
  },
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      captchaVerified: false,
      recaptchaSiteKey: process.env.RECAPTCHA_SITE_KEY,
      submitButtonTitle: '登録',
      model: {
        email: '',
        name: '',
        password: '',
      },
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            required: true,
            model: 'email',
            inputName: 'user[email]',
            placeholder: 'メールアドレス',
            validator: [VueFormGenerator.validators.email],
          },
          {
            type: 'input',
            inputType: 'password',
            min: 6,
            model: 'password',
            inputName: 'user[password]',
            placeholder: 'パスワード',
            required: true,
            validator: [VueFormGenerator.validators.string],
          },
        ],
      },
      errorHtml: '',
    };
  },
  computed: {
    hasError: function() {
      return this.errorHtml !== '';
    },
  },
  mounted() {
    VueFormGenerator.validators.resources.fieldIsRequired = Vue.prototype.$t.t('errors.messages.required');
    VueFormGenerator.validators.resources.invalidEmail = Vue.prototype.$t.t('errors.messages.invalid_email');
    VueFormGenerator.validators.resources.textTooSmall = Vue.prototype.$t.t('errors.messages.text_too_short');
    VueFormGenerator.validators.resources.numberTooSmall = Vue.prototype.$t.t('errors.messages.number_too_small');
  },
  methods: {
    resetCaptcha() {
      this.$refs.invisibleRecaptcha.reset();
    },
    onCaptchaExpired() {
      this.captchaVerified = false;
      this.resetCaptcha();
    },
    onCaptchaVerified() {
      this.captchaVerified = true;
      this.postSignup();
    },
    validateForm() {
      this.errorHtml = '';
      if (!this.$refs.formGenerator.validate()) {
        return false;
      }
      if (!this.captchaVerified) {
        this.$refs.invisibleRecaptcha.execute();
        return false;
      }
      return true;
    },
    matchPassword() {
      if (this.model.password !== this.model.password_confirmation) {
        return ['上記の欄に入力されたパスワードと一致していません'];
      }
      return [];
    },
    postSignup() {
      this.changeSubmitButtonTitleToSubmitting();
      const client = initApolloClient();
      const redirect_to = location.pathname.match(/^\/(works|items)\/\d+$/) ? location.pathname : '/';
      const afterAction = this.afterAction;

      client
        .mutate({
          mutation: SignupMutation,
          variables: {
            email: this.model.email,
            password: this.model.password,
            redirect_to: this.afterAction ? `${redirect_to}#${this.afterAction}` : redirect_to,
          },
        })
        .then(data => {
          if (data.data.signup.success) {
            this.$emit('success', {
              success: data.data.signup.success,
              email: this.model.email,
            });
          } else {
            this.errorHtml = data.data.signup.errorMessages.join('<br/>');
          }
          this.returnSubmitButtonTitle();
        })
        .catch(error => {
          Vue.prototype.$notify({
            group: 'error_notification',
            type: 'error',
            duration: 5000,
            text: error.message,
          });
          alert(error.message);
          this.returnSubmitButtonTitle();
        });
    },
    changeSubmitButtonTitleToSubmitting() {
      this.$refs.submitButton.value = I18n.t('other.processing');
    },
    returnSubmitButtonTitle() {
      this.$refs.submitButton.value = this.submitButtonTitle;
    },
    submit() {
      if (!this.$refs.agreementCheckbox.checked) {
        alert('利用規約・プライバシポリシーに同意して下さい');
        return;
      }
      if (this.validateForm()) {
        this.postSignup();
      }
    },
  },
};
</script>
