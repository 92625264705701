import { Controller } from 'stimulus';
import axios from 'axios';
import Cheerio from 'cheerio';

/**
 * B-OWND Magazineの記事ひとつを表示するコントローラー
 */
export default class extends Controller {
  static targets = ['title', 'link', 'image'];

  connect() {
    const item = JSON.parse(this.element.dataset.item);
    this.element.innerHTML = `
      <a href="${item.link}" target="_blank">
        <img data-target="magazine-feed-item.image" class="p-artist__magazine-article-image">
      </a>
      <div data-target="magazine-feed-item.title" class="p-artist__magazine-article-subject">
        <a href="${item.link}" target="_blank">${item.title}</a>
      </div>
      <div data-target="magazine-feed-item.link" class="p-artist__magazine-article-link">
        <a href="${item.link}" target="_blank">記事を読む</a>
      </div>
    `;

    // サムネイル画像が含まれないのでリンク先のmetaタグから取得する
    axios
      .get(`/cors/${item.link.replace(/^https?\:\/\//, '')}`)
      .then(response => {
        const html = Cheerio.load(response.data);
        const image = html('meta[property="og:image"]').attr('content');
        this.imageTarget.src = image;
      })
      .catch(error => {
        console.error(error);
        return;
      });
  }
}
