import isMobile from '../helpers/is_mobile';

export default {
  data() {
    return {
      width: '93.6%',
      height: 'auto',
      scrollable: true,
      withButton: false,
    };
  },
  methods: {
    beforeOpen(event) {
      this.width = isMobile() ? '93.6%' : '800px';
      // this.height = isMobile() ? 'auto' : '765px'
      this.withButton = event.params && event.params.withButton;
    },
    closed(event) {
      this.withButton = false;
    },
    submitReservation() {
      this.$eventHub.$emit('submit-reservation-form');
    },
  },
};
