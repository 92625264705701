/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
document.addEventListener('turbolinks:load', () =>
  $(document).on('change', '#auction_type_cd', function(ev) {
    const form = $(this).parents('form');
    const calendar = $('.auction-calendar-field');
    const input = calendar.find('input[type=text]');
    const fv = form.data('formValidation');
    if ($(this).val() === '0') {
      calendar.hide();
      fv.enableFieldValidators('auction[time_limit]', false);
      return input.val('');
    }
    calendar.show();
    return fv.enableFieldValidators('auction[time_limit]', true);
  })
);
