import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = ['icon'];

  connect() {
    this.toggleClass(this.isVisible);
  }

  toggleClass(val) {
    if (val) {
      this.iconTarget.classList.remove('invisible');
    } else {
      this.iconTarget.classList.add('invisible');
    }
  }

  toggle() {
    if (this.isVisible) {
      axios
        .post(`/marks/${this.markId}/invisibilities`)
        .then(() => {
          this.isVisible = false;
        })
        .catch(error => {
          alert(error);
        });
    } else {
      axios
        .post(`/marks/${this.markId}/visibilities`)
        .then(response => {
          this.isVisible = true;
        })
        .catch(error => {
          alert(error);
        });
    }
  }

  get markId() {
    return this.data.get('markId');
  }

  get isVisible() {
    return JSON.parse(this.data.get('markVisible'));
  }

  set isVisible(val) {
    this.data.set('markVisible', val);
    this.toggleClass(val);
  }
}
