import gql from 'graphql-tag';

const UnfollowMutation = gql`
  mutation unfollowMutation($followableId: ID!) {
    unfollow(followableId: $followableId) {
      success
      errorMessages
    }
  }
`;

export default UnfollowMutation;
