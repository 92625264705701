<template>
  <div class="b-imageCarousel">
    <carousel :per-page="1" :navigation-enabled="true" :mouse-drag="false" :loop="true" :pagination-enabled="false">
      <slide v-for="picture in pictures" :key="picture.id">
        <div class="b-imageCarousel__workImageWrapper">
          <img class="b-imageCarousel__workImage" :src="picture.image.url" :style="`height: ${imageHeight}`" />
        </div>
      </slide>
    </carousel>
    <div class="b-imageCarousel__splash"><div class="loader">Loading...</div></div>
  </div>
</template>

<script>
export default {
  props: {
    pictures: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    const resizeImageHeight = () =>
      document.querySelectorAll('.b-imageCarousel__workImage').forEach(img => {
        img.style.height = `${window.innerHeight}px`;
      });

    window.addEventListener('resize', resizeImageHeight);
    resizeImageHeight();
  },
  computed: {
    imageHeight() {
      return `${window.innerHeight}px`;
    },
  },
};
</script>
