/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
document.addEventListener('turbolinks:load', () => {
  $(document).on('click', '.action-btn', function(ev) {
    ev.preventDefault();
    $('.action-btn').removeClass('active');
    $(this).addClass('active');
    const form = $(this)
      .find('a')
      .data('url');
    $('.modal-form').hide();
    $(`#${form}`).fadeIn();
  });

  $(document).on('click', '#work_list li', function() {
    $('#work_list li').removeClass('selected');
    $(this).addClass('selected');
  });

  $('.drop-zone')
    .on('dragover', function() {
      $(this).css('z-index', 100);
    })
    .on('drop', function() {
      $(this).css('z-index', 0);
    });
});

window.disabledSize = function(checkbox) {
  const text_field = checkbox.parents('form').find('.sizeBox');
  if (checkbox.is(':checked')) {
    text_field.attr('disabled', 'disabled');
    return text_field.val('');
  }
  return text_field.removeAttr('disabled');
};
