const initialize_negotiate_form = function() {
  $('#new_comment').attr('action', $('#message-type option:selected').data('url'));
  $('#message-type').change(function() {
    const id = $(this)
      .find('option:selected')
      .data('id');
    const url = $('#message-type option:selected').data('url');
    $('#new_comment').attr('action', url);
  });
};

document.addEventListener('turbolinks:load', () => {
  initialize_negotiate_form();
});
