<template>
  <div>
    <button :class="buttonClass" @click="toggleButton">
      <span v-if="toggled">{{ untoggleLabel }}</span
      ><span v-else>{{ toggleLabel }}</span>
    </button>
  </div>
</template>

<script>
import initApolloClient from '../apollo/client.js';
import followMutation from '../graphql/mutations/follow_mutation.js';
import unfollowMutation from '../graphql/mutations/unfollow_mutation.js';
import markMutation from '../graphql/mutations/mark_mutation.js';
import unmarkMutation from '../graphql/mutations/unmark_mutation.js';

export default {
  name: 'FollowOrMarkButton',
  props: {
    isToggled: {
      type: Boolean,
      default: false,
      required: true,
    },
    targetId: {
      type: String,
      required: true,
    },
    toggleType: {
      type: String,
      default: 'follow',
      required: true,
    },
    toggleLabel: {
      type: String,
      default: 'お気に入り',
      required: false,
    },
    untoggleLabel: {
      type: String,
      default: 'お気に入り済',
      required: false,
    },
  },
  data() {
    return {
      toggled: this.isToggled,
    };
  },
  computed: {
    buttonClass() {
      const toggleClass = this.toggled ? 'b-followOrMarkButton--toggled' : 'b-followOrMarkButton--untoggled';
      return `b-followOrMarkButton ${toggleClass}`;
    },
    mutation() {
      if (this.toggleType === 'follow') {
        return this.toggled ? unfollowMutation : followMutation;
      }
      if (this.toggleType === 'mark') {
        return this.toggled ? unmarkMutation : markMutation;
      }
    },
    mutationType() {
      return this.toggled ? `un${this.toggleType}` : this.toggleType;
    },
  },
  methods: {
    toggleButton() {
      const client = initApolloClient();
      const mutation = this.mutation;

      client
        .mutate({
          mutation,
          variables: {
            [`${this.toggleType}ableId`]: this.targetId,
          },
        })
        .then(data => {
          if (data.data[this.mutationType].success) {
            this.toggled = !this.toggled;
          }
        })
        .catch(error => {
          Vue.prototype.$notify({
            group: 'error_notification',
            type: 'error',
            duration: 5000,
            text: error.message,
          });
        });
    },
  },
};
</script>
