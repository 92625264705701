import { Controller } from 'stimulus';
import Glide from '@glidejs/glide';
import isMobile from '../../helpers/is_mobile';

export default class extends Controller {
  static targets = ['slide'];

  mountSlide() {
    new Glide(this.slideTarget, {
      type: 'carousel',
      startAt: 0,
      focusAt: 'center',
      gap: isMobile() ? 0 : 50,
      perView: isMobile() ? 1 : 1.5684,
      dragThreshold: false,
    })
      .on('build.after', () => {
        // カルーセルのためにcloneされて生まれたサムネイルをクリックすると作品モーダルが開かない
        // clickイベントがコピーされていないためと思われるので、こっちで登録する
        this.slideTarget.querySelectorAll('.glide__slide--clone').forEach(clone => {
          clone.addEventListener('click', event => {
            Vue.prototype.$modal.hide('works');
            Vue.prototype.$modal.show('works', {
              workId: clone.dataset.workId,
              works: JSON.parse(clone.dataset.works),
            });
          });
        });
      })
      .mount();
  }
}
