import gql from 'graphql-tag';

const signupMutation = gql`
  mutation signupMutation($email: String!, $password: String!, $redirect_to: String) {
    signup(authProvider: { email: $email, password: $password, redirect_to: $redirect_to }) {
      success
      errorMessages
    }
  }
`;

export default signupMutation;
