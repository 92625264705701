<template>
  <div>
    <form ref="form" class="b-signupForm">
      <input name="utf8" type="hidden" value="✓" />
      <div class="input-body">
        <vue-form-generator ref="formGenerator" :schema="schema" :model="model" />
        <p class="b-loginModal__resetFormSubTxt">
          ご登録されているメールアドレスをご入力ください。<br />
          パスワードの再設定を行うためのメールをお送りいたします。
        </p>
        <input
          ref="submitButton"
          class="b-loginModal__formsLoginBtn"
          type="submit"
          :value="submitButtonTitle"
          @click.prevent="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import PostWithNotification from '../services/axios_post_with_notification.js';

export default {
  data() {
    return {
      submitButtonTitle: '送信',
      model: {
        email: '',
      },
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            required: true,
            model: 'email',
            inputName: 'email',
            placeholder: 'メールアドレス',
            validator: [VueFormGenerator.validators.email],
          },
        ],
      },
    };
  },
  methods: {
    validateForm() {
      if (!this.$refs.formGenerator.validate()) {
        return false;
      }
      return true;
    },
    postReset() {
      this.changeSubmitButtonTitleToSubmitting();
      PostWithNotification.submit(
        '/password_resets',
        {
          email: this.model.email,
        },
        this.resetSuccess,
        this.resetFailed
      );
    },
    resetFailed() {
      this.returnSubmitButtonTitle();
      alert('正しいメールアドレスを入力して下さい');
    },
    resetSuccess() {
      this.returnSubmitButtonTitle();
      this.$emit('success', {
        email: this.model.email,
      });
    },
    changeSubmitButtonTitleToSubmitting() {
      this.$refs.submitButton.value = I18n.t('other.processing');
    },
    returnSubmitButtonTitle() {
      this.$refs.submitButton.value = this.submitButtonTitle;
    },
    submit() {
      if (this.validateForm()) {
        this.postReset();
      }
    },
  },
};
</script>
