<template>
  <div>
    <form ref="form" class="b-signupForm">
      <input name="utf8" type="hidden" value="✓" />
      <div class="input-body">
        <vue-form-generator ref="formGenerator" :schema="schema" :model="model" />
        <p class="b-loginModal__formsErrorTxt">{{ errorTxt }}</p>
        <div class="b-loginModal__formsOauth">
          <p class="b-loginModal__formsSubTxt">or</p>
          <a class="b-loginModal__formsOauthLink" href="/auth/facebook">Facebook</a>
        </div>
        <input
          ref="submitButton"
          class="b-loginModal__formsLoginBtn"
          type="submit"
          :value="submitButtonTitle"
          @click.prevent="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import PostWithNotification from '../services/axios_post_with_notification.js';

export default {
  props: {
    purchaseWorkId: {
      type: Number,
      require: false,
    },
    reservationWorkId: {
      type: Number,
      require: false,
    },
    itemId: {
      type: Number,
      require: false,
    },
  },
  data() {
    return {
      submitButtonTitle: 'ログイン',
      model: {
        email: '',
        password: '',
      },
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            required: true,
            model: 'email',
            inputName: 'email',
            placeholder: 'メールアドレス',
            validator: [VueFormGenerator.validators.email],
          },
          {
            type: 'input',
            inputType: 'password',
            min: 6,
            model: 'password',
            inputName: 'password',
            placeholder: 'パスワード',
            required: true,
            validator: [VueFormGenerator.validators.string],
          },
        ],
      },
      errorTxt: '',
    };
  },
  methods: {
    validateForm() {
      this.errorTxt = '';
      if (!this.$refs.formGenerator.validate()) {
        return false;
      }
      return true;
    },
    postSignin() {
      this.changeSubmitButtonTitleToSubmitting();
      PostWithNotification.submit(
        '/sessions',
        {
          email: this.model.email,
          password: this.model.password,
        },
        this.signinSuccess,
        this.signinFailed
      );
    },
    signinFailed() {
      this.returnSubmitButtonTitle();
      this.errorTxt = '正しいメールアドレスとパスワードを入力して下さい';
    },
    signinSuccess() {
      this.returnSubmitButtonTitle();
      this.$eventHub.$emit('sign-in');
      this.$modal.hide('login-modal');
      if (this.purchaseWorkId) {
        axios
          .post('/transactions', { transaction: { work_id: this.purchaseWorkId } })
          .then(response => {
            if (response.data.message !== 'success') {
              this.handleError(response);
              return;
            }
            Turbolinks.visit(`/transactions/${response.data.transaction_id}/shipping_address/new`);
          })
          .catch(error_or_response => {
            const response = error_or_response.response || error_or_response;
            console.error(error_or_response);
            this.$notify({
              type: 'error',
              text: response.data.message,
              duration: 5000,
            });
          });
      } else if (this.reservationWorkId) {
        document.querySelector('form[action="/reservations"]').submit();
      } else if (this.itemId) {
        document.querySelector('form#item_purchase').submit();
      } else {
        Turbolinks.visit(window.location.href);
      }
    },
    changeSubmitButtonTitleToSubmitting() {
      this.$refs.submitButton.value = I18n.t('other.processing');
    },
    returnSubmitButtonTitle() {
      this.$refs.submitButton.value = this.submitButtonTitle;
    },
    submit() {
      if (this.validateForm()) {
        this.postSignin();
      }
    },
  },
};
</script>
