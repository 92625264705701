<template>
  <form>
    <div class="p-transactionCreditCard__info">
      <div class="p-transactionCreditCard__infoFlex">
        <h3 class="p-transactionCreditCard__infoTtl">クレジットカード情報</h3>

        <div class="p-transactionCreditCard__infoImg">
          <img src="../../images/creditcard/visa.svg" />
          <img src="../../images/creditcard/master-card.svg" />
          <img src="../../images/creditcard/american_express.svg" />
          <img src="../../images/creditcard/jcb.svg" />
          <img src="../../images/creditcard/discover.svg" />
          <img src="../../images/creditcard/diners.svg" />
        </div>
      </div>

      <p class="p-transactionCreditCard__notice is-red">
        ※クレジットカード情報はセキュリティ上、保存されません。
      </p>
    </div>

    <div :class="{ complete }">
      <div class="form-group">
        <label class="required">カード番号</label>
        <card-number
          ref="cardNumber"
          :stripe="stripePublishableKey"
          :options="options"
          class="stripe-element card-number"
          @change="number = $event.complete"
        />
      </div>

      <div class="form-group">
        <label class="required">有効期限（月/年）</label>
        <card-expiry
          ref="cardExpiry"
          :stripe="stripePublishableKey"
          :options="options"
          class="stripe-element card-expiry"
          @change="expiry = $event.complete"
        />
      </div>

      <div class="form-group">
        <label class="required">セキュリティコード</label>
        <card-cvc
          ref="cardCvc"
          :stripe="stripePublishableKey"
          :options="options"
          class="stripe-element card-cvc"
          @change="cvc = $event.complete"
        />
      </div>

      <div class="p-transactionCreditCard__formInputItemBtns">
        <button class="p-transactionCreditCard__formInputItemBtn" @click.prevent="back">
          戻る
        </button>
        <general-submit-button
          :disabled="!complete"
          class="p-transactionCreditCard__formInputItemBtn"
          @submitting="forward"
        >
          次へ
        </general-submit-button>
      </div>
    </div>
  </form>
</template>

<script>
import { CardNumber, CardExpiry, CardCvc, createToken } from 'vue-stripe-elements-plus';
import ServiceAgreementsStripe from '../service_agreements/stripe';
import GeneralSubmitButton from '../../buttons/general_submit_button.vue';

export default {
  components: {
    CardNumber,
    CardExpiry,
    CardCvc,
    GeneralSubmitButton,
    'service-agreements-stripe': ServiceAgreementsStripe,
  },
  data() {
    return {
      number: false,
      expiry: false,
      cvc: false,
      stripePublishableKey: process.env.STRIPE_PUBLISHABLE_KEY,
      options: {},
      token: {},
      acceptedCardBrands: ['Visa', 'MasterCard', 'American Express', 'JCB', 'Discover', 'Diners Club'],
    };
  },
  computed: {
    complete() {
      return this.number && this.expiry && this.cvc;
    },
  },
  watch: {
    number() {
      this.update();
    },
    expiry() {
      this.update();
    },
    cvc() {
      this.update();
    },
  },
  methods: {
    update() {
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.focus();
        } else if (!this.cvc) {
          this.$refs.cardCvc.focus();
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.focus();
        } else if (!this.number) {
          this.$refs.cardNumber.focus();
        }
      }
    },
    isAcceptedCardBrand(card) {
      return card && this.acceptedCardBrands.includes(card.brand);
    },
    back() {
      this.$emit('back');
    },
    forward() {
      createToken().then(data => {
        if (data.token === undefined || !this.isAcceptedCardBrand(data.token.card)) {
          this.$emit('invalid');
          this.$emit('submissionFailed');
          return false;
        }
        this.token = data.token;
        this.$emit('submissionDone');
        this.$emit('forward', data.token);
      });
    },
  },
};
</script>
