import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['detail', 'rangeText'];

  showDetail() {
    this.detailTarget.classList.remove('-hide');
  }

  hideDetail() {
    this.detailTarget.classList.add('-hide');
    this.detailTarget.querySelectorAll('input[type="radio"]').forEach(radio => {
      radio.checked = false;
    });
  }

  setDetailText(event) {
    this.rangeTextTarget.textContent = event.currentTarget.closest('label').querySelector('p').textContent;
  }

  clearDetailText(event) {
    this.rangeTextTarget.textContent = '';
  }

  connect() {
    this.detailTarget.querySelectorAll('input[type="radio"]').forEach(radio => {
      if (radio.checked) {
        this.element.querySelector('#time-1').checked = true;
        this.element.querySelector('#time-1').dispatchEvent(new Event('click'));
        radio.dispatchEvent(new Event('change'));
      }
    });
  }
}
