/*
 * notification mark as read
 */
document.addEventListener('turbolinks:load', () => {
  if ($('body').hasClass('notifications')) {
    return $.ajax({
      url: '/notifications/mark_as_read',
      type: 'POST',
      dataType: 'json',
      success: data => {
        if (data.warning_count > 0) {
          $('#marks_count').html(data.warning_count);
        } else {
          $('#marks_count').remove();
        }
      },
    });
  }
});
