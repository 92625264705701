import { Controller } from 'stimulus';

/**
 * TOPページのStimulusJSコントローラ
 */
export default class extends Controller {
  static targets = ['loading'];

  initialize() {
    document.addEventListener('turbolinks:load', event => {
      this.showInduceModal();
    });
  }

  connect() {
    this.hideSplashDirect();
  }

  showInduceModal() {
    if (this.element.dataset.signedIn === 'true' || sessionStorage.getItem('haveShownInduceModal')) return;

    const no = Math.floor(Math.random() * 2) + 1;
    const induceModal = document.querySelector('.p-home__induce-modal');
    induceModal.classList.add(`p-home__induce-modal0${no}`);
    induceModal.classList.remove('display-none');
    sessionStorage.setItem('haveShownInduceModal', true);
  }

  /**
   * 全画面黒塗りのローディング画像を非表示にする（フェードあり）
   */
  hideSplash() {
    this.loadingTarget.classList.add('-hide');
  }

  /**
   * 画像を非表示にする（フェード後にdisplay:noneにする）
   * opacity:0にしたまま最前面で全画面の要素を残しておくと他の要素がどれもクリックできないため
   * 全画面黒塗りのローディング要素のtransitionendイベントにセットしている
   */
  deleteSplash(event) {
    const target = event.currentTarget;
    if (window.getComputedStyle(target).getPropertyValue('opacity') === '0') {
      target.classList.add('display-none');
    }
  }

  /**
   * 全画面黒塗りのローディング画像を非表示にする（フェードなし。Turbolinks:visitによる移動用）
   * 初回アクセス時は実行せずにwindowのloadイベントでフェード非表示している
   */
  hideSplashDirect() {
    if (document.readyState === 'complete') {
      this.loadingTarget.classList.add('display-none');
    }
  }

  /**
   * アカウント作成モーダルを開く
   * ログインボタンのclickイベントに設定している
   */
  openSignUp() {
    Vue.prototype.$modal.show('login-modal', { initialForm: 'signup' });
  }

  clickFindButton(event) {
    Turbolinks.visit(event.currentTarget.dataset.href);
  }

  visitWorks() {
    Turbolinks.visit('/works');
  }

  closeInduceModal(e) {
    e.currentTarget.parentNode.classList.add('display-none');
  }
}
