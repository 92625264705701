window.set_card_position = function() {
  $('.card-trigger').hover(function() {
    const rect = this.getBoundingClientRect();
    if (rect.top < 360) {
      $('.userCard', this).addClass('beneath');
    } else {
      $('.userCard', this).removeClass('beneath');
    }
  });
};
