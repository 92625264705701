<template>
  <div>
    <div class="b-youtubePlayer">
      <div data-controller="artist-movie-band" class="p-artist__movie-band p-artist__movie-band-top"></div>
      <div data-controller="artist-movie-band" class="p-artist__movie-band p-artist__movie-band-bottom"></div>
      <div class="b-youtubePlayer__playButtonWrapper" @click="togglePlayerModal">
        <div class="b-youtubePlayer__playButton" />
      </div>
      <youtube :video-id="videoId" :player-vars="playerVars" :mute="true" @ready="ready" />
    </div>
    <div v-if="isModalShown" @click="togglePlayerModal" class="b-youtubePlayer__playerModal">
      <div class="b-youtubePlayer__modalCloseButton" />
      <youtube :video-id="videoId" :player-vars="modalPlayerVars" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'YoutubePlayer',
  props: {
    videoId: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      playerVars: {
        autoplay: 1,
        loop: 1,
        playlist: this.videoId,
        playsinline: 1,
        controls: 0, // hide control bar
      },
      modalPlayerVars: {
        autoplay: 1,
        playsinline: 1,
        modestbranding: 1, // hide youtube logo
      },
      isModalShown: false,
    };
  },
  methods: {
    togglePlayerModal() {
      this.isModalShown = !this.isModalShown;
    },
    ready(e) {
      const player = e.target;
      player.mute();
      player.playVideo();
    },
  },
};
</script>
