/*
 * Sets "data-header-works-is-current" attribute to true on works/:id page
 * header will have opacity: 0; if data-is-current='true'; (_header.scss)
 * header is shown back on hover or on mousemove
 */
import { Controller } from 'stimulus';
import debounce from '../helpers/debounce';

// /works/123 match
// /reservations/123 match
// /reservations/123/credit_card is not match
const worksOrReservations = new RegExp(`^/(works|reservations)/\\d+$`);

export default class extends Controller {
  static targets = ['header'];

  _isCurrent = true;
  _isWorksPage = false;
  _timeoutId = null;

  connect() {
    if (window.location.pathname.match(worksOrReservations)) {
      this._isCurrent = true;
      this._isWorksPage = true;
      this._timeoutId = setTimeout(() => {
        this.data.set('is-current', true);

        document.documentElement.addEventListener('mousemove', this.showHeaderOnMouseMove);
        document.documentElement.addEventListener('touchstart', this.showHeaderOnMouseMove);
      }, 2000);
    }
  }

  disconnect() {
    if (this._isWorksPage) {
      this._isCurrent = false;
      this.data.set('is-current', false);
      if (this._timeoutId !== null) clearTimeout(this._timeoutId);
      document.documentElement.removeEventListener('mousemove', this.showHeaderOnMouseMove);
      document.documentElement.removeEventListener('touchstart', this.showHeaderOnMouseMove);
    }
  }

  showHeaderOnMouseMove = () => {
    if (this._isCurrent) {
      this._isCurrent = false;
      this.data.set('is-current', false);
    }

    this._timeoutId = this.hideHeaderAfterPause();
  };

  // hide header after 2s while debouncing redundant calls
  hideHeaderAfterPause = debounce(3000, () => {
    this._isCurrent = true;
    this.data.set('is-current', true);
  });
}
