<template>
  <modal
    name="works-detail-modal"
    :adaptive="true"
    width="100%"
    height="100%"
    @before-open="beforeOpen"
    @closed="closed"
  >
    <div class="b-worksDetailModal">
      <a class="b-worksDetailModal__closeBtn" @click="closeWorksDetailModal"></a>
      <div class="b-worksDetailModal__wrapper">
        <div class="b-worksDetailModal__topAlignBox">
          <section class="b-worksDetailModal__leftCol">
            <div>
              <div class="b-worksDetailModal__workInfoTtlWrapper">
                <h3 class="b-worksDetailModal__workInfoTtl">作品情報</h3>
                <div class="b-worksDetailModal__workInfoTtlLimited">
                  <img
                    src="../images/common/b-ownd-logo-text.png"
                    class="b-worksDetailModal__workInfoTtlLogoImage"
                  />限定
                </div>
              </div>
            </div>

            <div class="b-worksDetailModal__leftLowerRow">
              <div class="b-worksDetailModal__leftLowerLeftCol">
                <div class="b-worksDetailModal__items">
                  <p class="b-worksDetailModal__subTxt">金額</p>
                  <p class="b-worksDetailModal__priceTxt">{{ priceTxt }} <span>JPY</span></p>
                </div>
              </div>
              <div v-if="work.limited_edition" class="b-worksDetailModal__leftLowerRightCol">
                <p class="b-worksDetailModal__limitedEditionLabel">LIMITED EDITION</p>
                <p class="worksDetailModal__priceTxt b-worksDetailModal__limitedNumber">
                  <span class="b-worksDetailModal__limitedNumberBody">{{ work.limited_num }}</span>
                  <span>限定数量</span>
                </p>
              </div>
            </div>
            <div class="b-worksDetailModal__leftLowerRow">
              <div class="b-worksDetailModal__leftLowerLeftCol">
                <div class="b-worksDetailModal__items">
                  <p class="b-worksDetailModal__subTxt">作家</p>
                  <p class="b-worksDetailModal__normalTxt">
                    <a class="b-worksDetailModal__link" :href="work.artist_url" @click="stopClosingModal">{{
                      work.artist_name
                    }}</a>
                  </p>
                </div>

                <div class="b-worksDetailModal__items">
                  <p class="b-worksDetailModal__subTxt">作品</p>
                  <p class="b-worksDetailModal__normalTxt" v-html="work.title_as_html"></p>
                </div>

                <div class="b-worksDetailModal__items">
                  <p class="b-worksDetailModal__subTxt">サイズ（幅・高・奥行）</p>
                  <p class="b-worksDetailModal__normalTxt">{{ workSize }}</p>
                </div>
              </div>

              <div class="b-worksDetailModal__leftLowerRightCol">
                <div class="b-worksDetailModal__items">
                  <p class="b-worksDetailModal__subTxt">素材・技法</p>
                  <p class="b-worksDetailModal__normalTxt">{{ work.caption }}</p>
                </div>

                <div class="b-worksDetailModal__items">
                  <p class="b-worksDetailModal__subTxt">地域</p>
                  <p class="b-worksDetailModal__normalTxt">{{ work.area_name }}</p>
                </div>

                <div class="b-worksDetailModal__items">
                  <p class="b-worksDetailModal__subTxt">制作年</p>
                  <p class="b-worksDetailModal__normalTxt">{{ dateFormat }}</p>
                </div>
              </div>

              <div>
                <a v-bind:href="goToCertPage" class="b-worksDetailModal__certificateBtn" target="_blank">
                  証明書 <cert-fingerprint-icon class="b-worksDetailModal__certificateIcon" />
                </a>
              </div>
            </div>
          </section>

          <section class="b-worksDetailModal__rightCol">
            <h3 class="b-worksDetailModal__conceptTtl">コンセプト</h3>
            <p class="b-worksDetailModal__scrollTxt" v-html="work.concept_markdown" />
          </section>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import moment from 'moment';
import certFingerprintIcon from '../images/works/cert_fingerprint.vue';
import ToggleHeaderWorks from './toggle_header_works';

export default {
  components: {
    certFingerprintIcon,
  },
  filters: {
    ymd(date) {
      if (date) {
        return moment(date).format('YYYY.MM.DD');
      }
    },
  },
  props: {
    workId: {
      type: Number,
      required: true,
    },
    work: {
      type: Object,
      required: true,
    },
  },
  computed: {
    workSize() {
      let sizeList = [];
      if (this.work.width > 0) sizeList.push(`${this.work.width || '-'}mm`);
      if (this.work.height > 0) sizeList.push(`${this.work.height || '-'}mm`);
      if (this.work.depth > 0) sizeList.push(`${this.work.depth || '-'}mm`);
      return sizeList.join(' x ');
    },
    priceTxt() {
      if (!this.work.price) {
        return '';
      }
      return this.work.price.toLocaleString();
    },
    dateFormat() {
      if (!this.work.creation_date) {
        return '';
      }
      var arr = [];
      var str_date = this.work.creation_date;
      arr.push(str_date.slice(0, 4));

      var n = 4;
      for (n = 4; n < 8; n += 2) {
        var slice = str_date.slice(n, n + 2);
        if (slice != '') {
          arr.push(slice);
        }
      }
      return arr.join('.');
    },
    goToCertPage() {
      return '/works/' + this.work.id + '/certificate';
    },
  },
  methods: {
    closeWorksDetailModal() {
      this.$modal.hide('works-detail-modal');
    },
    stopClosingModal(e) {
      e.stopPropagation();
    },
    beforeOpen() {
      this.disableHeaderWorks();

      // ボタンの色を変える
      this.$eventHub.$emit('activate-work-info-button');
    },
    closed() {
      this.enableHeaderWorks();

      // ボタンの色を変える
      this.$eventHub.$emit('deactivate-work-info-button');
    },
  },
  mixins: [ToggleHeaderWorks],
};
</script>
