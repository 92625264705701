import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  isMobile() {
    return window.innerWidth < 768;
  }

  turbolinksCaching = false;

  initialize() {}

  disconnect() {
    document.removeEventListener('turbolinks:load', this.turblinksLoadHander);
  }

  connect() {
    let m;
    if ((m = decodeURIComponent(location.hash).match(/^#(.*)\/(.*)$/))) {
      const name = m[1];
      const values = m[2].split(/,/);
      values.forEach(value => {
        const input = document.querySelector(`input[name="${name}"][value="${value}"]`);
        if (input) input.checked = true;
      });
    }

    if (document.readyState === 'complete') {
      this.turblinksLoadHander();
    } else {
      document.addEventListener('turbolinks:load', this.turblinksLoadHander);
    }

    document.addEventListener('turbolinks:before-cache', () => {
      document.querySelectorAll('.p-works__filter-loading').forEach(loading => {
        loading.style.transition = 'none';
        loading.classList.remove('-hide');
        loading.classList.remove('display-none');
        loading.querySelector('img').style.display = 'none';
      });
    });

    this.ticking = false;
    this.lastKnownScrollPosition = -1;

    if (!document.documentElement.dataset.connected) {
      window.addEventListener('scroll', event => {
        if (this.lastKnownScrollPosition === document.documentElement.scrollTop) {
          return;
        }

        this.lastKnownScrollPosition = document.documentElement.scrollTop;
        if (!this.ticking) {
          window.requestAnimationFrame(() => {
            this.scrollLoading(this.lastKnownScrollPosition);
          });

          this.ticking = true;
        }
      });
      document.documentElement.dataset.connected = true;
    }

    document.querySelectorAll('.p-works__filter-group').forEach(filter => {
      const header = filter.querySelector('.p-works__filter-group-header');
      header.addEventListener('click', event => {
        const target = event.currentTarget;
        const items = filter.querySelector('.p-works__filter-items');
        const icon = target.querySelector('.p-works__filter-group-openclose');
        if (icon.classList.contains('-closed')) {
          items.style.height = `${items.dataset.itemsHeight}px`;
          icon.classList.remove('-closed');
        } else {
          items.style.height = '0';
          icon.classList.add('-closed');
        }
      });
    });

    document.querySelectorAll('.p-works__filter input, .p-works__filter-clear').forEach(input => {
      input.addEventListener('click', event => {
        this.loadWorks();
        this.renderTags();
      });
    });

    document.querySelector('.p-works__filter-clear').addEventListener('click', event => {
      document.querySelectorAll('.p-works__filter input').forEach(input => (input.checked = false));
    });

    document.querySelector('.p-works__filter-button').addEventListener('click', event => {
      document.body.classList.add('frozen');
      document.querySelectorAll('.fullscreen').forEach(el => (el.style.height = `${window.innerHeight}px`));
      document.querySelector('.p-works__filter').classList.add('-show');
    });

    document.querySelectorAll('.p-works__filter-close, .p-works__filter-close-modal').forEach(close => {
      close.addEventListener('click', event => {
        document.body.classList.remove('frozen');
        document.querySelector('.p-works__filter').classList.remove('-show');
      });
    });
  }

  scrollLoading(lastKnownScrollPosition) {
    if (document.querySelector('.p-works__filter').classList.contains('-show')) return;

    const scrollTop = lastKnownScrollPosition + document.documentElement.offsetHeight;
    const height = document.documentElement.scrollHeight;

    if (scrollTop < height) {
      this.ticking = false;
      return;
    }

    const lastWork = document.querySelector('.p-works__work:last-child');
    const next = lastWork && lastWork.dataset.nextPage;
    if (next == null) {
      this.ticking = false;
      return;
    }

    document.querySelector('.p-works__page-loading').classList.remove('-transparent');
    setTimeout(() => {
      axios
        .post(`/works/filter?page=${next}`, this.criteria())
        .then(response => {
          response.data.works.forEach(work => {
            const li = document.createElement('li');
            li.classList.add('p-works__work');
            if (work.next_page) {
              li.dataset.nextPage = work.next_page;
            }
            li.dataset.workId = work.id;
            li.innerHTML = `
              <img src="${work.picture}" class="p-works__work-picture" data-work-id="${work.id}">
              <div class="p-works__work-artist">${work.artist}</div>
              <div class="p-works__work-title">${work.title}</div>
              <div class="p-works__work-price">${work.price.toLocaleString()}</div>
            `;
            document.querySelector('.p-works__works').appendChild(li);
            li.addEventListener('click', event => {
              const works = Array.prototype.map.call(document.querySelectorAll('.p-works__works li'), img =>
                parseInt(img.dataset.workId)
              );
              Vue.prototype.$modal.show('works', { workId: work.id, works: works });
            });
          });
          this.ticking = false;
          document.querySelector('.p-works__page-loading').classList.add('-transparent');
        })
        .catch(error => {
          console.error(error);
        });
    }, 500);
  }

  turblinksLoadHander = () => {
    if (document.readyState === 'complete') {
      this.loadHandler();
    } else {
      window.addEventListener('load', this.loadHandler);
    }
  };

  loadHandler = () => {
    document.querySelectorAll('.p-works__filter-group').forEach(filter => {
      const items = filter.querySelector('.p-works__filter-items');
      items.dataset.itemsHeight = items.getBoundingClientRect().height;
      items.style.height = `${items.dataset.itemsHeight}px`;
    });

    if (document.querySelector('.p-works') && !this.turbolinksCaching) {
      this.loadWorks();
    }
  };

  criteria() {
    const criteria = {};
    document.querySelectorAll('.p-works__filter input:checked').forEach(input => {
      if (input.type === 'checkbox') {
        criteria[input.name] = criteria[input.name] || [];
        criteria[input.name].push(input.value);
      } else if (input.type === 'radio') {
        criteria[input.name] = input.value;
      }
    });

    criteria.per_page = this.isMobile() ? 6 : 9;

    return criteria;
  }

  loadWorks() {
    const loading = document.querySelector('.p-works__filter-loading');
    loading.classList.remove('display-none');
    setTimeout(() => loading.classList.remove('-hide'), 1);
    setTimeout(() => {
      axios
        .post('/works/filter', this.criteria())
        .then(response => {
          if (!document.querySelector('.p-works')) return;
          document.querySelectorAll('.p-works__work').forEach(work => work.parentNode.removeChild(work));
          response.data.works.forEach(work => {
            const li = document.createElement('li');
            li.classList.add('p-works__work');
            if (work.next_page) {
              li.dataset.nextPage = work.next_page;
            }
            li.dataset.workId = work.id;
            li.innerHTML = `
                  <img src="${work.picture}" class="p-works__work-picture">
                  <div class="p-works__work-artist">${work.artist}</div>
                  <div class="p-works__work-title">${work.title}</div>
                  <div class="p-works__work-price">${work.price.toLocaleString()}</div>
                `;
            document.querySelector('.p-works__works').appendChild(li);
            li.addEventListener('click', event => {
              const works = Array.prototype.map.call(document.querySelectorAll('.p-works__works li'), img =>
                parseInt(img.dataset.workId)
              );
              Vue.prototype.$modal.show('works', { workId: event.currentTarget.dataset.workId, works: works });
            });
          });
          document.querySelector('.p-works__filter-count').textContent = response.data.total_count;
          loading.addEventListener('transitionend', event => event.currentTarget.classList.add('display-none'), {
            once: true,
          });
          loading.classList.add('-hide');
        })
        .catch(error => {
          console.error(error);
        });
    }, 500);
  }

  renderTags() {
    document.querySelectorAll('.p-works__filter-tag').forEach(tag => tag.parentNode.removeChild(tag));
    document.querySelectorAll('.p-works__filter input:checked').forEach(input => {
      const tag = document.createElement('li');
      tag.classList.add('p-works__filter-tag');
      tag.dataset.criteriaType = input.name;
      tag.dataset.criteriaValue = input.value;
      tag.textContent = input.closest('li').querySelector('label').textContent;
      tag.addEventListener('click', event => {
        const target = event.currentTarget;
        document.querySelector(
          `.p-works__filter input[name="${target.dataset.criteriaType}"][value="${target.dataset.criteriaValue}"]`
        ).checked = false;
        this.loadWorks();
        tag.parentNode.removeChild(tag);
      });
      document.querySelector('.p-works__filter-tags').appendChild(tag);
    });
  }
}
