export default {
  data() {
    return {
      headers: [],
    };
  },
  methods: {
    disableHeaderWorks() {
      this.headers = document.querySelectorAll("[data-controller*='header-works']");
      this.headers.forEach(header => {
        const controllers = header.dataset.controller.split(/\s+/);
        header.dataset.controller = controllers.filter(c => c !== 'header-works').join(' ');
      });
    },
    enableHeaderWorks() {
      this.headers.forEach(header => {
        const controllers = header.dataset.controller.split(/\s+/);
        controllers.push('header-works');
        header.dataset.controller = Array.from(new Set(controllers)).join(' ');
      });
    },
  },
};
