import { Controller } from 'stimulus';
import axios from 'axios';
export default class extends Controller {
  static targets = ['postalCode', 'prefecture', 'city', 'town'];

  search(e) {
    e.preventDefault();
    const postalCode = this.postalCodeTarget.value;
    if (!(postalCode && this.validatePostalCode(postalCode))) {
      alert('正しい郵便番号を入れてください');
      return;
    }
    axios
      .get(`/postal_codes/${postalCode}`)
      .then(response => {
        const inputTargets = this.constructor.targets.filter(item => item !== 'postalCode');
        inputTargets.forEach(targetEl => {
          const target = this[`${targetEl}Target`];
          target.value = response.data[targetEl];
          target.dispatchEvent(new Event('change'));
        });
      })
      .catch(error => {
        if (error.response) {
          const error_message = error.response.data;
          alert(error_message);
          this.postalCodeTarget.closest('.b-fvGroup').querySelector('.b-fvGroup__error').textContent = error_message;
        }
      });
  }

  validatePostalCode(postalCode) {
    return postalCode.match(/^\d{3}-?\d{4}$/g);
  }
}
