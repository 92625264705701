import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['price', 'submit'];

  connect() {
    document.getElementById('app').style.backgroundColor = '#EBEBEB';
    document.querySelector('form').addEventListener('submit', event => {
      this.priceTarget.value = this.priceTarget.value.replace(/,/g, '');
    });
    window.addEventListener('load', () => {
      this.priceTarget.dispatchEvent(new Event('input'));
    });
  }

  setPrice100() {
    this.setPrice(100);
  }

  setPrice500() {
    this.setPrice(500);
  }

  setPrice1000() {
    this.setPrice(1000);
  }

  setPrice(price) {
    this.priceTarget.value = price.toLocaleString();
    this.priceTarget.dispatchEvent(new Event('input'));
  }

  // input action
  formatPrice(event) {
    const input = event.currentTarget;
    const value = parseInt(input.value.replace(/,/, ''));

    if (isNaN(value) || value === 0) {
      this.submitTarget.classList.add('-disable');
      return;
    }

    input.value = value.toLocaleString();
    this.submitTarget.classList.remove('-disable');
  }

  selectPrice(event) {
    const input = event.currentTarget;
    input.select();
  }
}
