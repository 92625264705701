import { isNil } from 'lodash';

export default {
  props: {
    workId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      status: 'ready',
    };
  },
  computed: {
    label() {
      return this.status === 'sending' ? '送信中' : '購入手続きに進む';
    },
  },
  methods: {
    purchase() {
      const formData = new FormData();
      formData.append('transaction[work_id]', this.workId);
      axios
        .post('/transactions', formData)
        .then(this.handleResponse)
        .catch(this.handleError);
    },
    handleResponse(response) {
      if (response.data.message !== 'success') {
        this.handleError(response);
        return;
      }
      Turbolinks.visit(`/transactions/${response.data.transaction_id}/shipping_address/new`);
    },
    handleError(error_or_response) {
      const response = error_or_response.response || error_or_response;
      console.error(error_or_response);
      this.status = 'ready';
      this.$notify({
        type: 'error',
        text: response.data.message,
        duration: 5000,
      });
    },
  },
};
