<template>
  <modal
    name="about-member"
    :adaptive="true"
    :scrollable="true"
    width="660px"
    height="auto"
    @before-open="beforeOpen"
    @closed="closeModal"
  >
    <div class="p-about__member-modal">
      <div class="p-about__member-modal-picture"><img :src="picture" /></div>
      <div class="p-about__member-modal-name">{{ this.name }}</div>
      <div class="p-about__member-modal-role">{{ this.role }}</div>
      <div class="p-about__member-modal-profile">{{ this.profile }}</div>
      <div slot="right-top" @click="$modal.hide('about-member')" class="p-about__member-modal-close-button">
        <img src="../images/about/icon-close-modal-white.svg" />
      </div>
    </div>
  </modal>
</template>

<script>
import isMobile from '../helpers/is_mobile';

export default {
  data() {
    return {
      picture: '',
      name: '',
      role: '',
      profile: '',
    };
  },
  beforeDestroy: function() {
    window.removeEventListener('touchmove', this.preventScroll, { passive: false });
  },
  methods: {
    beforeOpen(e) {
      if (isMobile() && this.$el.nodeType !== Node.COMMENT_NODE) {
        this.$el.style.height = window.innerHeight;
      }
      const el = e.params.memberEl;
      this.picture = el.querySelector('.p-about__member-picture img').src;
      this.name = el.querySelector('.p-about__member-name').textContent;
      this.role = el.querySelector('.p-about__member-role').textContent;
      this.profile = el.querySelector('.p-about__member-profile').dataset.profile;
      document.body.classList.add('frozen');
    },
    closeModal() {
      this.$modal.hide('about-member-modal');
      document.body.classList.remove('frozen');
    },
    removeOpenClass() {
      window.removeEventListener('touchmove', this.preventScroll, { passive: false });
      this.fillBackground = false;
    },
    preventScroll(e) {
      e.preventDefault();
    },
  },
};
</script>
