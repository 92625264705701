<template>
  <a @click="showArtistWorksCarousel">
    <img :src="src" :alt="alt" />
    <div class="b-artistWorkImage__soldLabel" v-if="soldOrCertificated">販売済み</div>
    <div class="b-artistWorkImage__limitedLabel" v-if="limitedEdition">限定作品</div>
  </a>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
      required: true,
    },
    alt: {
      type: String,
      default: '',
      required: false,
    },
    soldOrCertificated: {
      type: Boolean,
      default: false,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
    limitedEdition: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    showArtistWorksCarousel() {
      this.$modal.show('artist-works-carousel-modal', {
        index: this.index,
      });
    },
  },
};
</script>
