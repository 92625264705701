// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus';
import initApolloClient from '../apollo/client.js';
import followMutation from '../graphql/mutations/follow_mutation.js';
import unfollowMutation from '../graphql/mutations/unfollow_mutation.js';

export default class extends Controller {
  connect() {
    this.setButtonTitle();
  }

  setButtonTitle() {
    this.element.innerHTML = this.btnTitle(this.isFollowing);
  }

  btnTitle(val) {
    return val ? I18n.t('shared.unfollow') : I18n.t('shared.follow');
  }

  get isFollowing() {
    return JSON.parse(this.data.get('isFollowing'));
  }

  set isFollowing(val) {
    const elements = document.querySelectorAll(`[data-follow-button-followable-id='${this.followableId}']`);

    Array.prototype.forEach.call(elements, element => {
      element.setAttribute('data-follow-button-is-following', val);
      element.innerHTML = this.btnTitle(val);
    });
  }

  get followableId() {
    return this.data.get('followableId');
  }

  changeFollowState() {
    const client = initApolloClient();
    const mutation = this.isFollowing ? unfollowMutation : followMutation;

    client
      .mutate({
        mutation,
        variables: {
          followableId: this.followableId,
        },
      })
      .then(data => {
        if (this.isFollowing) {
          if (data.data.unfollow.success) {
            this.isFollowing = false;
          }
        } else if (data.data.follow.success) {
          this.isFollowing = true;
        }
      });
  }
}
