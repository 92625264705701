import { Controller } from 'stimulus';
import moment from 'moment';

export default class extends Controller {
  static targets = ['registerAnchor', 'selectWorkAnchor', 'paymentAnchor', 'deliverAnchor'];

  goToAnchor(e) {
    const anchorId = e.target.getAttribute('name');
    const anchorTarget = this[`${anchorId}AnchorTarget`];

    const targetY = anchorTarget.offsetTop;
    window.scrollTo({
      top: targetY,
      left: 0,
      behavior: 'smooth',
    });
  }
}
