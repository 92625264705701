<template>
  <section class="p-transactionConfirmation">
    <h3 class="p-transactionConfirmation__ttl">注文内容</h3>
    <div class="p-transactionConfirmation__item">
      <dl v-if="model.work">
        <dt>出品者</dt>
        <dd>{{ model.artist.name }}</dd>
      </dl>
      <dl>
        <dt>価格</dt>
        <dd class="__mv-bold">{{ moneyFormat(model.item ? model.item.price : model.priceCents) }}</dd>
      </dl>
      <dl v-if="model.item && model.itemAmount > 1">
        <dt>数量</dt>
        <dd class="__mv-bold">{{ model.itemAmount }}</dd>
      </dl>
    </div>

    <h3 class="p-transactionConfirmation__ttl">証明書に記載するお名前</h3>
    <div class="p-transactionConfirmation__item">
      <dl>
        <dt>{{ model.certName === 'Anonymously' ? '匿名' : model.certName }}</dt>
      </dl>
    </div>

    <div v-show="model.address">
      <h3 class="p-transactionConfirmation__ttl">お届け先住所</h3>
      <div class="p-transactionConfirmation__item">
        <dl>
          <dt>住所</dt>
          <dd>{{ fullAddress }}</dd>
        </dl>
      </div>

      <h3 class="p-transactionConfirmation__ttl">お届け希望時間帯</h3>
      <div class="p-transactionConfirmation__item">
        <dl>
          <dt>{{ deliveryTimeIsSpecified() }}</dt>
          <dd>{{ deliveryTimeRange() }}</dd>
        </dl>
      </div>
    </div>

    <h3 class="p-transactionConfirmation__ttl">決済方法</h3>
    <div class="p-transactionConfirmation__item">
      <dl>
        <dt>{{ model.paymentMethod === 'credit_card' ? 'クレジットカード' : '銀行振込' }}</dt>
        <dd v-if="model.paymentMethod === 'credit_card'">****-****-****-{{ token }}</dd>
      </dl>
    </div>

    <h3 class="p-transactionConfirmation__ttl">ご請求金額</h3>
    <div class="p-transactionConfirmation__item">
      <dl>
        <dt>作品代</dt>
        <dd>{{ moneyFormat(model.artworkPrice, 10) }}</dd>
      </dl>

      <dl>
        <dt>合計</dt>
        <dd>{{ moneyFormat(parseInt(model.priceCents, 10)) }}</dd>
      </dl>
    </div>

    <slot name="notes" />
    <div class="p-transactionCreditCard__formInputItemBtns"><slot name="buttons" /></div>
  </section>
</template>

<script>
export default {
  name: 'TransactionInfoPanel',
  props: {
    model: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      certPrice: 2000,
    };
  },
  computed: {
    fullAddress() {
      if (this.model.address == null) {
        return '';
      }
      return `${this.model.address.state + this.model.address.city + this.model.address.line1} ${this.model.address
        .line2 || ''}`;
    },
  },
  methods: {
    moneyFormat(priceCents) {
      const formatter = new Intl.NumberFormat('ja-JP', {
        currency: 'JPY',
        minimumFractionDigits: 0,
      });
      return `${formatter.format(priceCents)} 円`;
    },
    deliveryTimeIsSpecified() {
      return this.model.address.deliveryTimeRange == null ? '指定しない' : '指定する';
    },
    deliveryTimeRange() {
      switch (this.model.address.deliveryTimeRange) {
        case 'am':
          return '午前中';
        case 'pm2to4':
          return '14:00 - 16:00';
        case 'pm4to6':
          return '16:00 - 18:00';
        case 'pm6to8':
          return '18:00 - 20:00';
        case 'pm7to9':
          return '19:00 - 21:00';
        default:
          return '';
      }
    },
  },
};
</script>
