// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['entityList', 'paginationNextLink'];

  stopMoreFetching() {
    this.data.set('isFetching', true);
  }

  onFetchSuccess(event) {
    if (this.data.get('isFetching') == 'true') {
      this.data.set('isFetching', false);
      let [data, status, xhr] = event.detail;
      var list = data.querySelector("[ data-target='load-more.entityList']");
      this.entityListTarget.innerHTML += list.innerHTML;
      var paginationLink = data.querySelector("[data-target='load-more.paginationNextLink']").innerHTML;
      this.paginationNextLinkTarget.innerHTML = paginationLink;
    }
  }
}
