import { Controller } from 'stimulus';

/**
 * トップページのヘッダー背景画像をマウスオーバーで少し拡大表示するコントローラ
 *
 * cssの:hover疑似クラスのような動作をします。
 * マウスオーバー要素と拡大させる要素が別々にする必要があって、:hoverでは実現できなかったのでJavaScriptで制御しました。
 */
export default class extends Controller {
  static targets = ['trigger', 'image'];

  connect() {
    this.triggerTarget.addEventListener('mouseover', event => {
      this.imageTarget.classList.add('-zoom');
    });

    this.triggerTarget.addEventListener('mouseout', event => {
      this.imageTarget.classList.remove('-zoom');
    });
  }
}
