import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    if (['/sign_in', '/sign_up'].includes(location.pathname)) {
      const loginButton = document.querySelector('.b-header-menu-modal__login');
      if (loginButton) {
        loginButton.dispatchEvent(new Event('click'));
      }
    }
  }
}
