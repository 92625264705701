<template>
  <span>
    {{ daysRemaining | twoDigits }} : {{ hoursRemaining | twoDigits }} : {{ minutesRemaining | twoDigits }} :
    {{ secondsRemaining | twoDigits }}
  </span>
</template>

<script>
import moment from 'moment';

export default {
  props: ['end'],
  data: () => {
    return {
      currentDateTime: moment(),
    };
  },
  mounted() {
    setInterval(
      function() {
        this.currentDateTime = moment();
      }.bind(this),
      100
    );
  },
  computed: {
    daysRemaining: function() {
      return this.remaining('days');
    },
    hoursRemaining: function() {
      return this.remaining('hours') % 24;
    },
    minutesRemaining: function() {
      return this.remaining('minutes') % 60;
    },
    secondsRemaining: function() {
      return this.remaining('seconds') % 60;
    },
  },
  methods: {
    /* 終了日時(momentオブジェクト) */
    endDateTime: function() {
      return moment(this.end);
    },
    /* 残り時間 */
    remaining: function(unitOfTime) {
      return this.endDateTime().diff(this.currentDateTime, unitOfTime);
    },
  },
  filters: {
    // 数値を2桁のゼロ埋めに変換する
    twoDigits: function(num) {
      return String(num).padStart(2, '0');
    },
  },
};
</script>
