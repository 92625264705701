import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return;
    }

    const hideMask = _ => {
      if (document.querySelector('.b-workDetailIndex__splash') === null) {
        this.element.style.display = 'none';
      }
    };

    window.addEventListener('load', hideMask);
    document.addEventListener('turbolinks:load', hideMask);
    if (document.readyState === 'complete') {
      hideMask();
    }
  }
}
