import axios from 'axios';

export default {
  props: {
    transactionId: {
      type: Number,
      required: false,
    },
    reservationId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      certName: '匿名',
      isAnnonymous: true,
      isError: false,
      showAnnotation: false,
      hasBeenSavedCertname: false,
      sending: false,
    };
  },
  mounted() {
    if ((this.form = document.querySelector('form'))) {
      this.form.addEventListener('validate-all', this.submitHandler);
    }
    const url = this.transactionId
      ? `/transactions/${this.transactionId}.json`
      : `/reservations/${this.reservationId}.json`;
    axios.get(url).then(response => {
      const certName = response.data.cert_name;
      if (certName && certName !== 'Anonymously') {
        this.isAnnonymous = false;
        this.certName = certName;
      }
    });
  },
  unmounted() {
    if (this.form) {
      this.form.removeEventListener('validate-all', this.submitHandler);
    }
  },
  methods: {
    toggleAnonymouse() {
      this.isAnnonymous = !this.isAnnonymous;
      this.certName = this.isAnnonymous ? '匿名' : '';
    },
    toggleAnnotation() {
      this.showAnnotation = !this.showAnnotation;
    },
    toggleSending() {
      this.sending = !this.sending;
      this.submit = this.form.querySelector('[type="submit"]');
      this.submit.disabled = this.sending;
      if (this.sending) {
        this.submitLabel = this.submit.textContent;
        this.submit.textContent = '送信中…';
      } else {
        this.submit.textContent = this.submitLabel;
      }
    },
    requestPatch() {
      const url = this.transactionId ? `/transactions/${this.transactionId}` : `/reservations/${this.reservationId}`;
      axios
        .patch(url, {
          transaction: { cert_name: this.isAnnonymous ? 'Anonymously' : this.certName },
          reservation: { cert_name: this.isAnnonymous ? 'Anonymously' : this.certName },
        })
        .then(response => {
          this.hasBeenSavedCertname = true;
        })
        .catch(error => {
          console.error(error);
          this.toggleSending();
          this.$notify({
            type: 'error',
            text: error.response.data.message.join('<br>'),
            duration: 5000,
          });
        });
    },
    submitHandler(event) {
      if (this.sending || event.defaultPrevented) {
        return;
      }

      this.toggleSending();

      if (!this.hasBeenSavedCertname) {
        this.requestPatch();
      }
    },
  },
};
