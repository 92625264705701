import gql from 'graphql-tag';

const markMutation = gql`
  mutation markMutation($markableId: ID!) {
    mark(markableId: $markableId) {
      success
    }
  }
`;

export default markMutation;
