<template>
  <a ref="info" :class="buttonClasses" @click="showWorksDetailModal">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 2 10" fill="#fff">
      <path d="M0,10h2V4H0V10z M0,2h2V0H0V2z" />
    </svg>
  </a>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    showWorksDetailModal() {
      this.$modal.show('works-detail-modal');
      // フキダシを消す
      this.$refs.info.classList.remove('b-workSinglePage__footerBtn--info-first');
      localStorage.haveVisitedToWorkDetail = true;

      // 他のモーダルを閉じる
      this.$modal.hide('works-purchase-modal');
      this.$modal.hide('works-share-modal');
      this.$modal.hide('login-modal');
    },
  },
  computed: {
    buttonClasses() {
      let classes = ['b-workSinglePage__footerBtn--info'];
      if (this.isActive) {
        classes.push('b-workSinglePage__footerBtn--active');
      }
      return classes.join(' ');
    },
  },
  mounted() {
    // 初めてのアクセスだったらフキダシを表示
    if (!localStorage.haveVisitedToWorkDetail) {
      this.$refs.info.classList.add('b-workSinglePage__footerBtn--info-first');
    }
    // ボタンのON/OFFを切り替えるリスナーを登録
    this.$eventHub.$on('activate-work-info-button', () => (this.isActive = true));
    this.$eventHub.$on('deactivate-work-info-button', () => (this.isActive = false));
  },
};
</script>
