export default {
  props: {
    workId: Number,
    works: Array,
  },
  methods: {
    openModal() {
      this.$modal.hide('works');
      this.$modal.show('works', { workId: this.workId, works: this.works });
    },
  },
};
