<template>
  <modal name="works-purchase-modal" :adaptive="true" width="100%" height="100%">
    <div class="b-worksPurchaseModal">
      <div class="b-worksPurchaseModal__payinfoWrapper">
        <div class="b-worksPurchaseModal__items">
          <h3 class="b-worksPurchaseModal__ttl">作家</h3>
          <p class="b-worksPurchaseModal__normalTxt--bold">{{ work.artist_name }}</p>
        </div>

        <div class="b-worksPurchaseModal__items">
          <h3 class="b-worksPurchaseModal__ttl">作品</h3>
          <p class="b-worksPurchaseModal__normalTxt--bold">{{ work.title }}</p>
        </div>

        <div class="b-worksPurchaseModal__items">
          <h3 class="b-worksPurchaseModal__ttl">価格</h3>
          <p class="b-worksPurchaseModal__normalTxt--price">
            {{ work.price }}
            <span class="b-worksPurchaseModal__priceUnit"
              >円<span class="b-worksPurchaseModal__taxTxtSp">（税込）</span>
            </span>
          </p>
          <p class="b-worksPurchaseModal__taxTxtPc">（税込）</p>
        </div>

        <div class="b-worksPurchaseModal__items">
          <a class="b-worksPurchaseModal__nextBtn" @click="ok">購入画面に進む</a>
        </div>

        <div class="b-worksPurchaseModal__items">
          <p class="b-worksPurchaseModal__normalTxt">
            作品証明書にはあなたの名前が記され<br />ブロックチェーンで保管されます
          </p>
        </div>

        <div class="b-worksPurchaseModal__items">
          <img class="b-worksPurchaseModal__logo" src="../images/modal/logo--fullBlack.png" alt="B-OWND" />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    work: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ok() {
      this.$emit('ok');
    },
  },
};
</script>
