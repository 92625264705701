<template>
  <div class="b-workSinglePage">
    <works-image-carousel :pictures="work.pictures"></works-image-carousel>

    <works-detail-modal :work-id="work.id" :work="work"></works-detail-modal>

    <works-footer
      :markable-id="markableId"
      :is-user="isUser"
      :is-for-sale="isForSale"
      :is-marking="isMarking"
      :is-buyer="isBuyer"
      :is-artist="isArtist"
      :is-buyable="isBuyable"
      :transaction-link="transactionLink"
      :work="work"
      :from="from"
      :modal="modal"
      :campaign-id="campaignId"
    >
    </works-footer>

    <works-share-modal :work="work"></works-share-modal>

    <works-purchase-modal :work="work" :current-user-name="currentUserName" v-if="isBuyable"></works-purchase-modal>
  </div>
</template>

<script>
export default {
  props: {
    isUser: {
      type: Boolean,
      required: true,
    },
    currentUserName: {
      type: String,
      required: false,
    },
    isBuyer: {
      type: Boolean,
      required: true,
    },
    isArtist: {
      type: Boolean,
      required: true,
    },
    isBuyable: {
      type: Boolean,
      required: true,
    },
    transactionLink: {
      type: String,
      required: false,
    },
    markableId: {
      type: String,
      required: true,
    },
    isMarking: {
      type: Boolean,
      required: true,
    },
    isForSale: {
      type: Boolean,
      required: true,
    },
    work: {
      type: Object,
      required: true,
    },
    from: {
      type: String,
    },
    modal: {
      type: Boolean,
    },
    campaignId: {
      type: String,
      required: false,
    },
  },
  mounted() {
    document.body.classList.add('b-workSinglePage__background');
  },
};
</script>
