<template>
  <button v-bind="$attrs" @click.prevent="onClick" ref="submitButton" :disabled="disabled">
    <slot>{{ $t.t('helpers.submit.create') }}</slot>
  </button>
</template>

<script>
// Events:
// "submitting",catch this event on parent using @submtting listener to handle submission
//     Example:
//
//  <general-submit-button
//   class="submitButton"
//   @submitting="sendCreditCardToken"
//  >
//  submit
//  </general-submit-button>
//
//
// emit "submissionFailed" or "submissionDone" event on parent to enableButton again
//
//
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      disableWithText: I18n.t('other.processing'),
      buttonTitle: '',
    };
  },
  mounted() {
    this.$parent.$on('submissionFailed', () => {
      this.enableSubmitButton();
    });
    this.$parent.$on('submissionDone', () => {
      this.enableSubmitButton();
    });
  },
  methods: {
    enableSubmitButton() {
      const { submitButton } = this.$refs;
      submitButton.disabled = false;
      submitButton.innerHTML = this.buttonTitle;
    },

    disableSubmitButton() {
      const { submitButton } = this.$refs;
      submitButton.disabled = true;
      submitButton.innerHTML = this.disableWithText;
    },

    onClick() {
      this.buttonTitle = this.$refs.submitButton.innerHTML;
      this.disableSubmitButton();
      this.$emit('submitting');
    },
  },
};
</script>
