import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['ki', 'go', 'kiTab', 'goTab'];

  showKi() {
    this.kiTarget.classList.remove('p-campaigns__pcOnly');
    this.goTarget.classList.add('p-campaigns__pcOnly');
    this.kiTabTarget.classList.remove('p-campaigns__works2Tab--hide');
    this.goTabTarget.classList.add('p-campaigns__works2Tab--hide');
  }

  showGo() {
    this.goTarget.classList.remove('p-campaigns__pcOnly');
    this.kiTarget.classList.add('p-campaigns__pcOnly');
    this.goTabTarget.classList.remove('p-campaigns__works2Tab--hide');
    this.kiTabTarget.classList.add('p-campaigns__works2Tab--hide');
  }
}
