/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
document.addEventListener('turbolinks:load', () =>
  $('.addButton').on('click', function() {
    const template = $(this)
      .parents('.form-group')
      .last();
    const $clone = template.clone().insertAfter(template);
    const $option = $clone.find('[name="invitation[email][]"]');
    $option.val('');

    const addButton = $clone.find('.addButton');
    addButton.removeClass('addButton').addClass('removeButton');
    addButton.html('<i class="fa fa-minus"></i>');
    return $('#new_invitation').formValidation('addField', $option);
  })
);

document.addEventListener('turbolinks:load', () =>
  $('body').on('click', '.removeButton', function() {
    const template = $(this)
      .parents('.form-group')
      .last();
    const $option = template.find('[name="invitation[email][]"]');
    template.remove();
    return $('#new_invitation').formValidation('removeField', $option);
  })
);
