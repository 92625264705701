import axios from 'axios';

export default class PostWithNotification {
  static submit(postURL, formData = {}, successCallback = false, failCallback = false, method = 'post') {
    axios({
      url: postURL,
      method,
      data: formData,
    })
      .then(response => {
        Vue.prototype.$notify({
          group: 'success_notification',
          type: 'success',
          duration: 5000,
          text: response.data.message || 'success',
        });
        if (successCallback) successCallback(response);
      })
      .catch(error => {
        console.error(error);
        Vue.prototype.$notify({
          group: 'error_notification',
          type: 'error',
          duration: 5000,
          text: error.response.data.errors || error.response.data || 'error',
        });
        if (failCallback) failCallback(error);
      });
  }
}
