import { Controller } from 'stimulus';
import Glide from '@glidejs/glide';

export default class extends Controller {
  connect() {
    const effect = event => {
      document.querySelector('.p-specialEn__loading').classList.add('display-none');
      document.querySelector('.p-specialEn__keyVisualImage').classList.add('-after');
    };
    window.addEventListener('load', effect);
    document.body.style.backgroundColor = 'black';
    document.addEventListener('turbolinks:load', this.showSignUpInduction);
  }

  showSlide(event) {
    document.querySelector('.p-specialEn__slide').classList.remove('display-none');
    const index = parseInt(event.currentTarget.dataset.index);
    new Glide('.p-specialEn__slide', { startAt: index, gap: 0 }).mount();
  }

  closeSlide(event) {
    document.querySelectorAll('.glide').forEach(e => e.classList.add('display-none'));
  }

  showContact(event) {
    document.querySelector('.p-specialEn__contactModalBackground').classList.remove('display-none');
    document.body.classList.add('overflow-hidden');
  }

  closeContact(event) {
    document.querySelector('.p-specialEn__contactModalBackground').classList.add('display-none');
    document.body.classList.remove('overflow-hidden');
  }

  // ポップアップを閉じる
  close() {
    this.element.classList.add('p-specialEn__signupInductionWrapper--hide');
    setTimeout(() => this.element.classList.add('p-specialEn__signupInductionWrapper--displayNone'), 500);
    document.body.style.position = '';
  }

  // ポップアップを表示する
  showSignUpInduction() {
    const modal = document.querySelector('.p-specialEn__signupInductionWrapper');
    if (modal) {
      modal.classList.remove('p-specialEn__signupInductionWrapper--displayNone');
      modal.classList.remove('p-specialEn__signupInductionWrapper--hide');
      window.scrollTo(0, 0);
      document.body.style.position = 'fixed';
    }
  }
}
