import axios from 'axios';
import Glide from '@glidejs/glide';
import moment from 'moment';
import smoothscroll from 'smoothscroll-polyfill';
import isMobile from '../helpers/is_mobile';

export default {
  props: {
    showAuto: {
      type: Boolean,
      required: false,
    },
    workId: {
      type: Number,
      required: false,
    },
    secret: {
      type: String,
      required: false,
    },
    closable: {
      type: Boolean,
      default: true,
      required: false,
    },
    showGalleryLink: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      work: { pictures: [] },
      works: [],
      meta: {}, // 作品と関係ないデータ
      glides: {},
      otherWorks: [],
      nearWorks: [],
      submitted: false,
      beforeLocation: '',
    };
  },
  mounted() {
    smoothscroll.polyfill();
    if (this.showAuto) {
      this.$modal.show('works', { workId: this.workId, secret: this.secret });
    }
    this.$eventHub.$on('submit-reservation-form', () => {
      this.$refs.reservationForm && this.$refs.reservationForm.submit();
    });
  },
  filters: {
    ymd(date) {
      if (date) {
        return moment(date).format('YYYY.MM.DD');
      }
    },
  },
  computed: {
    workSize() {
      let sizeList = [];
      if (this.work.width > 0) sizeList.push(`${this.work.width || '-'}mm`);
      if (this.work.height > 0) sizeList.push(`${this.work.height || '-'}mm`);
      if (this.work.depth > 0) sizeList.push(`${this.work.depth || '-'}mm`);
      return sizeList.join(' x ');
    },
    priceTxt() {
      if (!this.work.price) {
        return '';
      }
      return this.work.price.toLocaleString();
    },
    dateFormat() {
      if (!this.work.creation_date) {
        return '';
      }
      var arr = [];
      var str_date = this.work.creation_date;
      arr.push(str_date.slice(0, 4));

      var n = 4;
      for (n = 4; n < 8; n += 2) {
        var slice = str_date.slice(n, n + 2);
        if (slice != '') {
          arr.push(slice);
        }
      }
      return arr.join('.');
    },
    token() {
      return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    },
  },
  methods: {
    beforeOpen(event) {
      document.body.classList.add('frozen');
      document.querySelectorAll('.fullscreen').forEach(el => (el.style.height = `${window.innerHeight}px`));

      if (this.$el.scrollTo) this.$el.scrollTo({ top: 0 });
      if (this.glides.keyVisual) {
        this.glides.keyVisual.destroy();
        delete this.glides.keyVisual;
      }
      if (this.glides.otherWorks) {
        this.glides.otherWorks.destroy();
        delete this.glides.otherWorks;
      }
      if (this.glides.nearWorks) {
        this.glides.nearWorks.destroy();
        delete this.glides.nearWorks;
      }

      this.work = { pictures: [] };
      if (event.params.works) {
        this.works = event.params.works;
      }
      const urlBase = event.params.secret ? `/works/secret/${event.params.secret}` : `/works/${event.params.workId}`;
      axios
        .get(`${urlBase}/for_modal`)
        .then(response => {
          const data = response.data;
          this.work = data.work;
          if (!location.pathname.match(/^\/works\/\d+/)) {
            this.beforeLocation = location.href;
          }
          if (event.params.secret == null) {
            history.replaceState({}, null, `/works/${this.work.id}${location.hash}`);
          }
          Object.keys(data)
            .filter(k => k !== 'work')
            .forEach(key => (this.meta[key] = data[key]));
          this.$nextTick().then(() => {
            if (this.work.pictures.length > 1) {
              this.glides.keyVisual = new Glide(this.$refs.glide, {
                startAt: 0,
                gap: 0,
                dragThreshold: false,
                swipeThreshold: 80,
              }).mount();
            } else {
              this.$refs.glide
                .querySelectorAll('[data-glide-el="controls"],[data-glide-el="controls[nav]"]')
                .forEach(el => el.classList.add('display-none'));
            }
            this.$refs.loading.classList.add('-hide');
            // 購入ボタンでアカウント登録した人向け
            if (this.meta.is_user) {
              if (location.hash === '#purchase') {
                axios
                  .post('/transactions', { transaction: { work_id: this.work.id } })
                  .then(response => {
                    if (response.data.message !== 'success') {
                      this.handleError(response);
                      return;
                    }
                    Turbolinks.visit(`/transactions/${response.data.transaction_id}/shipping_address/new`);
                  })
                  .catch(error_or_response => {
                    const response = error_or_response.response || error_or_response;
                    console.error(error_or_response);
                    this.$notify({
                      type: 'error',
                      text: response.data.message,
                      duration: 5000,
                    });
                  });
              } else if (location.hash === '#reserve') {
                document.querySelector('form[action="/reservations"]').submit();
              }
            }
          });
        })
        .catch(error => console.error(error));

      this.otherWorks = [];
      axios
        .get(`${urlBase}/other_works`)
        .then(response => {
          this.otherWorks = response.data;
          if (response.data.length === 0) return;
          this.$nextTick().then(() => {
            if (this.otherWorks.length > 4 && !isMobile()) {
              this.glides.otherWorks = new Glide(this.$refs.otherWorks, {
                type: 'carousel',
                startAt: 0,
                gap: 0,
                perView: 4.5,
                dragThreshold: false,
              }).mount();
            } else {
              this.$refs.otherWorks
                .querySelector('[data-glide-el="controls"],[data-glide-el="controls[nav]"]')
                .classList.add('display-none');
            }
            this.$refs.otherWorks.querySelectorAll('.glide__slide').forEach(slide => {
              slide.addEventListener('click', event => {
                const target = event.currentTarget;
                this.reopen(
                  target.dataset.workId,
                  this.otherWorks.map(work => work.id)
                );
              });
            });
          });
        })
        .catch(error => console.error(error));

      this.nearWorks = [];
      axios
        .get(`${urlBase}/near_works`)
        .then(response => {
          this.nearWorks = response.data;
          if (response.data.length === 0) return;
          this.$nextTick().then(() => {
            if (this.nearWorks.length > 4 && !isMobile()) {
              this.glides.nearWorks = new Glide(this.$refs.nearWorks, {
                type: 'carousel',
                startAt: 0,
                gap: 0,
                perView: 4.5,
                dragThreshold: false,
              }).mount();
            } else {
              this.$refs.nearWorks
                .querySelector('[data-glide-el="controls"],[data-glide-el="controls[nav]"]')
                .classList.add('display-none');
            }
            this.$refs.nearWorks.querySelectorAll('.glide__slide').forEach(slide => {
              slide.addEventListener('click', event => {
                const target = event.currentTarget;
                this.reopen(
                  target.dataset.workId,
                  this.otherWorks.map(work => work.id)
                );
              });
            });
          });
        })
        .catch(error => console.error(error));
    },
    opened() {
      this.$refs.header.parentNode.style.overflow = 'scroll';
      document.querySelector('.b-works-modal__loading').addEventListener('transitionend', event => {
        const target = event.currentTarget;
        if (window.getComputedStyle(target).getPropertyValue('opacity') === '0') {
          target.classList.add('display-none');
        }
      });
    },
    beforeClose() {},
    closed() {
      document.body.classList.remove('frozen');
      history.replaceState({}, null, this.beforeLocation);
    },
    closeModal() {
      this.$modal.hide('works');
    },
    stopClosingModal(e) {
      e.stopPropagation();
    },
    goToCertPage() {
      return '/works/' + this.work.id + '/certificate';
    },
    openMenu() {
      document.querySelector('[data-target="header-menu.button"]').dispatchEvent(new Event('click'));
      document.querySelector('[data-controller="header-menu"]').classList.add('-z-index-1000');
    },
    scrollDown() {
      document
        .querySelector('.v--modal-box')
        .scrollTo({ top: window.innerHeight || document.documentElement.clientHeight || 0, behavior: 'smooth' });
    },
    submitForm(event) {
      event.currentTarget.closest('form').submit();
    },
    next() {
      if (this.work.id == null || this.work.id == NaN) return;
      const index = this.works.indexOf(this.work.id);
      if (index < 0) return;
      const nextIndex = index === this.works.length - 1 ? 0 : index + 1;
      const nextWorkId = this.works[nextIndex];
      this.reopen(nextWorkId);
    },
    prev() {
      if (this.work.id == null || this.work.id == NaN) return;
      const index = this.works.indexOf(this.work.id);
      if (index < 0) return;
      const prevIndex = index === 0 ? this.works.length - 1 : index - 1;
      const prevWorkId = this.works[prevIndex];
      this.reopen(prevWorkId);
    },
    reopen(workId, works = null) {
      this.$refs.loading.classList.remove('display-none');
      setTimeout(() => this.$refs.loading.classList.remove('-hide'), 1);
      setTimeout(() => {
        this.$modal.hide('works');
        this.$modal.show('works', { workId: workId, works: works });
      }, 400);
    },
    openSignUp() {
      Vue.prototype.$modal.show('login-modal', { initialForm: 'signup' });
    },
    openReservationModalBeforeSubmit() {
      this.$modal.show('reservation-info-modal', { withButton: true });
    },
    purchase() {
      const formData = new FormData();
      formData.append('transaction[work_id]', this.work.id);
      axios
        .post('/transactions', formData)
        .then(this.handleResponse)
        .catch(this.handleError);
    },
    handleResponse(response) {
      if (response.data.message !== 'success') {
        this.handleError(response);
        return;
      }
      Turbolinks.visit(`/transactions/${response.data.transaction_id}/shipping_address/new`);
    },
    handleError(error_or_response) {
      const response = error_or_response.response || error_or_response;
      console.error(error_or_response);
      this.status = 'ready';
      this.$notify({
        type: 'error',
        text: response.data.message,
        duration: 5000,
      });
    },
  },
};
