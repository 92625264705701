<template>
  <div class="b-workSinglePage__purchaseBtn">
    <div v-if="campaignId"><a :href="`/campaigns/${campaignId}`">購入手続きに進む</a></div>
    <div v-else-if="isBuyable"><purchase-button :work-id="workId"></purchase-button></div>
    <div v-else-if="isBuyer && transactionLink"><a :href="transactionLink">取引</a></div>
    <div v-else class="b-workSinglePage__footerSold">売済</div>
  </div>
</template>

<script>
export default {
  props: {
    workId: {
      type: Number,
      required: false,
    },
    isBuyer: {
      type: Boolean,
      required: true,
    },
    isBuyable: {
      type: Boolean,
      required: true,
    },
    transactionLink: {
      type: String,
      required: false,
    },
    campaignId: {
      type: String,
      required: false,
    },
  },
};
</script>
