// body読み込み時に一度だけbodyサイズを設定
document.addEventListener('DOMContentLoaded', () => {
  document.body.style.minHeight = window.innerHeight + 'px';
  document.querySelectorAll('.fullscreen').forEach(el => (el.style.height = window.innerHeight + 'px'));

  // リサイズを停止して後にbodyサイズを設定
  let timeoutId;
  window.addEventListener('resize', () => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      document.querySelectorAll('.fullscreen').forEach(el => (el.style.height = window.innerHeight + 'px'));
      document.body.style.minHeight = window.innerHeight + 'px';
    }, 33);
  });
});
