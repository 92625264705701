import { Controller } from 'stimulus';
import httpclient from 'axios';
import axios from 'axios';

export default class extends Controller {
  static targets = ['articles'];

  axiosInstance = axios.create({
    baseURL: 'https://media.b-ownd.com/wp-json/wp/v2',
  });

  /**
   * B-OWND Magazineのサムネイルを取得して表示する
   */
  loadArticles = () => {
    this.axiosInstance.interceptors.request.use(config => {
      const clone = JSON.parse(JSON.stringify(config));
      delete clone.headers.common['X-CSRF-Token'];
      return clone;
    });

    this.axiosInstance.get('/article').then(response => {
      JSON.parse(response.data)
        .slice(0, 3)
        .forEach(item => {
          this._feedItemElement(item);
        });
    });
  };

  /**
   * 記事単体の要素を生成して返す
   * 処理の詳細はmagazine_feed_item_controllerでやっている
   */
  async _feedItemElement(item) {
    const div = document.createElement('div');
    div.classList.add('p-home__magazine-article');
    this.articlesTarget.appendChild(div);

    const featuredMedia = JSON.parse((await this.axiosInstance.get(`/media/${item.featured_media}`)).data);
    const articleCategory = JSON.parse(
      (await this.axiosInstance.get(`/article_category/${item.article_category[0]}`)).data
    );
    div.innerHTML = `
      <a href="${item.link}" target="_blank">
        <img src="${featuredMedia.source_url}" alt="">
        <div class="p-home__magazine-article-category">${articleCategory.name}</div>
        <div class="p-home__magazine-article-title">${item.title.rendered}</div>
        <div class="p-home__magazine-article-button">
          <span>詳細を見る</span>
        </div>
      </a>
    `;
  }
}
