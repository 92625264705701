<template>
  <div class="p-transactionAddressee__form">
    <label class="p-transactionAddressee__formDifferentAddress">
      <input type="checkbox" class="p-transactionAddressee__formDifferentAddressCheckbox" v-model="showForm" />
      <p class="p-transactionAddressee__formDifferentAddressTxt">購入者とは異なる住所へ送る</p>
    </label>

    <div class="p-transactionAddressee__formInput">
      <form ref="form">
        <input name="utf8" type="hidden" value="✓" />
        <vue-form-generator ref="formGenerator" :schema="schema" :model="model" :options="formOptions" />
        <!-- <button class="p&#45;transactionAddressee__formInputItemReflectionBtn">住所に反映</button> -->
        <div class="p-transactionAddressee__formInputItemBtn">
          <button @click.prevent="back">戻る</button>
          <button :title="$t.t('helpers.submit.pay')" @click.prevent="onSubmitForm">次へ</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import initApolloClient from '../apollo/client.js';
import shippingAddressMutation from '../graphql/mutations/shipping_address_mutation.js';
import ServiceAgreementsStripe from './service_agreements/stripe.vue';

export default {
  components: {
    'service-agreements-stripe': ServiceAgreementsStripe,
  },
  watch: {
    showForm(value) {
      if (value) {
        this.model = { country: 'ja' };
      } else {
        this.newAddress = this.model;
        this.model = this.originalAddress;
      }
    },
  },
  methods: {
    toggleAddressForm() {
      this.showForm = !this.showForm;
    },
    validateForm() {
      return this.$refs.formGenerator.validate();
    },
    onSubmitForm() {
      if (!this.validateForm()) return;
      const client = initApolloClient();
      const mutation = shippingAddressMutation;
      client
        .mutate({
          mutation,
          variables: {
            country: 'ja',
            state: this.model.state,
            city: this.model.city,
            line1: this.model.line1,
            line2: this.model.line2,
            postalCode: this.model.postalCode,
            name: this.model.name,
            phone: this.model.phone,
            transactionId: this.transactionId,
          },
        })
        .then(response => {
          const id = response.data.attachNewAddressToTransaction.transaction.activeRecordId;
          Turbolinks.visit(`/transactions/${id}`);
        })
        .catch(error => {
          Vue.prototype.$notify({
            group: 'error_notification',
            type: 'error',
            duration: 5000,
            text: error.message,
          });
        });
    },
    back() {
      Turbolinks.visit(this.backUrl);
    },
  },
  props: {
    transactionId: {
      type: String,
      required: true,
    },
    backUrl: {
      type: String,
      required: true,
    },
    address: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      postUrl: '/stripe_accounts',
      formOptions: {
        validateAfterChanged: false,
      },
      originalAddress: this.address,
      newAddress: {},
      model: this.address || {
        country: 'ja',
      },
      showForm: false,
      entityType: 'work',
      imageRequired: true,
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            styleClasses: 'half r',
            label: '名前',
            placeholder: '名前',
            model: 'name',
            inputName: 'legal_entity[name_kanji]',
            required: true,
            validator: [VueFormGenerator.validators.string],
          },
          {
            type: 'input',
            inputType: 'text',
            label: '郵便番号',
            placeholder: '郵便番号(例: 1130023)',
            model: 'postalCode',
            inputName: 'legal_entity[address_kana][postal_code]',
            required: true,
            pattern: /^\d{3}-?\d{4}$/,
            validator: [VueFormGenerator.validators.regexp],
            onChanged(model, newVal, oldVal, field) {
              if (field.pattern.test(newVal)) {
                axios
                  .get(`/postal_codes/${newVal}`)
                  .then(response => {
                    this.$set(this.model, 'state', response.data.prefecture);
                    // TODO: model.townを入力しなくても良い？
                    this.$set(this.model, 'city', response.data.city + response.data.town);
                  })
                  .catch(error => {
                    Vue.prototype.$notify({
                      group: 'error_notification',
                      type: 'error',
                      duration: 5000,
                      text: error.response.data,
                    });
                  });
              }
            },
          },
          {
            type: 'input',
            inputType: 'text',
            styleClasses: 'half l',
            label: '都道府県',
            placeholder: '都道府県',
            model: 'state',
            inputName: 'legal_entity[address_kanji][state]',
            validator: [VueFormGenerator.validators.string],
            required: true,
          },
          {
            type: 'input',
            inputType: 'text',
            styleClasses: 'half l',
            label: '市区町村',
            placeholder: '市区町村',
            model: 'city',
            inputName: 'legal_entity[address_kanji][city]',
            validator: [VueFormGenerator.validators.string],
            required: true,
          },
          {
            type: 'input',
            inputType: 'text',
            styleClasses: 'half l',
            label: '番地',
            placeholder: '番地',
            model: 'line1',
            inputName: 'legal_entity[address_kanji][town]',
            validator: [VueFormGenerator.validators.string],
            required: true,
          },
          {
            type: 'input',
            inputType: 'text',
            styleClasses: 'half l',
            label: 'マンション名/部屋番号',
            placeholder: 'マンション名/部屋番号',
            model: 'line2',
            inputName: 'legal_entity[address_kanji][line1]',
            validator: [VueFormGenerator.validators.string],
            required: false,
          },
          {
            type: 'input',
            inputType: 'text',
            styleClasses: 'half r',
            label: '電話番号',
            placeholder: '電話番号',
            model: 'phone',
            inputName: 'legal_entity[phone_number]',
            validator: [VueFormGenerator.validators.string],
            required: true,
          },
        ],
      },
    };
  },
};
</script>
