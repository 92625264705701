import { Controller } from 'stimulus';
import Parser from 'rss-parser';

/**
 * B-OWND Magazineの記事一覧を表示するコントローラ
 */
export default class extends Controller {
  static targets = ['articles', 'link'];

  connect() {
    // data-feed-url属性からfeedを取得する
    new Parser().parseURL(`/cors/${this.element.dataset.feedUrl.replace(/^https?\:\/\//, '')}`, (error, feed) => {
      // 取得できなかったら何もしない
      if (error) {
        console.error(error);
        return;
      }

      // 記事が0件だったら何も表示しない
      if (feed.items.length === 0) {
        return;
      }

      // 最新の3件のみを表示する
      feed.items.slice(0, 3).forEach(item => {
        this.articlesTarget.appendChild(this.feedItemElement(item));
      });
      this.linkTarget.setAttribute('href', feed.feedUrl.replace(/\/feed$/, ''));

      this.element.classList.remove('p-artist__magazine--hide');
      this.element.classList.add('p-artist__magazine');
    });
  }

  // 記事単体の要素を生成して返す
  // 処理の詳細はmagazine_feed_item_controllerでやってる
  feedItemElement(item) {
    const li = document.createElement('li');
    li.classList.add('p-artist__magazine-article');
    li.dataset.controller = 'magazine-feed-item';
    li.dataset.item = JSON.stringify({ title: item.title, link: item.link });
    return li;
  }
}
