<template>
  <section class="p-transactionConfirmation">
    <h3 class="p-transactionConfirmation__ttl">寄付内容</h3>
    <div class="p-transactionConfirmation__item">
      <dl>
        <dt>アーティスト</dt>
        <dd>{{ model.artist.name }}</dd>
      </dl>
      <dl>
        <dt>価格</dt>
        <dd class="__mv-bold">{{ moneyFormat(model.price) }}</dd>
      </dl>
    </div>

    <div class="p-transactionCreditCard__formInputItemBtns"><slot name="buttons" /></div>
  </section>
</template>

<script>
export default {
  name: 'DonationInfoPanel',
  props: {
    model: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      certPrice: 2000,
    };
  },
  computed: {
    fullAddress() {
      return `${this.model.address.state + this.model.address.city + this.model.address.line1} ${this.model.address
        .line2 || ''}`;
    },
  },
  methods: {
    moneyFormat(price) {
      const formatter = new Intl.NumberFormat('ja-JP', {
        currency: 'JPY',
        minimumFractionDigits: 0,
      });
      return `${formatter.format(price)} 円`;
    },
  },
};
</script>
