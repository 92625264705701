<template>
  <a class="b-workSinglePage__footerBtn--share" @click="showWorksShareModal" ref="button">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.551"
      height="19.297"
      viewBox="0 0 14.551 19.297"
      stroke="#fff"
      fill="#fff"
    >
      <g id="share" transform="translate(-3)">
        <path
          id="パス_382"
          d="M14.428 8.25h1.186A1.187 1.187 0 0 1 16.8 9.436v9.491a1.187 1.187 0 0 1-1.186 1.186H4.936a1.187 1.187 0 0 1-1.186-1.185V9.436A1.187 1.187 0 0 1 4.936 8.25h1.187"
          style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px"
          data-name="パス 382"
          transform="translate(0 -1.568)"
        />
        <path
          id="パス_383"
          d="M12 .75v8.305"
          style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px"
          data-name="パス 383"
          transform="translate(-1.725)"
        />
        <path
          id="パス_384"
          d="M8.25 3.716L11.216.75l2.966 2.966"
          style="fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px"
          data-name="パス 384"
          transform="translate(-.941)"
        />
      </g>
    </svg>
  </a>
</template>

<script>
export default {
  methods: {
    showWorksShareModal() {
      this.$modal.show('works-share-modal');

      // 他のモーダルを閉じる
      this.$modal.hide('works-purchase-modal');
      this.$modal.hide('works-detail-modal');
      this.$modal.hide('login-modal');
    },
  },
};
</script>
