import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['fileField', 'fileName', 'preview', 'buttonLabel'];

  addFile() {
    this.fileFieldTarget.click();
  }

  getFileName(event) {
    const file = event.target.files[0];
    this.fileNameTarget.innerHTML = file.name;
    if (this.hasPreviewTarget) {
      this.previewTarget.src = URL.createObjectURL(file);
    }
    if (this.hasButtonLabelTarget) {
      this.buttonLabelTarget.innerHTML = '添付済み';
    }
  }
}
