<template>
  <div v-cloak class="b-artistProfileAccordion">
    <button
      :class="{
        'b-artistProfileAccordion__closedBtn': !isOpened,
        'b-artistProfileAccordion__openedBtn': isOpened,
      }"
      type="button"
      @click="accordionToggle();"
    >
      <slot name="btnTtl" />
    </button>

    <div v-if="isOpened">
      <div class="b-artistProfileAccordion__body"><slot name="body" /></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpened: false,
    };
  },
  methods: {
    accordionToggle() {
      this.isOpened = !this.isOpened;
    },
  },
};
</script>
