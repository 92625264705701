import gql from 'graphql-tag';

const FollowMutation = gql`
  mutation followMutation($followableId: ID!) {
    follow(followableId: $followableId) {
      success
      errorMessages
    }
  }
`;

export default FollowMutation;
