import { Controller } from 'stimulus';

/**
 * Viewの内側からbodyタグのclass属性を指定するコントローラ
 */
export default class extends Controller {
  connect() {
    this.element.classList.forEach(styleClass => document.body.classList.add(styleClass));
    this.element.style.dispaly = 'none';
  }
}
