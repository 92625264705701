import gql from 'graphql-tag';

const SearchAllQueries = gql`
  query SearchAllQueries($query: String!) {
    searchArtists(query: $query, first: 2) {
      edges {
        node {
          id
          name
          url
        }
      }
    }
    searchGenerals(query: $query, first: 2) {
      edges {
        node {
          id
          name
          url
        }
      }
    }
    searchWorks(query: $query, first: 2) {
      edges {
        node {
          id
          name: title
          url
        }
      }
    }
  }
`;

export default SearchAllQueries;
