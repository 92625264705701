/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.appendTextBoxToRadio = function(radio) {
  const text_field = radio.parents('form').find('#shipping_info_text');
  if (radio.attr('id') === 'info_other' && radio.is(':checked')) {
    text_field.show();
    return text_field.attr('name', 'auction[shipping_info]');
  }
  text_field.hide();
  return text_field.removeAttr('name');
};

window.appendTextBoxToSelect = function(select) {
  const text_field = select.parents('form').find('#shipping_info_text');
  if (select.val() === 'other') {
    text_field.show();
    return text_field.attr('name', 'auction[shipping_info]');
  }
  text_field.hide();
  return text_field.removeAttr('name');
};
