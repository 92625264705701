<template>
  <div class="b-remaining-time">
    <div class="b-remaining-time__subjects">
      <div class="b-remaining-time__subject">
        Days
      </div>
      <div class="b-remaining-time__subject">
        Hours
      </div>
      <div class="b-remaining-time__subject">
        Minutes
      </div>
      <div class="b-remaining-time__subject">
        Seconds
      </div>
    </div>
    <div class="b-remaining-time__times">
      <div class="b-remaining-time__time">
        {{ daysRemaining | twoDigits }}
      </div>
      <div class="b-remaining-time__time">:</div>
      <div class="b-remaining-time__time">
        {{ hoursRemaining | twoDigits }}
      </div>
      <div class="b-remaining-time__time">:</div>
      <div class="b-remaining-time__time">
        {{ minutesRemaining | twoDigits }}
      </div>
      <div class="b-remaining-time__time">:</div>
      <div class="b-remaining-time__time">
        {{ secondsRemaining | twoDigits }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['end'],
  data: () => {
    return {
      currentDateTime: moment(),
    };
  },
  mounted() {
    setInterval(
      function() {
        this.currentDateTime = moment();
      }.bind(this),
      100
    );
  },
  computed: {
    daysRemaining: function() {
      return this.remaining('days');
    },
    hoursRemaining: function() {
      return this.remaining('hours') % 24;
    },
    minutesRemaining: function() {
      return this.remaining('minutes') % 60;
    },
    secondsRemaining: function() {
      return this.remaining('seconds') % 60;
    },
  },
  methods: {
    /* 終了日時(momentオブジェクト) */
    endDateTime: function() {
      return moment(this.end);
    },
    /* 残り時間 */
    remaining: function(unitOfTime) {
      return this.endDateTime().diff(this.currentDateTime, unitOfTime);
    },
  },
  filters: {
    // 数値を2桁のゼロ埋めに変換する
    twoDigits: function(num) {
      return String(num).padStart(2, '0');
    },
  },
};
</script>

<style lang="scss">
.b-remaining-time {
  width: 234px;

  &__subjects {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__subject {
    font-size: 12px;
    letter-spacing: 0;
    width: 46px;
  }

  &__times {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__time {
    font-size: 25px;
    letter-spacing: 3.75px;
  }
}
</style>
