import { Controller } from 'stimulus';
import moment from 'moment';

export default class extends Controller {
  static targets = ['created', 'transaction', 'logo', 'workbtn', 'info', 'aboutbtn'];

  connect() {
    // this.createdTarget.innerText = moment(this.createdTarget.innerText).format('MMM DD, YYYY');
    setTimeout(() => {
      this.showCertificate();
    }, 1000);
  }

  showCertificate() {
    this.logoTarget.classList.add('is-hide');
    setTimeout(() => {
      this.transactionTarget.classList.add('is-show');
      setTimeout(() => {
        this.aboutbtnTarget.classList.add('is-show');
        this.infoTarget.classList.add('is-show');
        this.workbtnTarget.classList.add('is-show');
      }, 3000);
    }, 600);
  }

  toBoolean(str) {
    return str.toLowerCase() === 'true';
  }
}
