import { Controller } from 'stimulus';
import Glide from '@glidejs/glide';

export default class extends Controller {
  static targets = ['slide'];

  mountSlide() {
    new Glide(this.slideTarget, {
      type: 'carousel',
      startAt: 0,
      gap: 0,
      autoplay: 4000,
      dragThreshold: false,
      hoverpause: false,
    }).mount();
  }
}
