<template>
  <a @click="showWorksPurchaseModal">購入手続きに進む</a>
</template>

<script>
export default {
  methods: {
    showWorksPurchaseModal() {
      this.$modal.show('works-purchase-modal');

      // 他のモーダルを閉じる
      this.$modal.hide('works-detail-modal');
      this.$modal.hide('works-share-modal');
      this.$modal.hide('login-modal');
    },
  },
};
</script>
