import { Controller } from 'stimulus';

/**
 * トップページにアカウント作成を促すポップアップを表示する
 */
export default class extends Controller {
  connect() {
    document.body.style.backgroundColor = 'black';
    if (!['/sign_in', '/sign_up'].includes(location.pathname)) {
      document.addEventListener('turbolinks:load', this.showSignUpInduction);
    }
  }

  // ポップアップを閉じる
  close() {
    this.element.classList.add('l-worksGallery__signupInductionWrapper--hide');
    setTimeout(() => this.element.classList.add('l-worksGallery__signupInductionWrapper--displayNone'), 500);
    document.body.style.position = '';
  }

  // ポップアップを閉じてアカウント作成モーダルを開く
  openSignUp() {
    this.close();
    Vue.prototype.$modal.show('login-modal', { initialForm: 'signup' });
  }

  // ポップアップを表示する
  showSignUpInduction() {
    const modal = document.querySelector('.l-worksGallery__signupInductionWrapper');
    if (modal) {
      modal.classList.remove('l-worksGallery__signupInductionWrapper--displayNone');
      modal.classList.remove('l-worksGallery__signupInductionWrapper--hide');
      window.scrollTo(0, 0);
      document.body.style.position = 'fixed';
    }
  }
}
